import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"


const baseQuery = fetchBaseQuery({
	baseUrl: "",//process.env.REACT_APP_API_BASE_URL? `${process.env.REACT_APP_API_BASE_URL}orders/` : ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})


// Define a service using a base URL and expected endpoints
export const ordersApi = createApi({
	reducerPath: "ordersApi",
	baseQuery: baseQuery,
    tagTypes: ["Orders"],
	endpoints: (builder) => ({
		getOrder: builder.query<any, string>({
			query: (orderToken) => {
				return `${window.config.REACT_APP_API_BASE_URL}orders/${orderToken}`
			},
            providesTags: (result, error, arg) => [{ type:"Orders", id: arg }],
		})
	}),
})


export const { useGetOrderQuery } = ordersApi
