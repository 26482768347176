import axios from "axios"
import { MdProductionQuantityLimits } from "react-icons/md"



export interface ExternalEnvironmentVariables {
    REACT_APP_AUTH0_DOMAIN:string
    REACT_APP_AUTH0_CLIENT_ID:string
    REACT_APP_AUTH0_AUDIENCE:string
    REACT_APP_API_BASE_URL:string
    REACT_APP_MAPBOX_ACCESS_TOKEN: string
}

export const environmentVariables: ExternalEnvironmentVariables = {
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || "",
    REACT_APP_AUTH0_CLIENT_ID:process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    REACT_APP_AUTH0_AUDIENCE:process.env.REACT_APP_AUTH0_AUDIENCE || "",
    REACT_APP_API_BASE_URL:process.env.REACT_APP_API_BASE_URL || "",
    REACT_APP_MAPBOX_ACCESS_TOKEN:process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ""
}
export const getConfig = (): Promise<{data: ExternalEnvironmentVariables}> => {
    
	if (process.env.NODE_ENV === "development") {
		return Promise.resolve({ data: environmentVariables })
	}
   return axios.get(`${window.location.origin}/config.json`)
}
export enum Environment {
    DEV,
    TESTING, 
    PRODUCTION,
}
export const getEnvironment = ():Environment => {
    if(process.env.NODE_ENV === "development"){
        return Environment.DEV
    }
    if(window.location.origin.includes("testing")){
        return Environment.TESTING
    }
    return Environment.PRODUCTION
}