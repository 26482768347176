import styled from "styled-components"
import tw from "twin.macro"
import { handleBoxSize, handleBoxSizeSmall } from "./LockerView.styles"

export const StyledLockerBox = styled.div<{
	size: string
	status: string
	type: string
	small?: boolean
}>`
	${tw`flex-1 flex-row border-b border-r box-border cursor-pointer border-bb-petrol-600 text-black bg-white flex-grow justify-between relative select-none`};
	${({ status, type }) => {
		switch (status) {
			case "AVAILABLE":
				return tw`bg-white`
			case "PRESENT":
				if (type === "RETURN") {
					return tw`bg-orange-200`
				}
				return tw`bg-green-200`
			case "RESERVED":
				return tw`bg-gray-200`
			case "ERROR":
				return tw`bg-red-200`
			default:
				return tw`bg-blue-200`
		}
	}};
	${({ size, small }) => {
		if (small) {
			return handleBoxSizeSmall(size)
		}
		return handleBoxSize(size)
	}}
`
export const StyledIndicator = styled.div<{ status: string; type: string }>`
	${tw`flex relative top-1 right-1 items-center justify-center text-xs text-white rounded-full w-[18px] h-[18px] 2xl:w-[24px] 2xl:h-[24px] border-2 border-bb-petrol-600 bg-bb-warn`};
	${({ status, type }) => {
		switch (status) {
			case "AVAILABLE":
				return tw`bg-white`
			case "PRESENT":
				if (type === "RETURN") {
					return tw`bg-bb-warn`
				}
				return tw`bg-bb-green-500`
			case "RESERVED":
				return tw`bg-gray-300`
			case "ERROR":
				return tw`bg-bb-red-500`
			default:
				return tw`bg-blue-400`
		}
	}};
`

export const BoxDimOverlay = styled.div`
	${tw`bg-black bg-opacity-50 absolute w-full h-full`}
`
