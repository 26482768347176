import styled from "styled-components"
import tw from "twin.macro"

type StyledButtonProps = {
	enabled: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
	${tw`flex flex-row h-6 items-center pr-2 mb-2 mr-1 hover:ring-indigo-800 text-xs rounded-full font-normal`}
	${({ enabled }) => (enabled ? tw`bg-bb-green-300 pl-0` : tw`bg-bb-red-300 pl-2`)};
`

export const StyledSearchFilterContainer = styled.div`
	${tw`flex flex-row flex-wrap mb-2`}
`
export const StyledFilterSections = styled.div``
export const StyledFilterHeader = styled.h2`
	${tw` text-lg text-bb-white`}
`
export const StyledFilterSection = styled.div`
	${tw`flex flex-wrap py-2`}
`
export const StyledFilterSectionHeader = styled.h3`
	${tw`text-base text-bb-white`}
`
