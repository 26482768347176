import { ButtonVariants } from "@budbee-monorepo/ui"
import { ExternalEnvironmentVariables } from "../utils/environment-variables"
import {
	IBox,
	ILocker,
	ILockersWithin,
	TLockerDemand,
} from "./lockers"
import { ILogsAndComments } from "./logsAndComments"

declare global {
	interface Window{
		config: ExternalEnvironmentVariables
	}
}

export enum Country {
	DK = "DK",
	FI = "FI",
	NL = "NL",
	SE = "SE",
}

export enum BoxSize {
	XL = "BOX_400_300_600",
	L = "BOX_265_225_600",
	M = "BOX_265_150_600",
	S = "BOX_265_100_600",
	XS = "BOX_240_70_340"
}

export enum BoxSizeFriendly {
	XL = "XL",
	L = "L",
	M = "M",
	S = "S",
	XS = "XS",
}

export enum BoxSizeV2 {
	WIDE_XL = "BOX_450_450_600",
	WIDE_L = "BOX_450_360_600",
	WIDE_M = "BOX_450_265_600",
	WIDE_S = "BOX_450_170_600",
	WIDE_XS = "BOX_450_122_600",
	REGULAR_L = "BOX_265_265_600",
	REGULAR_M = "BOX_265_170_600",
	REGULAR_S = "BOX_265_122_600",
	REGULAR_XS = "BOX_265_75_600",
	NARROW_L = "BOX_200_170_600",
	NARROW_M = "BOX_200_122_600",
	NARROW_S = "BOX_200_75_600",
}

export interface ISelectOption {
	value: string | number
	label: string
	isFixed?: boolean
	countryCode?: string
	name?: string
	countryName?: string
	isDisabled?: boolean
}

export type Toggle =
	 TLockerDemand
	| "requiringService"
	| "offline"
	| "disabled"
	| "toBeInstalled"
	| "toBeRemoved"
	| "locationClosed"
	| "awaitingRoute"
	| "awaitingExtension"
	| "pendingCommercial"


export interface ISelectFilter {
	type: string
	active: ISelectOption[]
}

export interface ISidebarFilters {
	select: ISelectFilter[]
	toggle: ITogglableFilters
	isExpandedFilters: boolean
}
export interface IToggleFilter{
	active: boolean
	name: string
}
export type TogglableFilterNames = "showMaxBound" | 
"showLowBound" | 
"showHighdemand" | 
"showEnabled" 

export interface ITogglableFilters{
	showMaxBound: boolean
	showLowBound: boolean
	showHighdemand: boolean
	showEnabled: boolean
	showDisabledReasons?: {
		id: number
	}[] 
}
export interface IMap {
	lockersWithin: ILockersWithin
}

export interface ILockerSlice {
	selectedLocker: string
	showLockerOnMap: ILocker
}

export interface IGlobalModalActions{
	variant: ButtonVariants
	text: string
	action: () => void
}
export interface IGlobalModal{
	type: string | null
	show: boolean
	title: string | null
	text: string | null
}
export interface IGlobalModalConfirmation extends IGlobalModal {
	type: "CONFIRMATION"
	actions: IGlobalModalActions[]
}
export interface IGlobalModalNumberInput extends IGlobalModal  {
	type: "NUMBER_INPUT"
	label: string 
	placeholder: string 
	isValid: (value: number) => boolean
	onSubmit: (value: number) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants

}
export interface IGlobalModalStringInput extends IGlobalModal  {
	type: "STRING_INPUT"
	label: string 
	placeholder: string 
	isValid: (value: string) => boolean
	onSubmit: (value: string) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants

}
export interface IGlobalModalDropdownInput extends IGlobalModal{
	type: "DROPDOWN_INPUT"
	placeholder: string
	label: string
	options: IOption<string | null>[]
	selectedOption: IOption<string | null>
	onCancel: () => void
	isValid: (value: IOption<string | null>) => boolean
	onSubmit: (value: IOption<string | null>) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants
}
export interface IGlobalState {
	sidebarFilters: ISidebarFilters
	lockers: ILockerSlice
	map: IMap
	logsAndComments: ILogsAndComments
	globalModal: IGlobalModal | IGlobalModalConfirmation | IGlobalModalNumberInput | IGlobalModalDropdownInput
}

export type Selector<S> = (state: IGlobalState) => S

export interface IOption<ValueType>{
	value: ValueType
	label: string
}
export interface IBoard{
	boardId: number
	id: number
	boxes: IBox[]
}