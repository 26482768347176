import React, { ReactElement } from "react"
import { BsQuestionOctagon } from "react-icons/bs"
import demandMax from "../../assets/demandMax.png"
import demandMed from "../../assets/demandMed.png"
import demandMin from "../../assets/demandMin.png"
import {
	StyledFooter,
	StyledStatsCol,
	StyledStatsContent,
	StyledStatsDemandCount,
	StyledStatsDemandIndicator,
	StyledStatsDemandRow,
	StyledStatsTitle,
	StyledStatsWrapper,
} from "./StatsDemand.styles"
import StatsLoader from "./StatsLoader"

type OverlayStatsProps = {
	high: number
	medium: number
	low: number
	none: number
	available: number
	isLoading: boolean
	isFetching: boolean
}

const StatsDemand = ({
	high,
	medium,
	low,
	none,
	available,
	isLoading,
	isFetching,
}: OverlayStatsProps): ReactElement => {
	return (
		<StyledStatsWrapper>
			<StyledStatsTitle>Demand</StyledStatsTitle>

			{isLoading || isFetching ? (
				<StatsLoader />
			) : (
				<StyledStatsContent>
					<StyledStatsCol>
						<StyledStatsDemandRow>
							<StyledStatsDemandIndicator src={demandMax} />
							<StyledStatsDemandCount>{high}</StyledStatsDemandCount>
						</StyledStatsDemandRow>
						<StyledStatsDemandRow>
							<StyledStatsDemandIndicator src={demandMed} />
							<StyledStatsDemandCount>{medium}</StyledStatsDemandCount>
						</StyledStatsDemandRow>
					</StyledStatsCol>
					<StyledStatsCol>
						<StyledStatsDemandRow>
							<StyledStatsDemandIndicator src={demandMin} />
							<StyledStatsDemandCount>{low}</StyledStatsDemandCount>
						</StyledStatsDemandRow>
						<StyledStatsDemandRow>
							<BsQuestionOctagon className="text-gray-400 mr-2" size={23} />
							<StyledStatsDemandCount>{none}</StyledStatsDemandCount>
						</StyledStatsDemandRow>
					</StyledStatsCol>
				</StyledStatsContent>
			)}

			<StyledFooter>Lockers available: {available}</StyledFooter>
		</StyledStatsWrapper>
	)
}

export default StatsDemand
