import { skipToken } from "@reduxjs/toolkit/dist/query"
import _ from "lodash"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { ButtonVariants } from "@budbee-monorepo/ui"
import { useGetLockerCommentsQuery } from "../../services/lockerComments"
import {
	lockersApi,
	useGetSpecificLockerQuery,
	useUpdateLockerDemandMutation,
} from "../../services/lockers"
import { useGetDeviceInfoQuery, useGetMacAddressQuery } from "../../services/deviceAccess"
import {
	removeLockerActionCreator,
	setLogsAndCommentsActionCreator,
	setShowLockerOnMapActionCreator,
	toggleLogsAndCommentsActionCreator,
} from "../../store/slices"
import { ICompartmentBySize, ILocker, LockerDemand } from "../../types/lockers"
import { ILockerTab } from "../../types/logsAndComments"
import {
	ExtendedTimeSuccessfullyToast,
	ResetErrorStateForAllBoxesSuccessfullyToast,
	wipToast,
} from "../../utils/toasts"
import InboundParcels from "./InboundParcels"
import { useGlobalModal } from "../../utils/hooks/useGlobalModal"
import {
	OpeningHoursDay,
	OpeningHoursList,
	OpeningHoursListItem,
	OpeningHoursTime,
	StyledBody,
	StyledCommentsNotification,
	StyledDemandIndicator,
	StyledDetailButton,
	StyledDetailClosableSelectWrapper,
	StyledDetailContainer,
	StyledDetailContent,
	StyledDetailContentCol,
	StyledDetailContentColRightAlignedItems,
	StyledDetailLabel,
	StyledDetailLabelInline,
	StyledDetailLink,
	StyledDetailMinorLabel,
	StyledDetailTextBlock,
	StyledInlineCloseButton,
} from "./LockerViewDetailsBody.styles"
import { useExtendExpireDateForAllParcelsMutation } from "../../services/extendTime"
import { ordersApi } from "../../services/orders"
import { useClearAllBoxErrorMutation } from "../../services/clearBoxError"
import { useRoles } from "../../utils/hooks/useRoles"
import { useDeviceAccessAuthorities } from "../../utils/hooks/useAuthorities"
import { SelectTheme, StyledSelect } from "./LockerViewDetailsBodyEditable.styles"
import { IOption } from "../../types/global"
import demandMax from "../../assets/demandMax.png"
import demandMed from "../../assets/demandMed.png"
import demandMin from "../../assets/demandMin.png"

type Props = {
	selectedLocker: ILocker
	ipidt?: ICompartmentBySize
	parcelsOnTheWay?: ICompartmentBySize
	editEnabled: boolean
}

const defaultProps = {
	ipidt: {},
	parcelsOnTheWay: {},
}
const DEMAND_LEVELS: IOption<LockerDemand>[] = [
	{
		label: "Low bound",
		value: LockerDemand.LOW_BOUND,
	},
	{
		label: "High demand",
		value: LockerDemand.HIGH_DEMAND,
	},
	{
		label: "Max bound",
		value: LockerDemand.MAXIMUM_BOUND,
	},
]

const LockerViewDetailsBody = ({ selectedLocker, ipidt, parcelsOnTheWay, editEnabled }: Props) => {
	const [updateDemand, setUpdateDemand] = useState<boolean>(false)
	const { canViewLockers, canUpdateDemandLevel } = useDeviceAccessAuthorities()
	const dispatch = useDispatch()
	const { isOperationsAdministrator } = useRoles()
	const { data: comments, isLoading } = useGetLockerCommentsQuery(selectedLocker.identifier)
	const { data: specificLocker } = useGetSpecificLockerQuery(
		selectedLocker.identifier || skipToken
	)
	const { showConfirmation, showNumberInput, hide } = useGlobalModal()
	const [extendExpireDate, extendExpireDateResult] = useExtendExpireDateForAllParcelsMutation()
	const [clearAllBoxError, clearAllBoxErrorResult] = useClearAllBoxErrorMutation()
	const { data: deviceAccessData, isLoading: deviceAccessLoading } = useGetDeviceInfoQuery(
		canViewLockers ? selectedLocker.identifier : skipToken
	)
	const { data: macAddressData, isLoading: macAddressLoading } = useGetMacAddressQuery(
		canViewLockers ? selectedLocker.identifier : skipToken
	)
	const [updateLockerDemand, updateLockerDemandResult] = useUpdateLockerDemandMutation()
	const handleShowOnMap = () => {
		if (selectedLocker) {
			dispatch(setShowLockerOnMapActionCreator({ locker: selectedLocker }))
			dispatch(removeLockerActionCreator())
			const url = new URL(window.location.toString())
			url.searchParams.delete("locker")
			window.history.pushState({}, "", url)
		}
	}
	const showLockerLogs = (tab: ILockerTab) => {
		dispatch(setLogsAndCommentsActionCreator(tab))
		dispatch(toggleLogsAndCommentsActionCreator())
	}
	const openExtendExpireAllParcelsConfirmation = () => {
		showNumberInput({
			title: "Extend time for all parcels",
			text: "Please enter the number of days you want to extend the expiry time for the parcels in this locker. Minimum 1 day and maximum 7 days",
			placeholder: "ex. 1",
			label: "Days to extend",
			isValid: (value: number) => {
				return value <= 7 && value > 0
			},
			onSubmit: (value: number) => {
				extendExpireDate({ identifier: selectedLocker.identifier, days: value }).then(
					() => {
						ExtendedTimeSuccessfullyToast(value)
						dispatch(
							ordersApi.util.invalidateTags([
								{
									type: "Orders",
								},
							])
						)
						hide()
					}
				)
			},
			submitButtonText: "Submit",
			submitButtonVariant: ButtonVariants.DANGER,
		})
	}
	const openResetAllErrorsConfirmation = () => {
		showConfirmation({
			title: "Are you sure?",
			text: "You are about to reset the error code for this compartment, are you sure this is what you want to do?",
			actions: [
				{
					variant: ButtonVariants.SECONDARY,
					text: "Cancel",
					action: () => {
						hide()
					},
				},
				{
					variant: ButtonVariants.DANGER,
					text: "Yes",
					action: () => {
						clearAllBoxError({ lockerIdentifier: selectedLocker.identifier })
							.then(() => {
								ResetErrorStateForAllBoxesSuccessfullyToast(
									selectedLocker.identifier
								)
								dispatch(
									lockersApi.util.invalidateTags([
										{
											type: "Lockers",
										},
										{
											type: "SpecificLocker",
										},
									])
								)
								hide()
							})
							.catch((e) => {
								hide()
							})
					},
				},
			],
		})
	}
	const openingHours = selectedLocker.openingHours.periods.map((period) => {
		const day = period.open.day.slice(0, 3).toLowerCase()
		const open = period.open.time ? period.open.time.slice(0, 5) : null
		const close = period.close.time ? period.close.time.slice(0, 5) : null

		return (
			<OpeningHoursList key={period.close.day}>
				<OpeningHoursListItem>
					<OpeningHoursDay>{day}</OpeningHoursDay>
					<OpeningHoursTime>
						{open && close ? `${open} - ${close}` : `Closed`}
					</OpeningHoursTime>
				</OpeningHoursListItem>
			</OpeningHoursList>
		)
	})
	let demandIcon
	let demandString

	switch (selectedLocker.demand) {
		case LockerDemand.LOW_BOUND:
			demandIcon = demandMin
			demandString = "Low bound"
			break
		case LockerDemand.HIGH_DEMAND:
			demandIcon = demandMed
			demandString = "High demand"
			break
		case LockerDemand.MAXIMUM_BOUND:
			demandIcon = demandMax
			demandString = "Max bound"
			break
		default:
			demandIcon = demandMin
			demandString = "Low bound"
	}
	const getRmsLink = (mac: string): string => {
		return `https://rms.teltonika-networks.com/management/devices?q=${encodeURIComponent(
			mac
		)}&search-fields=mac`
	}

	const boolToOnline = (online: boolean) => (online ? "yes" : "no")
	const onDemandChange = (demand: IOption<LockerDemand>) => {
		updateLockerDemand({
			identifier: selectedLocker.identifier,
			demand: demand.value,
		})
		setUpdateDemand(false)
	}
	return (
		<StyledBody>
			<StyledDetailContainer>
				<StyledDetailLabel>Status</StyledDetailLabel>
				<StyledDetailContent>
					<div>
						{selectedLocker.enabled
							? "Enabled"
							: `Disabled - ${
									selectedLocker.disabledReason
										? selectedLocker.disabledReason.reason
										: "Unknown reason"
							  }
						`}
					</div>
					<StyledDetailButton onClick={() => showLockerLogs(ILockerTab.LOGS)}>
						View Logs
					</StyledDetailButton>
				</StyledDetailContent>
			</StyledDetailContainer>
			<StyledDetailContainer>
				<StyledDetailContent>
					<StyledDetailLabelInline>Demand</StyledDetailLabelInline>
					<StyledDetailContentColRightAlignedItems>
						<StyledDemandIndicator src={demandIcon} />
					</StyledDetailContentColRightAlignedItems>
				</StyledDetailContent>
				<StyledDetailContent>
					{updateDemand && canUpdateDemandLevel ? (
						<StyledDetailClosableSelectWrapper>
							<StyledSelect
								options={DEMAND_LEVELS}
								defaultValue={DEMAND_LEVELS.find(
									(val) =>
										val.value === selectedLocker.demand ||
										(!selectedLocker.demand &&
											val.value === LockerDemand.LOW_BOUND)
								)}
								onChange={(e) => onDemandChange(e as IOption<LockerDemand>)}
								classNamePrefix="react-select"
								theme={SelectTheme}
							/>
							<StyledInlineCloseButton onClick={() => setUpdateDemand(false)} />
						</StyledDetailClosableSelectWrapper>
					) : (
						<>
							<div>{demandString}</div>
							{canUpdateDemandLevel ? (
								<StyledDetailButton
									type="button"
									onClick={() => setUpdateDemand(true)}
								>
									Update demand
								</StyledDetailButton>
							) : null}
						</>
					)}
				</StyledDetailContent>
			</StyledDetailContainer>
			<StyledDetailContainer>
				<StyledDetailContent>
					<StyledDetailLabelInline>Comments</StyledDetailLabelInline>
					<StyledDetailButton onClick={() => showLockerLogs(ILockerTab.COMMENTS)}>
						View{" "}
						{isLoading || comments === undefined || comments.length === 0 ? (
							""
						) : (
							<StyledCommentsNotification>
								{comments.length}
							</StyledCommentsNotification>
						)}{" "}
						comments
					</StyledDetailButton>
				</StyledDetailContent>
			</StyledDetailContainer>
			{!_.isEmpty(parcelsOnTheWay) && parcelsOnTheWay ? (
				<StyledDetailContainer>
					<StyledDetailLabel>Parcels on way to this box</StyledDetailLabel>
					<StyledDetailContentCol>
						<InboundParcels parcelSizes={parcelsOnTheWay} />
						<StyledDetailButton type="button" onClick={wipToast}>
							View parcels
						</StyledDetailButton>
					</StyledDetailContentCol>
				</StyledDetailContainer>
			) : null}
			{!_.isEmpty(ipidt) && ipidt ? (
				<StyledDetailContainer>
					<StyledDetailLabel>Injected parcels in terminal</StyledDetailLabel>
					<StyledDetailContentCol>
						<InboundParcels parcelSizes={ipidt} />
						<StyledDetailButton type="button" onClick={wipToast}>
							View parcels
						</StyledDetailButton>
					</StyledDetailContentCol>
				</StyledDetailContainer>
			) : null}

			{selectedLocker.locationPartner && selectedLocker.locationPartner.name ? (
				<StyledDetailContainer>
					<StyledDetailLabel>Location partner</StyledDetailLabel>
					<StyledDetailContent>
						<div>{selectedLocker.locationPartner?.name}</div>
						<StyledDetailButton type="button" onClick={wipToast}>
							View details
						</StyledDetailButton>
					</StyledDetailContent>
				</StyledDetailContainer>
			) : null}

			<StyledDetailContainer>
				<StyledDetailLabel>Address</StyledDetailLabel>
				<StyledDetailContent>
					<div>{`${selectedLocker.street}, ${selectedLocker.postalCode} ${selectedLocker.city} ${selectedLocker.country} `}</div>
					<button
						type="button"
						className="font-medium shrink-0 text-bb-petrol-500 cursor-pointer"
						onClick={handleShowOnMap}
					>
						Show on map
					</button>
				</StyledDetailContent>
			</StyledDetailContainer>
			<StyledDetailContainer>
				<StyledDetailLabel>Postal Code Zone</StyledDetailLabel>
				<StyledDetailContent>
					<div>{`${selectedLocker.postalCodeZone.id}, ${selectedLocker.postalCodeZone.country} ${selectedLocker.postalCodeZone.title}`}</div>
					<button
						type="button"
						className="font-medium shrink-0 text-bb-petrol-500 cursor-pointer"
						onClick={wipToast}
					>
						View details
					</button>
				</StyledDetailContent>
			</StyledDetailContainer>
			<StyledDetailContainer>
				<StyledDetailLabel>Directions</StyledDetailLabel>
				<StyledDetailContentCol>
					<StyledDetailMinorLabel>Local language</StyledDetailMinorLabel>
					<StyledDetailTextBlock>{`${selectedLocker.directions}`}</StyledDetailTextBlock>
					<StyledDetailMinorLabel>English</StyledDetailMinorLabel>
					<StyledDetailTextBlock>{`${selectedLocker.directionsEnglish}`}</StyledDetailTextBlock>
				</StyledDetailContentCol>
			</StyledDetailContainer>
			{specificLocker ? (
				<StyledDetailContainer>
					<StyledDetailLabel>Service code</StyledDetailLabel>
					<StyledDetailTextBlock>{specificLocker?.unlockCode}</StyledDetailTextBlock>
				</StyledDetailContainer>
			) : null}
			<StyledDetailContainer>
				<StyledDetailLabel>Parcels expires by</StyledDetailLabel>
				<StyledDetailButton onClick={openExtendExpireAllParcelsConfirmation}>
					Extend expire date for all parcels
				</StyledDetailButton>
			</StyledDetailContainer>
			{isOperationsAdministrator ? (
				<StyledDetailContainer>
					<StyledDetailLabel>Compartment errors</StyledDetailLabel>
					<StyledDetailButton onClick={openResetAllErrorsConfirmation}>
						Reset all compartment errors
					</StyledDetailButton>
				</StyledDetailContainer>
			) : null}
			<StyledDetailContainer>
				<StyledDetailLabel>Coordinates</StyledDetailLabel>
				<StyledDetailContentCol>
					<StyledDetailMinorLabel>Latitude</StyledDetailMinorLabel>
					<StyledDetailTextBlock>{`${selectedLocker.latitude}`}</StyledDetailTextBlock>
					<StyledDetailMinorLabel>Longitude</StyledDetailMinorLabel>
					<StyledDetailTextBlock>{`${selectedLocker.longitude}`}</StyledDetailTextBlock>
				</StyledDetailContentCol>
			</StyledDetailContainer>
			<StyledDetailContainer>
				<StyledDetailLabel>Opening hours:</StyledDetailLabel>
				<StyledDetailContentCol>{openingHours}</StyledDetailContentCol>
			</StyledDetailContainer>
			<StyledDetailContainer hidden={!canViewLockers}>
				<StyledDetailLabel>Locker Info</StyledDetailLabel>
				<StyledDetailContentCol>
					{deviceAccessLoading ? (
						<div>Loading</div>
					) : (
						<StyledDetailContentCol>
							<StyledDetailMinorLabel>Device ID</StyledDetailMinorLabel>
							<StyledDetailTextBlock>
								{deviceAccessData ? deviceAccessData.deviceId : "No data"}
							</StyledDetailTextBlock>
							<StyledDetailMinorLabel>Online</StyledDetailMinorLabel>
							<StyledDetailTextBlock>
								{deviceAccessData && deviceAccessData.online
									? boolToOnline(deviceAccessData.online)
									: "No data"}
							</StyledDetailTextBlock>
							<StyledDetailMinorLabel>DAC version</StyledDetailMinorLabel>
							<StyledDetailTextBlock>
								{deviceAccessData ? deviceAccessData.version : "No data"}
							</StyledDetailTextBlock>
							<StyledDetailMinorLabel>RMS</StyledDetailMinorLabel>
							<StyledDetailTextBlock>
								{macAddressData ? (
									<StyledDetailLink
										target="_blank"
										rel="noreferrer"
										href={getRmsLink(macAddressData)}
									>
										Link to router
									</StyledDetailLink>
								) : (
									"Unknown"
								)}
							</StyledDetailTextBlock>
						</StyledDetailContentCol>
					)}
				</StyledDetailContentCol>
			</StyledDetailContainer>
		</StyledBody>
	)
}

LockerViewDetailsBody.defaultProps = defaultProps

export default LockerViewDetailsBody
