import { useAuth0 } from "@auth0/auth0-react"

export interface UseRolesResult{
    isAdmin: boolean
    isOperationsAdministrator: boolean
}

export const useRoles = (): UseRolesResult => {
    const auth = useAuth0();
    if(auth && auth.user){
        const roles = auth.user["https://webapi.budbee.com/roles"]
        const isAdmin = roles.includes("admin")
        const isOperationsAdministrator = isAdmin || roles.includes("* operations administrator")
        return {
            isAdmin,
            isOperationsAdministrator
        }
    }
    return {
        isAdmin: false,
        isOperationsAdministrator: false
    }
    
}