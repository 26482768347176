import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"
import { IAddLockerComment, IDeleteLockerComment, IEditLockerComment, ILockerComment } from "../types/lockerComment"

const baseQuery = fetchBaseQuery({
	baseUrl: ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

// Define a service using a base URL and expected endpoints
export const lockerCommentsApi = createApi({
	reducerPath: "lockerComments",
	baseQuery: baseQuery,
	tagTypes: ["LockerComments"],
	endpoints: (builder) => ({
		getLockerComments: builder.query<ILockerComment[], string>({
            query: (lockerIdentifier) => {
                return `${window.config.REACT_APP_API_BASE_URL}bumblebox/comments/${lockerIdentifier}`
            },
            providesTags: (result, error, arg) => [{ type: "LockerComments", id: arg }]
        }),
        addLockerComment: builder.mutation<void, IAddLockerComment>({
            query:(data) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/comment`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'LockerComments', id: arg.lockerIdentifier }],
        }),
        editLockerComment: builder.mutation<void, IEditLockerComment>({
            query:({ id, data }) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/comment/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'LockerComments', id: arg.lockerIdentifier }],
        }),
        deleteLockerComment: builder.mutation<void, IDeleteLockerComment>({
            query: ({ id }) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/comment/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'LockerComments', id: arg.lockerIdentifier }],
        })
		
	}),
})

export const { useGetLockerCommentsQuery, useAddLockerCommentMutation, useEditLockerCommentMutation, useDeleteLockerCommentMutation } =
	lockerCommentsApi
