import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { LockerDemand } from "../types/lockers"
import { StyledBadge, StyledLockerName } from "./ClusterMapView.styles"
import DemandIndicator from "./DemandIndicator"

const StyledEnabledStatus = styled.div(({ enabled = false }: { enabled?: boolean }) => {
	return [tw`w-1.5 h-8 rounded-md`, enabled ? tw`bg-bb-green-500` : tw`bg-bb-red-500`]
})

type Props = {
	enabled: boolean
	demand: LockerDemand
	identifier: string
	name: string
}

const PopupContent = ({ enabled, demand, identifier, name }: Props) => {
	return (
		<div className="flex flex-row items-center">
			<StyledEnabledStatus enabled={enabled} />
			<DemandIndicator demand={demand} />
			<StyledBadge>{identifier}</StyledBadge>
			<StyledLockerName>{name}</StyledLockerName>
		</div>
	)
}

export default PopupContent
