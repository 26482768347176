import { createSelector } from "@reduxjs/toolkit"
import { IGlobalState } from "../../types/global"
import { ILocker } from "../../types/lockers"

export interface ILockerStats {
	demand: {
		HIGH_DEMAND: number
		LOW_BOUND: number
		MAXIMUM_BOUND: number
		none: number
	}
	countLockersAvailable: number
	countDisabledLockersSelected: number
	countLockersSelected: number
	countTotalDisabledLockers: number
}


export const selectActiveSelectionFiltersByType = () =>
	createSelector(
		(state: IGlobalState) => state.sidebarFilters.select,
		(_: IGlobalState, type: string) => type,
		(filters, type) => filters.find((filter) => filter.type === type)?.active
	)

export const selectAllActiveSelectionFilters = createSelector(
	(state: IGlobalState) => state.sidebarFilters.select,
	(filters) => filters.filter((filter) => filter.active.length)
)


const emptyLockers: any[] = []

//todo-hevar: evaluate and optimizes
export const selectFilteredLockers = createSelector(
	(_: IGlobalState, lockers: ILocker[]) => lockers,
	(state: IGlobalState) => state.sidebarFilters.toggle,
	selectAllActiveSelectionFilters,
	(lockers, toggles, activeSelects) => {
		const activeCountries = activeSelects.find((filter) => filter.type === "country")?.active
		const activeRegions = activeSelects.find((filter) => filter.type === "region")?.active
		return lockers.filter((locker) => {
			if (activeCountries) {
				const isLockerCountryInFilteredCountries = activeCountries?.some(
					(option) => option.value === locker.country
				)
				if (!isLockerCountryInFilteredCountries) {
					return false
				}
			}
			if (activeRegions) {
				const isLockerRegionInFilteredCountries = activeRegions?.some((option) => {
					return option.value === locker.postalCodeZone.id
				})

				if (!isLockerRegionInFilteredCountries) {
					return false
				}
			}
			if(locker.enabled && !toggles.showEnabled) return false;
			if(locker.disabledReason && toggles.showDisabledReasons){
				const allowDisabledReason = toggles.showDisabledReasons.find(reason => locker.disabledReason?.id === reason.id)
				if(!allowDisabledReason) return false;
			}
			if(!locker.disabledReason){
				switch(locker.demand){
					case "HIGH_DEMAND":
						if(!toggles.showHighdemand) return false;
					break;
					case "LOW_BOUND":
						if(!toggles.showLowBound) return false;
					break;
					case "MAXIMUM_BOUND":
						if(!toggles.showMaxBound) return false;
					break;
					default:
						if(!toggles.showLowBound) return false;
					break;
				}
			}
			return true
		})
	}
)

const getLockers = (_: IGlobalState, lockers: ILocker[]) => lockers

const getDisabledLockers = createSelector(getLockers, (lockers) => {
	return lockers.filter((locker) => !locker.enabled) ?? emptyLockers
})

export const selectLockerStats = createSelector(
	selectFilteredLockers,
	getDisabledLockers,
	(lockers, disabledLockers): ILockerStats => {
		const initStats = {
			demand: {
				HIGH_DEMAND: 0,
				LOW_BOUND: 0,
				MAXIMUM_BOUND: 0,
				none: 0,
			},
			countDisabledLockersSelected: 0,
			countLockersAvailable: 0,
			countLockersSelected: lockers.length,
			countTotalDisabledLockers: disabledLockers.length,
		}

		return lockers.reduce((acc, cur) => {
			if (cur.demand) {
				acc.demand[cur.demand] += 1
			} else {
				acc.demand.none += 1
			}

			if (cur.available) {
				acc.countLockersAvailable += 1
			}

			if (!cur.enabled) {
				acc.countDisabledLockersSelected += 1
			}

			return acc
		}, initStats)
	}
)
