import { isEmpty } from "lodash"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"
import { useSearchParams } from "react-router-dom"
import { config, useTransition } from "react-spring"
import { useGetSpecificLockerQuery } from "../../services/lockers"
import {
	removeLockerActionCreator,
	removeShowLockerOnMapActionCreator,
	selectLockerActionCreator,
	toggleLogsAndCommentsActionCreator,
} from "../../store/slices"
import { IBoard, IGlobalState } from "../../types/global"
import { IBox } from "../../types/lockers"
import boxSizeFormatter from "../../utils/boxSizeFormatter"
import Drawer from "../Drawer/Drawer"
import LockerLogsAndCommentsBody from "../LockerLogsAndComments/LockerLogsAndCommentsBody"
import LockerLogsAndCommentsHeader from "../LockerLogsAndComments/LockerLogsAndCommentsHeader"
import LockerBox from "./LockerBox"
import LockerScreen from "./LockerScreen"
import {
	SizeLabel,
	StyledBackdrop,
	StyledCol,
	StyledContainer,
	StyledContent,
	StyledLocker,
	StyledLockerContainer,
	StyledRow,
	StyledStatusContainer,
	StyledStatusIndicator,
	StyledStatusRow,
	StyledStatusText,
} from "./LockerView.styles"
import LockerViewDetails from "./LockerViewDetails"
import { sortArrayByParameter, splitArray } from "../../utils/arrayHelpers"
import LockerModuleEdit from "./LockerModuleEdit"
import { useRoles } from "../../utils/hooks/useRoles"

const LockerView = (): ReactElement | null => {
	const selectedLocker = useSelector((state: IGlobalState) => state.lockers.selectedLocker)
	const logsAndCommentsIsOpen = useSelector((state: IGlobalState) => state.logsAndComments.isOpen)
	const [selectedBoxes, setSelectedBoxes] = useState<IBox[]>([])
	const [editEnabled, setEditEnabled] = useState<boolean>(false)
	const [boxMatrix, setBoxMatrix] = useState<IBox[][]>([])
	const [boards, setBoards] = useState<IBoard[]>([])
	const { isOperationsAdministrator } = useRoles()
	const dispatch = useDispatch()
	const {
		data: specificLocker,
		isLoading: specificLockerIsLoading,
		isFetching: specificLockerIsFetching,
		isSuccess: specificLockerIsSuccess,
	} = useGetSpecificLockerQuery(selectedLocker || skipToken)

	const transitions = useTransition(selectedLocker, {
		from: { opacity: 0, transform: "translate3d(150%,0,0)" },
		enter: { opacity: 1, transform: "translate3d(0%,0,0)", config: config.gentle },
	})
	useEffect(() => {
		const url = new URL(window.location.toString())
		const identifier = url.searchParams.get("locker")
		if (identifier) {
			dispatch(selectLockerActionCreator({ identifier }))
		}
	}, [dispatch])
	useEffect(() => {
		setSelectedBoxes([])
	}, [selectedLocker, specificLocker])

	const getMatrix = useCallback((): IBox[][] => {
		if (!specificLocker) return []
		const boxesForSort = [...specificLocker.boxes]
		const sortedByBoardId = sortArrayByParameter(boxesForSort, "boardId")

		const matrix: IBox[][] = []
		const sortBySlot = (x: IBox, y: IBox) => x.slotId - y.slotId

		Object.keys(sortedByBoardId).forEach((key) => {
			const [below, over] = splitArray(
				sortedByBoardId[key].sort(sortBySlot),
				(val: IBox) => val.slotId < 25
			)
			const leftColumn = below
			const rightColumn = over
			if (Number(key) % 2 === 0) {
				matrix.unshift(leftColumn, rightColumn)
			} else {
				matrix.push(leftColumn, rightColumn)
			}
		})
		return matrix
	}, [specificLocker])

	const getBoards = useCallback((): IBoard[] => {
		if (!specificLocker) return []
		const boxesForSort = [...specificLocker.boxes]
		const tempBoards: IBoard[] = []
		boxesForSort.forEach((box) => {
			const index = tempBoards.findIndex((value) => box.boardId === value.boardId)
			if (index === -1) {
				tempBoards.push({ boardId: box.boardId, id: box.id, boxes: [box] })
			} else {
				tempBoards[index].boxes.push(box)
			}
		})
		tempBoards.forEach((board) => {
			board.boxes.sort((a, b) => a.slotId - b.slotId)
		})
		return tempBoards.sort((a, b) => {
			if (a.boardId === 0) {
				if (b.boardId % 2 === 0) {
					return 1
				}
				return -1
			}
			if (b.boardId === 0) {
				if (a.boardId % 2 === 0) {
					return -1
				}
				return 1
			}
			if (a.boardId % 2 === b.boardId % 2) {
				if (a.boardId % 2 === 0) {
					return b.boardId - a.boardId
				}
				return a.boardId - b.boardId
			}
			if (a.boardId % 2 === 0) {
				return -1
			}
			return 1
		})
	}, [specificLocker])

	useEffect(() => {
		setBoxMatrix(getMatrix())
		setBoards(getBoards())
	}, [getMatrix, getBoards])

	if (!selectedLocker || !specificLocker) {
		return null
	}
	const selectBoard = (boardId: number) => {
		if (selectedBoxes.findIndex((box) => box.boardId === boardId) === -1) {
			const boxes: IBox[] = []
			specificLocker.boxes.forEach((box) => {
				if (box.boardId === boardId) {
					boxes.push(box)
				}
			})
			setSelectedBoxes(boxes)
		} else {
			setSelectedBoxes([])
		}
	}
	const addOrRemoveBoardFromSelect = (boardId: number) => {
		if (selectedBoxes.findIndex((box) => box.boardId === boardId) === -1) {
			const boxes: IBox[] = []
			specificLocker.boxes.forEach((box) => {
				if (box.boardId === boardId) {
					boxes.push(box)
				}
			})
			setSelectedBoxes([...selectedBoxes, ...boxes])
		} else {
			const boxes: IBox[] = []
			selectedBoxes.forEach((box) => {
				if (box.boardId !== boardId) {
					boxes.push(box)
				}
			})
			setSelectedBoxes(boxes)
		}
	}
	const handleOnClickBox = (event: React.MouseEvent<HTMLElement>, box: IBox) => {
		const existsIndex = selectedBoxes.findIndex((b) => b.id === box.id)
		if (event.altKey && event.shiftKey) {
			addOrRemoveBoardFromSelect(box.boardId)
		} else if (event.altKey) {
			selectBoard(box.boardId)
		} else if (existsIndex !== -1) {
			if (event.shiftKey) {
				setSelectedBoxes(selectedBoxes.filter((b) => b.id !== box.id))
			} else {
				setSelectedBoxes([])
			}
		} else if (event.shiftKey) {
			setSelectedBoxes([...selectedBoxes, box])
		} else {
			setSelectedBoxes([box])
		}
	}

	const handleClearSelectedBox = () => {
		setSelectedBoxes([])
	}

	const handleOnClick = () => {
		const url = new URL(window.location.toString())
		url.searchParams.delete("locker")
		window.history.pushState({}, "", url)
		dispatch(removeLockerActionCreator())
		dispatch(removeShowLockerOnMapActionCreator())
		handleClearSelectedBox()
	}
	const handleCloseLogsAndComments = () => {
		dispatch(toggleLogsAndCommentsActionCreator())
	}

	const statuses = ["Available", "Delivery", "Reserved", "Return", "Error"]
	const keys = []
	const renderCompartment = (box: IBox, v2: boolean) => {
		const boxIsSelected = selectedBoxes.findIndex((b) => b.id === box.id) !== -1
		const compartment = (
			<LockerBox
				key={box.id}
				box={box}
				selectedBox={boxIsSelected}
				hasAnySelectedBoxes={selectedBoxes.length > 0}
				handleOnClick={handleOnClickBox}
			>
				<SizeLabel size={box.size}>{boxSizeFormatter(box.size)}</SizeLabel>
			</LockerBox>
		)
		const screen = (
			<LockerScreen hasSelectedBox={selectedBoxes.length > 0} key="screen" v2={v2} />
		)
		const screenPostion = { boardId: 0, slotId: 1 }
		if (v2) {
			screenPostion.slotId = 3
		}
		keys.push(box.id)
		if (box.boardId === screenPostion.boardId && box.slotId === screenPostion.slotId) {
			return (
				<>
					{compartment}
					{screen}
				</>
			)
		}
		return compartment
	}
	return transitions((style) => (
		<StyledContainer>
			<StyledBackdrop onClick={handleOnClick} />
			<StyledContent style={{ ...style }}>
				{editEnabled && isOperationsAdministrator ? (
					<LockerModuleEdit boards={boards} locker={specificLocker.locker} />
				) : (
					<StyledLockerContainer>
						<StyledLocker>
							{boxMatrix.map((row, index) => {
								if (row.length > 0) {
									return (
										<StyledRow key={`board-${row[0].boardId}-${index % 2}`}>
											<StyledCol>
												{row.map((box) =>
													renderCompartment(
														box,
														specificLocker.locker.version === "indoor_2"
													)
												)}
											</StyledCol>
										</StyledRow>
									)
								}
								return null
							})}
						</StyledLocker>
						<StyledStatusRow>
							{statuses.map((status) => (
								<StyledStatusContainer key={status}>
									<StyledStatusIndicator status={status} />
									<StyledStatusText>{status}</StyledStatusText>
								</StyledStatusContainer>
							))}
						</StyledStatusRow>
					</StyledLockerContainer>
				)}

				<LockerViewDetails
					selectedBoxes={selectedBoxes}
					clearSelectedBox={handleClearSelectedBox}
					editEnabled={editEnabled}
					setEditEnabled={setEditEnabled}
				/>
			</StyledContent>
			<Drawer isOpen={logsAndCommentsIsOpen} onClose={handleCloseLogsAndComments}>
				<LockerLogsAndCommentsHeader lockerName={selectedLocker} />
				<LockerLogsAndCommentsBody />
			</Drawer>
		</StyledContainer>
	))
}

export default LockerView
