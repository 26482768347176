export enum LockerDemand {
	HIGH_DEMAND = "HIGH_DEMAND",
	LOW_BOUND = "LOW_BOUND",
	MAXIMUM_BOUND = "MAXIMUM_BOUND",
}

export type TLockerDemand = "HIGH_DEMAND" | "LOW_BOUND" | "MAXIMUM_BOUND"

export interface IGetLockers {
	pageInfo: IPageInfo
	records?: ILocker[] | null
}
export interface IPageInfo {
	hasNext: boolean
	limit: number
	offset: number
	pageCount: number
	pageIndex: number
	totalRecordsCount: number
}

export interface ICompartmentBySize {
	BOX_240_70_340: number
	BOX_265_100_600: number
	BOX_265_150_600: number
	BOX_265_225_600: number
	BOX_400_300_600: number
}

export interface IOpeningHours {
	weekdayText: string[]
	periods: {
		close: {
			day: string
			time: string | null
		}
		open: {
			day: string
			time: string | null
		}
	}[]
}
export interface ILocker {
	id: number
	identifier: string
	name: string
	street: string
	directions: string
	directionsEnglish: string
	postalCode: string
	city: string
	country: string
	latitude: number
	longitude: number
	postalCodeZone: IPostalCodeZone
	installationDate: string
	enabled: boolean
	available: boolean
	collectionPointId: number
	openingHours: IOpeningHours
	locationPartner?: ILocationPartner | null
	contactPerson: string
	contactPhoneNumber: string
	demand?: keyof typeof LockerDemand
	customerSupport: ICustomerSupport
	disabledReason?: IDisabledReason | null
	version: string
}
export interface IPostalCodeZone {
	id: number
	title: string
	country: string
}
export interface ILocationPartner {
	id: number
	name: string
}
export interface ICustomerSupport {
	id: number
	name: string
	phoneNumber: string
	email: string
}
export interface IDisabledReason {
	id: number
	reason: string
}

export interface ILockersWithin {
	id: string
	radius: number
	coordinates: {
		lat: number
		lng: number
	}
	customPin: {
		lat: number
		lng: number
		showOnMap: boolean
	} | null
	flyTo: {
		lat: number
		lng: number
	} | null
}

export interface IBox {
	id: number
	name: string
	size: string
	width: number
	depth: number
	height: number
	lockerId: number
	boardId: number
	slotId: number
	errorCode?: string | boolean | number | null
	status: string
	type: string
	parcelId: number
	orderToken: string
}

export interface ISpecificLocker {
	boxMatrixMaxBounds: {
		x: number
		y: number
	}
	boxes: IBox[]
	locker: ILocker
	unlockCode: string
	injectedParcelsInDistributionTerminal: ICompartmentBySize
	parcelsOnTheWay: ICompartmentBySize
}
export interface IUpdateLockerDetails {
	identifier: string
	data: {
		name: string
		street: string
		city: string
		country: string
		directions: string
		directionsEnglish: string
		postalCodeZone: IPostalCodeZone
		postalCode: string
		openingHours: IOpeningHours
		latitude: number
		longitude: number
		enabled: boolean
		version: string
		disabledReason: {
			id: number
			reason: string
		} | null
	}
}
export interface IUpdateLockerDemand {
	identifier: string
	demand: LockerDemand
}
