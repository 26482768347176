import React, { ReactNode } from "react"
import { MdClose } from "react-icons/md"
import { DrawerCloseButton, DrawerContentWrapper, DrawerFade, Wrapper } from "./Drawer.styles"

type Props = {
	isOpen: boolean
	children: ReactNode
	onClose: () => void
}
const Drawer = ({ isOpen, children, onClose }: Props) => {
	return (
		<Wrapper isOpen={isOpen}>
			<DrawerFade isOpen={isOpen} onClick={onClose} />
			<DrawerContentWrapper isOpen={isOpen}>
				<DrawerCloseButton isOpen={isOpen} onClick={onClose}>
					<MdClose size="1.5em" />
				</DrawerCloseButton>
				{isOpen ? children : null}
			</DrawerContentWrapper>
		</Wrapper>
	)
}

export default Drawer
