import { ButtonVariants } from "@budbee-monorepo/ui"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import React from "react"
import { BsBoxArrowUpRight } from "react-icons/bs"
import { useDispatch } from "react-redux"
import {
	useGetErrorCodesQuery,
	useUpdateLockerBoxStatusMutation,
} from "../../services/clearBoxError"
import { useExtendExpireDateMutation } from "../../services/extendTime"
import { useGetDeviceInfoQuery, useOpenLockerMutation } from "../../services/deviceAccess"
import { ordersApi, useGetOrderQuery } from "../../services/orders"
import { lockersApi } from "../../services/lockers"
import { IBox } from "../../types/lockers"
import { useGlobalModal } from "../../utils/hooks/useGlobalModal"
import { timeAndDate } from "../../utils/timeFormatter"
import {
	ExtendedTimeSuccessfullyToast,
	errorToast,
	ResetErrorStateSuccessfullyToast,
	OpenCompartmentSuccessToast,
	OpenCompartmentFailedToast,
	OpenCompartmentWorkingToast,
} from "../../utils/toasts"
import {
	BodyWrapper,
	ButtonWrapper,
	StyledBadge,
	StyledButton,
	StyledCloseButton,
	StyledStatus,
} from "./SelectedBoxView.styles"
import { IOption } from "../../types/global"
import { Environment, getEnvironment } from "../../utils/environment-variables"
import { useRoles } from "../../utils/hooks/useRoles"
import { useDeviceAccessAuthorities } from "../../utils/hooks/useAuthorities"
import { useSetParcelToPresentMutation, useUnassignParcelMutation } from "../../services/parcels"

type Props = {
	lockerIdentifier: string
	selectedBox: IBox
	clearSelectedBox: () => void
}

const SelectedBoxView = ({ lockerIdentifier, selectedBox, clearSelectedBox }: Props) => {
	const { id, orderToken, parcelId, size, status, type, errorCode, boardId, slotId } = selectedBox
	const { showNumberInput, showStringInput, hide, showDropdownInput, showConfirmation } =
		useGlobalModal()
	const { data: order, isLoading } = useGetOrderQuery(orderToken || skipToken)
	const [extendExpireDate, extendExpireDateResult] = useExtendExpireDateMutation()
	const [updateLockerBoxStatus, updateLockerBoxStatusResult] = useUpdateLockerBoxStatusMutation()
	const [unassignParcel, unassignParcelResult] = useUnassignParcelMutation()
	const [setParcelToPresent, setParcelToPresentResult] = useSetParcelToPresentMutation()
	const { isOperationsAdministrator } = useRoles()
	const { data: errorCodes } = useGetErrorCodesQuery()
	const [openLockerQuery, openLockerQueryResult] = useOpenLockerMutation()
	const dispatch = useDispatch()
	const { canOpenLockers, canViewLockers } = useDeviceAccessAuthorities()
	const { isLoading: deviceAccessLoading, data: deviceAccessData } = useGetDeviceInfoQuery(
		canViewLockers ? lockerIdentifier : skipToken
	)
	const openAvailable = !deviceAccessLoading && deviceAccessData && deviceAccessData.online
	const openCompartment = (reason: string, loadingToastHandle: string | number) => {
		openLockerQuery({
			reason: reason,
			lockerId: lockerIdentifier,
			board: selectedBox.boardId,
			slot: selectedBox.slotId,
		})
			.then((value) => {
				if ("data" in value) {
					const { success, message, tries } = value.data
					if (!success) {
						const finalMessage = `${message || "Open locker failed"} Tries: ${tries}`
						OpenCompartmentFailedToast(loadingToastHandle, finalMessage)
					} else {
						OpenCompartmentSuccessToast(loadingToastHandle)
					}
				} else {
					OpenCompartmentFailedToast(loadingToastHandle, "Failed to open, server error")
				}
			})
			.catch(() => {
				OpenCompartmentFailedToast(loadingToastHandle, "Failed to open, network error")
			})
	}

	const handleOpenBox = () => {
		if (!openAvailable) {
			errorToast("Open is not available on locker")
			return
		}

		showStringInput({
			title: "Are you sure?",
			text: "You are about to open a compartment, are you sure this is what you want to do? Ask customer to read back locker identifier!",
			placeholder: "A reason for opening this compartment",
			label: "Reason",
			isValid: (value: string) => {
				return value.length > 0
			},
			onSubmit: (value: string) => {
				const loadingToastHandle = OpenCompartmentWorkingToast()
				openCompartment(value, loadingToastHandle)
				hide()
			},
			submitButtonText: "Open",
			submitButtonVariant: ButtonVariants.DANGER,
		})
	}
	const handleUpdateBoxState = () => {
		let options: IOption<string | null>[] = []
		if (errorCodes) {
			options = errorCodes.map((code) => {
				return {
					value: code.errorCode,
					label: code.humanReadable,
				}
			})
		}
		const selectedOption = options.find((option) => option.value === errorCode)
		if (!selectedOption) return
		showDropdownInput({
			title: "Update compartment status?",
			text: "You are about to change the error code for this compartment, are you sure this is what you want to do?",
			options,
			selectedOption,
			label: "",
			placeholder: "",
			isValid: function (value: IOption<string | null>): boolean {
				return value.value !== selectedOption.value && value.label !== selectedOption.label
			},
			onSubmit: function (value: IOption<string | null>): void {
				ResetErrorStateSuccessfullyToast(id)
				updateLockerBoxStatus({
					boxIdentifier: id,
					errorCode: value.value,
				}).then(() => {
					dispatch(
						lockersApi.util.invalidateTags([
							{
								type: "SpecificLocker",
							},
						])
					)
					hide()
				})
			},
			submitButtonText: "Change",
			submitButtonVariant: ButtonVariants.PRIMARY,
		})
	}
	const handleExtendTime = () => {
		const packageId = order.parcels.find((parcel: any) => parcel.id === parcelId)?.packageId
		showNumberInput({
			title: "Extend time for parcel",
			text: "Please enter the number of days you want to extend the expiry time for this parcel. Minimum 1 day and maximum 7 days",
			placeholder: "ex. 1",
			label: "Days to extend",
			isValid: (value: number) => {
				return value <= 7 && value > 0
			},
			onSubmit: (value: number) => {
				extendExpireDate({ packageId, days: value }).then(() => {
					ExtendedTimeSuccessfullyToast(value)
					dispatch(
						ordersApi.util.invalidateTags([
							{
								type: "Orders",
								id: orderToken,
							},
						])
					)
					hide()
				})
			},
			submitButtonText: "Submit",
			submitButtonVariant: ButtonVariants.DANGER,
		})
	}

	const handleUnassignParcel = () => {
		showConfirmation({
			title: "Are you sure?",
			text: "You are about to unassign this parcel. This is an irreversible action and cannot be undone",
			actions: [
				{
					variant: ButtonVariants.DANGER,
					text: "Unassign",
					action: () => {
						unassignParcel({
							identifier: lockerIdentifier,
							parcelId,
						}).then(() => {
							dispatch(
								lockersApi.util.invalidateTags([
									{
										type: "SpecificLocker",
									},
								])
							)
							hide()
						})
					},
				},
				{
					variant: ButtonVariants.SECONDARY,
					text: "Cancel",
					action: () => {
						hide()
					},
				},
			],
		})
	}
	const progressParcelToPresent = () => {
		showConfirmation({
			title: "Are you sure?",
			text: "You are about to progress this parcel to Present. This is an irreversible action and cannot be undone",
			actions: [
				{
					variant: ButtonVariants.DANGER,
					text: "Set to present",
					action: () => {
						setParcelToPresent({
							identifier: lockerIdentifier,
							parcelId,
						}).then(() => {
							dispatch(
								lockersApi.util.invalidateTags([
									{
										type: "SpecificLocker",
									},
								])
							)
							hide()
						})
					},
				},
				{
					variant: ButtonVariants.SECONDARY,
					text: "Cancel",
					action: () => {
						hide()
					},
				},
			],
		})
	}

	const orderParcel = order?.parcels.find((parcel: any) => parcel.id === parcelId)
	const carriersLink =
		getEnvironment() === Environment.PRODUCTION
			? "https://carriers.budbee.com/admin/orders/"
			: "https://carriers.testing.budbee.com/admin/orders/"
	return (
		<div className="flex flex-col bg-bb-petrol-600 w-full rounded-t-xl rounded-b-lg absolute bottom-0 left-0">
			<StyledCloseButton onClick={clearSelectedBox} />
			<div className="flex flex-row w-16 h-1 mt-2 rounded-full bg-white opacity-75 justify-self-center self-center" />
			<BodyWrapper>
				<ButtonWrapper>
					<StyledButton
						onClick={handleUpdateBoxState}
						disabled={status === "PRESENT"}
						displayDisabled={!isOperationsAdministrator}
					>
						Update State
					</StyledButton>
					<StyledButton
						onClick={handleExtendTime}
						disabled={status !== "PRESENT" || type === "RETURN" || isLoading}
					>
						Extend time
					</StyledButton>
					{type === "DELIVERY" && status === "PRESENT" && isOperationsAdministrator ? (
						<StyledButton onClick={handleUnassignParcel}>Unassign</StyledButton>
					) : null}
					{status === "UNLOCKING" && isOperationsAdministrator ? (
						<StyledButton onClick={progressParcelToPresent}>
							Set to present
						</StyledButton>
					) : null}
					{canOpenLockers && (
						<StyledButton onClick={handleOpenBox} displayDisabled={!openAvailable}>
							Open Box
						</StyledButton>
					)}
				</ButtonWrapper>
				<div className="text-gray-200 flex flex-col mb-2 divide-y">
					<div className="flex flex-row justify-between py-2">
						<div>Status/Type:</div>
						<div className="">
							<StyledStatus status={status} type={type}>
								{status} {type}
							</StyledStatus>
						</div>
					</div>
					{errorCode && (
						<div className="flex flex-row justify-between py-2">
							<div>Error code:</div>
							<StyledBadge>{errorCode}</StyledBadge>
						</div>
					)}
					{orderParcel?.packageId && (
						<div className="flex flex-row justify-between py-2">
							<div>Parcel Id:</div>
							<StyledBadge>{orderParcel.packageId}</StyledBadge>
						</div>
					)}
					{orderToken && (
						<div className="flex flex-row justify-between py-2">
							<div>Order token:</div>
							<a
								target="_blank"
								rel="noreferrer"
								href={`${carriersLink}${orderToken}`}
							>
								<StyledBadge>
									{orderToken}
									<BsBoxArrowUpRight className="ml-2" />
								</StyledBadge>
							</a>
						</div>
					)}
					{size && (
						<div className="flex flex-row justify-between py-2">
							<div>Size:</div>
							<StyledBadge>{size}</StyledBadge>
						</div>
					)}
					{orderParcel?.expiresAt && (
						<div className="flex flex-row justify-between py-2">
							<div>Expires at:</div>
							<StyledBadge>
								{timeAndDate(new Date(orderParcel.expiresAt))}
							</StyledBadge>
						</div>
					)}
					<div className="flex flex-row justify-between py-2">
						<div>Board: {boardId}</div>
					</div>
					<div className="flex flex-row justify-between py-2">
						<div>Slot: {slotId}</div>
					</div>
				</div>
			</BodyWrapper>
		</div>
	)
}

export default SelectedBoxView
