import React from "react"
import { ButtonVariants } from "@budbee-monorepo/ui"
import { useDispatch } from "react-redux"
import { IOption } from "../../types/global"
import { IBox } from "../../types/lockers"
import { sortArrayByParameter } from "../../utils/arrayHelpers"
import { useGlobalModal } from "../../utils/hooks/useGlobalModal"
import { ResetErrorStateSuccessfullyToast } from "../../utils/toasts"
import {
	BodyWrapper,
	ButtonWrapper,
	StyledButton,
	StyledStatus,
	StyledCloseButton,
} from "./SelectedBoxView.styles"
import {
	useGetErrorCodesQuery,
	useUpdateLockerBoxStatusMutation,
} from "../../services/clearBoxError"
import { lockersApi } from "../../services/lockers"
import { useRoles } from "../../utils/hooks/useRoles"

type Props = {
	selectedBoxes: IBox[]
	clearSelectedBoxes: () => void
}
const MultipleSelectedBoxView = ({ selectedBoxes, clearSelectedBoxes }: Props) => {
	const { data: errorCodes } = useGetErrorCodesQuery()
	const { hide, showDropdownInput } = useGlobalModal()
	const [updateLockerBoxStatus, updateLockerBoxStatusResult] = useUpdateLockerBoxStatusMutation()
	const dispatch = useDispatch()
	const { isOperationsAdministrator } = useRoles()

	const boardIds = Object.keys(sortArrayByParameter(selectedBoxes, "boardId")).map(
		(boardId, index, array) => (
			<span>
				{boardId}
				{index === array.length - 1 ? " " : ", "}
			</span>
		)
	)
	const sortedByStatus = sortArrayByParameter(selectedBoxes, "status")
	const statuses = Object.keys(sortedByStatus).map((status) => (
		<StyledStatus status={status} type="">
			{status}
		</StyledStatus>
	))
	const handleUpdateStates = () => {
		let options: IOption<string | null>[] = []
		if (errorCodes) {
			options = errorCodes.map((code) => {
				return {
					value: code.errorCode,
					label: code.humanReadable,
				}
			})
			showDropdownInput({
				title: "Update states for these compartments?",
				text: "You are about to change the error code for multiple compartments, are you sure this is what you want to do?",
				options,
				selectedOption: {
					value: null,
					label: "No error",
				},
				label: "",
				placeholder: "",
				isValid: function (value: IOption<string | null>): boolean {
					return true
				},
				onSubmit: function (value: IOption<string | null>): void {
					ResetErrorStateSuccessfullyToast(null)

					Promise.all(
						selectedBoxes.map((box) =>
							updateLockerBoxStatus({
								boxIdentifier: box.id,
								errorCode: value.value,
							})
						)
					).then(() => {
						dispatch(
							lockersApi.util.invalidateTags([
								{
									type: "SpecificLocker",
								},
							])
						)
						hide()
					})
				},
				submitButtonText: "Change",
				submitButtonVariant: ButtonVariants.PRIMARY,
			})
		}
	}
	return (
		<div className="flex flex-col bg-bb-petrol-600 w-full rounded-t-xl rounded-b-lg absolute bottom-0 left-0">
			<StyledCloseButton onClick={clearSelectedBoxes} />
			<div className="flex flex-row w-16 h-1 mt-2 rounded-full bg-white opacity-75 justify-self-center self-center" />
			<BodyWrapper>
				<ButtonWrapper>
					<StyledButton
						onClick={handleUpdateStates}
						disabled={"PRESENT" in sortedByStatus || !isOperationsAdministrator}
					>
						Update States
					</StyledButton>
				</ButtonWrapper>
				<div className="text-gray-200 flex flex-col mb-2 divide-y">
					<div className="flex flex-row justify-between py-2">
						<div>Statuses</div>
						<div className="flex flex-row gap-1">{statuses}</div>
					</div>
					<div className="flex flex-row justify-between py-2">
						<div>Boards</div>
						<div>{boardIds}</div>
					</div>
				</div>
			</BodyWrapper>
		</div>
	)
}

export default MultipleSelectedBoxView
