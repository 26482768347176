import { IoMdClose, IoMdWifi, IoMdHelpCircle } from "react-icons/io"
import { MdSearch } from "react-icons/md"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledRoot = styled.div`
	${tw`relative z-30 flex flex-col min-w-[320px] w-[320px] 2xl:w-[380px] bg-bb-petrol-800 justify-self-end rounded-lg`};
`

export const StyledHeader = styled.div`
	${tw`flex flex-col p-4 relative`}
`

export const StyledHeaderIdRow = styled.div`
	${tw`flex flex-row text-white h-12 items-center`}
`

export const StyledIdContainer = styled.div`
	${tw`border-r-2 ml-2 pr-1 border-gray-500 text-base font-medium`}
`

export const StyledNameContainer = styled.div`
	${tw`flex w-full pl-1 text-gray-200 text-xs font-medium`}
`

export const StyledCloseButton = styled(IoMdClose)`
	${tw`text-3xl absolute top-2 right-2 cursor-pointer`}
`

export const StyledHeaderSearchRow = styled.div`
	${tw`flex flex-row items-center justify-between mt-4`}
`

export const StyledSearchContainer = styled.div`
	${tw`relative flex flex-col mr-4 flex-grow`}
`

export const StyledSearchInput = styled.input`
	${tw`flex w-full text-white bg-bb-petrol-600 rounded-lg pl-6 pr-2 py-2 outline-none ring-inset ring-white focus:ring-2`}
`

export const StyledMdSearch = styled(MdSearch)`
	${tw`text-bb-white text-lg absolute top-3 left-1`};
`

export const StyledEnabledStatus = styled.div(({ enabled = false }: { enabled?: boolean }) => {
	return [
		tw`w-1.5 h-full flex-shrink-0 rounded-md`,
		enabled ? tw`bg-bb-green-500` : tw`bg-bb-red-500`,
	]
})

export const StyledOnlineStatus = styled(IoMdWifi)<{
	loading?: boolean
	online?: boolean
}>`
	${tw`text-2xl ml-1`}
	${({ loading, online }) => {
		if (loading) {
			return tw`text-blue-200`
		}
		if (online) {
			return tw`text-bb-green-500`
		}
		return tw`text-bb-red-500`
	}}
`
export const StyledUnknowOnlineStatus = styled(IoMdHelpCircle)`
	${tw`text-2xl ml-1 text-yellow-100`}
`
export const StyledPopup = styled.span<{
	text: string
}>`
	${({ text }) => {
		return `
		&:hover {
			&:after {
				content: "${text}";
				position: absolute;
				color: white;
				font-size: 0.75rem;
				background: black;
				padding 0.75rem;
				z-index: 10;
				border-radius: 0.5rem;
			}
		}`
	}}
`

export const StyledButton = styled.button`
	${tw`h-10 px-4 py-2 bg-white text-black text-sm rounded-lg font-medium`}
	min-width: fit-content;
`
