import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactMapGL, {
	Layer,
	MapLayerMouseEvent,
	MapLayerTouchEvent,
	MapRef,
	Marker,
	PopupProps,
	Source,
	ViewStateChangeEvent,
} from "react-map-gl"
import { FeatureCollection, Point } from "geojson"
import { GeocodeFeature, GeocodeResponse } from "@budbee/js/dist/mapbox-api"

import { useSearchParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { clusterPinLayer, pinLayer, TOpenFeature } from "../../components/Layers"
import { useGetLockersFromOpenQuery } from "../../services/openedApi"
import { useMapboxApi } from "../../utils/hooks/useMapboxApi"
import {
	StyledInput,
	StyledResultItem,
	StyledResultItemText,
	StyledResultList,
	StyledRightArrowIcon,
	StyledSearchContainer,
	StyledSearchIcon,
	StyledSearchResultSubTitle,
	StyledSearchResultTitle,
	StyledPopup,
	StyledInnerSearchContainer,
	StyledCloseIcon,
} from "./OpenMap.styles"
import { IOpeningHours } from "../../types/lockers"

const defaultCoordinates = {
	latitude: 52.336057,
	longitude: 4.02866,
}
interface IHoverInfo {
	longitude: number
	latitude: number
	name: string
	address: string
	openHours: string[]
}
const OpenMap = () => {
	const mapRef = useRef<MapRef>(null)
	const [searchTerm, setSearchTerm] = useState<string>("")
	const [lockersGeoJson, setLockersGeoJson] = useState<FeatureCollection | string>("")
	const [result, setResult] = useState<GeocodeFeature[]>([])
	const [showList, setShowList] = useState<boolean>(false)
	const [hoverInfo, setHoverInfo] = useState<IHoverInfo | null>(null)

	const [coordinates, setCoordinates] = useState<{
		latitude: number | null
		longitude: number | null
	}>({
		latitude: null,
		longitude: null,
	})

	const [country, setCountry] = useState<string>("SE")
	const [searchParams, setSearchParams] = useSearchParams()
	const { data: lockersWithinQueryResult } = useGetLockersFromOpenQuery(
		coordinates.latitude && coordinates.longitude
			? {
					coordinates: {
						lat: coordinates.latitude,
						lng: coordinates.longitude,
					},
					country,
			  }
			: skipToken
	)
	const [shouldFlyTo, setShouldFlyTo] = useState<boolean>(false)
	const mapboxApi = useMapboxApi(window.config.REACT_APP_MAPBOX_ACCESS_TOKEN || "")
	const mapboxSearch = useCallback(
		async (query: string) => {
			const { features: swedishMatches } = await mapboxApi.geocode(query, "SE", "", 5)
			const { features: danishMatches } = await mapboxApi.geocode(query, "DK", "", 5)
			const { features: neatherlandsMatches } = await mapboxApi.geocode(query, "NL", "", 5)
			const { features: finishMatches } = await mapboxApi.geocode(query, "FI", "", 5)
			const { features: belgiumMatches } = await mapboxApi.geocode(query, "BE", "", 5)
			const { features: germanyMatches } = await mapboxApi.geocode(query, "DE", "", 5)
			const matches = swedishMatches
				.concat(danishMatches)
				.concat(neatherlandsMatches)
				.concat(finishMatches)
				.concat(belgiumMatches)
				.concat(germanyMatches)
			const sortedMatches = matches.sort((a, b) => b.relevance - a.relevance)
			setResult(sortedMatches.slice(0, 5))
		},
		[mapboxApi]
	)
	const mapboxSearchWithCountryCode = useCallback(
		async (query: string, countryCode: string) => {
			const { features } = await mapboxApi.geocode(query, countryCode, "", 5)
			return features
		},
		[mapboxApi]
	)
	const getCountryFromCoordinates = useCallback(
		async (latitude: number, longitude: number) => {
			const { features } = await mapboxApi.geocode(`${longitude},${latitude}`, "", "", 1)
			const feature = features[0] as any
			return feature.context
				.find((context: any) => context.id.includes("country"))
				.short_code.toUpperCase()
		},
		[mapboxApi]
	)

	useEffect(() => {
		const countryCode = searchParams.get("country_code")
		const latitudeFromParam = searchParams.get("latitude")
		const longitudeFromParam = searchParams.get("longitude")
		if (Number(latitudeFromParam) && Number(longitudeFromParam) && countryCode) {
			setCoordinates({
				latitude: Number(latitudeFromParam),
				longitude: Number(longitudeFromParam),
			})
			setCountry(countryCode)
			setTimeout(() => {
				mapRef.current?.flyTo({
					center: {
						lat: Number(latitudeFromParam),
						lng: Number(longitudeFromParam),
					},
					zoom: 14,
					duration: 2500,
				})
			}, 1000)
			return
		}
		const postalCode = searchParams.get("postal_code")
		const address = searchParams.get("address")
		const city = searchParams.get("city")
		const initSearch = postalCode || address || city
		if (initSearch && countryCode) {
			mapboxSearchWithCountryCode(initSearch, countryCode).then((res) => {
				setCoordinates({
					latitude: res[0].center[1],
					longitude: res[0].center[0],
				})
				setCountry(countryCode)
				setTimeout(() => {
					mapRef.current?.flyTo({
						center: {
							lat: res[0].center[1],
							lng: res[0].center[0],
						},
						zoom: 14,
						duration: 2500,
					})
				}, 1000)
			})
		}
	}, [searchParams, setCoordinates, setCountry, mapboxSearchWithCountryCode])
	useEffect(() => {
		if (searchTerm.length > 2) {
			mapboxSearch(searchTerm)
			setShowList(true)
		} else {
			setShowList(false)
		}
	}, [searchTerm, mapboxSearch, setShowList])

	const getOpeningHourText = (openingHours: IOpeningHours) => {
		const days = openingHours.periods.map((period) => {
			const day = period.open.day.slice(0, 3).toLowerCase()
			const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1)
			const open = period.open && period.open.time ? period.open.time.slice(0, 5) : null
			const close = period.close && period.close.time ? period.close.time.slice(0, 5) : null
			if (open && close) {
				return `${capitalizedDay}: ${open} - ${close}`
			}
			return `${capitalizedDay}: closed`
		})
		return days
	}

	useEffect(() => {
		const geoJson: FeatureCollection = {
			type: "FeatureCollection",
			features: [],
		}

		if (!lockersWithinQueryResult) {
			return
		}
		const { lockers } = lockersWithinQueryResult
		if (!lockers) return

		for (let i = 0; i < lockers.length; i += 1) {
			const locker = lockers[i]

			if (locker.enabled) {
				const openHours = getOpeningHourText(locker.openingHours)
				const feature: TOpenFeature = {
					type: "Feature",
					properties: {
						name: locker.name,
						lat: locker.address.coordinate.latitude,
						lng: locker.address.coordinate.longitude,
						address: `${locker.address.street}, ${locker.address.postalCode}, ${locker.address.city}`,
						openHours,
					},
					geometry: {
						type: "Point",
						coordinates: [
							locker.address.coordinate.longitude,
							locker.address.coordinate.latitude,
						],
					},
				}

				geoJson.features.push(feature)
			}
		}
		setLockersGeoJson(geoJson)
	}, [lockersWithinQueryResult])

	useEffect(() => {
		if (shouldFlyTo) {
			mapRef.current?.flyTo({
				center: {
					lat: coordinates.latitude ? coordinates.latitude : defaultCoordinates.latitude,
					lng: coordinates.longitude
						? coordinates.longitude
						: defaultCoordinates.longitude,
				},
				zoom: 14,
				duration: 2500,
			})
			setShouldFlyTo(false)
		}
	}, [coordinates, shouldFlyTo])
	const handleOnMouseEnter = useCallback((event: MapLayerMouseEvent) => {
		const locker = event.features && event.features[0]

		if (locker && locker.properties && locker.layer.id === pinLayer.id) {
			return setHoverInfo({
				longitude: locker.properties.lng,
				latitude: locker.properties.lat,
				name: locker.properties.name,
				address: locker.properties.address,
				openHours: locker.properties.openHours,
			})
		}
	}, [])
	const handleOnMouseLeave = useCallback(() => {
		return setHoverInfo(null)
	}, [])

	const handleTouchStart = useCallback((event: MapLayerTouchEvent) => {
		const locker = event.features && event.features[0]
		if (locker && locker.properties && locker.layer.id === pinLayer.id) {
			return setHoverInfo({
				longitude: locker.properties.lng,
				latitude: locker.properties.lat,
				name: locker.properties.name,
				address: locker.properties.address,
				openHours: locker.properties.openHours,
			})
		}
		return setHoverInfo(null)
	}, [])

	const selectedHoverLocker = () => {
		const { name, address, openHours } = hoverInfo || {}
		if (!(hoverInfo && name)) {
			return null
		}
		let openinghours = []
		if (typeof openHours === "string") {
			openinghours = JSON.parse(openHours)
		}
		return (
			<div>
				<p>
					<b>{name}</b>
				</p>
				<p>{address}</p>
				{openinghours.map((hours: string) => (
					<p>{hours}</p>
				))}
			</div>
		)
	}

	const handleClickOnAddress = useCallback(
		(address: any) => {
			const [longitude, latitude] = address.center
			setShouldFlyTo(true)
			setCoordinates({ latitude, longitude })
			setCountry(
				address.context
					.find((context: any) => context.id.includes("country"))
					.short_code.toUpperCase()
			)
			setResult([])
		},
		[setCoordinates, setCountry, setResult]
	)
	const handleMapChange = (e: ViewStateChangeEvent) => {
		if (e.viewState.zoom > 10) {
			getCountryFromCoordinates(e.viewState.latitude, e.viewState.longitude).then((resp) => {
				setCoordinates({
					latitude: e.viewState.latitude,
					longitude: e.viewState.longitude,
				})
				setCountry(resp)
			})
		}
	}
	return (
		<>
			<ReactMapGL
				reuseMaps
				initialViewState={{
					latitude: coordinates.latitude
						? coordinates.latitude
						: defaultCoordinates.latitude,
					longitude: coordinates.longitude
						? coordinates.longitude
						: defaultCoordinates.longitude,
					zoom: 4,
					bearing: 0,
					pitch: 0,
				}}
				style={{
					position: "absolute",
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
				}}
				mapboxAccessToken={window.config.REACT_APP_MAPBOX_ACCESS_TOKEN || ""}
				mapStyle="mapbox://styles/budbee/cl7q98hzb002m15obbxs3gbgl"
				onMoveEnd={handleMapChange}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
				onTouchEnd={handleTouchStart}
				onClick={(e) => handleTouchStart(e as any)}
				minZoom={4}
				interactiveLayerIds={[clusterPinLayer.id, pinLayer.id]}
				ref={mapRef}
			>
				<Source
					id="lockers"
					type="geojson"
					data={lockersGeoJson}
					cluster
					clusterMaxZoom={12}
					clusterRadius={25}
				>
					<Layer {...clusterPinLayer} />
					<Layer {...pinLayer} />
					{hoverInfo !== null ? (
						<StyledPopup
							longitude={hoverInfo.longitude}
							latitude={hoverInfo.latitude}
							offset={[0, -20]}
							closeButton={false}
							style={{ maxWidth: "500px" }}
							closeOnClick={false}
						>
							{selectedHoverLocker()}
						</StyledPopup>
					) : null}
				</Source>
			</ReactMapGL>
			<StyledSearchContainer>
				<StyledInnerSearchContainer>
					<StyledInput
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						placeholder="Search"
					/>
					{searchTerm.length > 0 ? (
						<StyledCloseIcon onClick={() => setSearchTerm("")} />
					) : (
						<StyledSearchIcon />
					)}
				</StyledInnerSearchContainer>

				{result && showList ? (
					<StyledResultList>
						{result.map((res) => {
							const { id, text, place_name: placeName } = res
							return (
								<StyledResultItem onClick={() => handleClickOnAddress(res)}>
									<StyledResultItemText>
										<StyledSearchResultTitle>{text}</StyledSearchResultTitle>
										<StyledSearchResultSubTitle>
											{placeName}
										</StyledSearchResultSubTitle>
									</StyledResultItemText>
									<StyledRightArrowIcon />
								</StyledResultItem>
							)
						})}
					</StyledResultList>
				) : null}
			</StyledSearchContainer>
		</>
	)
}

export default OpenMap
