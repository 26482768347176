import React, { ReactElement } from "react"
import { useSelector } from "react-redux"
import demandMax from "../../assets/demandMax.png"
import demandMed from "../../assets/demandMed.png"
import demandMin from "../../assets/demandMin.png"
import { IGlobalState } from "../../types/global"
import { ILocker, LockerDemand } from "../../types/lockers"
import {
	StyledArrow,
	StyledArrowWrapper,
	StyledDemandIndicator,
	StyledEnabledStatus,
	StyledLabel,
	StyledLockerName,
	StyledSidebarLocation,
} from "./SidebarLocation.styles"

type SidebarLocationProps = {
	locker?: ILocker
	handleSelect: (locker: ILocker) => void
}

const defaultProps = {
	locker: null,
}

const SidebarLocation = ({ locker, handleSelect }: SidebarLocationProps): ReactElement | null => {
	const selectedLocker = useSelector((state: IGlobalState) => state.lockers.selectedLocker)

	if (!locker) {
		return null
	}

	let demandIcon

	switch (locker.demand) {
		case LockerDemand.LOW_BOUND:
			demandIcon = demandMin
			break
		case LockerDemand.HIGH_DEMAND:
			demandIcon = demandMed
			break

		case LockerDemand.MAXIMUM_BOUND:
			demandIcon = demandMax
			break
		default:
			demandIcon = demandMin
	}

	return (
		<StyledSidebarLocation
			isSelected={locker.identifier === selectedLocker}
			hasSelected={!!selectedLocker}
			onClick={() => handleSelect(locker)}
		>
			<StyledEnabledStatus enabled={locker.enabled} />
			<StyledDemandIndicator src={demandIcon} />
			<StyledLabel>{locker.identifier}</StyledLabel>
			<StyledLockerName>{locker.name}</StyledLockerName>
			<StyledArrowWrapper>
				<StyledArrow />
			</StyledArrowWrapper>
		</StyledSidebarLocation>
	)
}

SidebarLocation.defaultProps = defaultProps

export default SidebarLocation
