import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"


const baseQuery = fetchBaseQuery({
	baseUrl: ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

interface IUpdateLockerBoxStatusErrors{
    boxIdentifier: number
	errorCode: string | null
}
interface IClearAllLockerBoxErrors{
    lockerIdentifier: string
}
interface IErrorCode{
	errorCode : string | null
	humanReadable: string
}
// Define a service using a base URL and expected endpoints
export const clearBoxErrorApi = createApi({
	reducerPath: "clearBoxError",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		updateLockerBoxStatus: builder.mutation<void, IUpdateLockerBoxStatusErrors>({
			query:({ boxIdentifier, errorCode }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/locker-box-status/${boxIdentifier}`,
				method: 'PUT',
				body: {
					errorCode
				},
			}),
		}),
		clearAllBoxError: builder.mutation<void, IClearAllLockerBoxErrors>({
			query:({ lockerIdentifier }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/clear-locker-box-errors/${lockerIdentifier}`,
				method: 'PUT',
				body: {},
			}),
		}),
		getErrorCodes: builder.query<IErrorCode[], void>({
			query: () => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/box-error-codes`;
			}
		}),
	}),
})

export const { useUpdateLockerBoxStatusMutation, useClearAllBoxErrorMutation, useGetErrorCodesQuery } = clearBoxErrorApi
