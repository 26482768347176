import React from "react"
import {
	IGlobalModalConfirmation,
	IGlobalModalDropdownInput,
	IGlobalModalNumberInput,
	IGlobalModalStringInput,
} from "../../types/global"
import { useGlobalModal } from "../../utils/hooks/useGlobalModal"
import GlobalModalConfirmation from "./GlobalModalConfirmation"
import GlobalModalDropdownInput from "./GlobalModalDropdownInput"
import GlobalModalNumberInput from "./GlobalModalNumberInput"
import GlobalModalStringInput from "./GlobalModalStringInput"

const GlobalModalWrapper = () => {
	const { state, hide } = useGlobalModal()
	const { type, show, title, text } = state
	if (show && title && text) {
		if (type === "CONFIRMATION") {
			const { actions } = state as IGlobalModalConfirmation
			return (
				<GlobalModalConfirmation
					title={title}
					text={text}
					onCancel={hide}
					actions={actions}
				/>
			)
		}
		if (type === "NUMBER_INPUT") {
			const { placeholder, label, isValid, onSubmit, submitButtonText, submitButtonVariant } =
				state as IGlobalModalNumberInput
			return (
				<GlobalModalNumberInput
					title={title}
					text={text}
					onCancel={hide}
					placeholder={placeholder}
					label={label}
					isValid={isValid}
					onSubmit={onSubmit}
					submitButtonText={submitButtonText}
					submitButtonVariant={submitButtonVariant}
				/>
			)
		}
		if (type === "STRING_INPUT") {
			const { placeholder, label, isValid, onSubmit, submitButtonText, submitButtonVariant } =
				state as IGlobalModalStringInput
			return (
				<GlobalModalStringInput
					title={title}
					text={text}
					onCancel={hide}
					placeholder={placeholder}
					label={label}
					isValid={isValid}
					onSubmit={onSubmit}
					submitButtonText={submitButtonText}
					submitButtonVariant={submitButtonVariant}
				/>
			)
		}
		if (type === "DROPDOWN_INPUT") {
			const {
				placeholder,
				label,
				options,
				selectedOption,
				isValid,
				onSubmit,
				submitButtonText,
				submitButtonVariant,
			} = state as IGlobalModalDropdownInput
			return (
				<GlobalModalDropdownInput
					title={title}
					text={text}
					onCancel={hide}
					placeholder={placeholder}
					label={label}
					isValid={isValid}
					options={options}
					selectedOption={selectedOption}
					onSubmit={onSubmit}
					submitButtonText={submitButtonText}
					submitButtonVariant={submitButtonVariant}
				/>
			)
		}
	}

	return null
}

export default GlobalModalWrapper
