import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { List } from "postcss/lib/list"
import type { RootState } from "../store/configureStore"

const baseQuery = fetchBaseQuery({
	baseUrl: ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

interface IDeviceInfo {
	deviceId: string | null
	online: boolean | null
	version: string | null
}

interface IOpenLockerRequest {
	lockerId: string
	reason: string
	board: number
	slot: number
}

interface IOpenLockerResult {
	success: boolean
	message: string | null
	tries: number
}

export const deviceAccessApi = createApi({
	baseQuery: baseQuery,
	reducerPath: "deviceAccess",
	endpoints: (builder) => ({
		getDeviceInfo: builder.query<IDeviceInfo, string>({
			query: (lockerIdentifier) => {
				return `${window.config.REACT_APP_API_BASE_URL}device-access/v1/lockers/${lockerIdentifier}`
			},
		}),

		getAuthorities: builder.query<string[], void>({
			query: () => {
				return `${window.config.REACT_APP_API_BASE_URL}device-access/v1/security/authorities`
			},
		}),
		getMacAddress: builder.query<string, string>({
			query: (lockerIdentifier) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}device-access/v1/lockers/router-mac/${lockerIdentifier}`,
				responseHandler: (response) => response.text(),
			}),
		}),
		openLocker: builder.mutation<IOpenLockerResult, IOpenLockerRequest>({
			query: ({ lockerId, reason, board, slot }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}device-access/v1/action/${lockerId}/open`,
				method: "POST",
				body: {
					board,
					slot,
					reason,
				},
			}),
		}),
	}),
})

export const {
	useGetDeviceInfoQuery,
	useGetAuthoritiesQuery,
	useGetMacAddressQuery,
	useOpenLockerMutation,
} = deviceAccessApi
