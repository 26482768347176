import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ILockerTab, ILogsAndComments } from "../../types/logsAndComments"

const initialStateSelectedLockerLogsAndComments: ILogsAndComments = {
	tab: ILockerTab.LOGS,
	isOpen: false,
}
export const logsAndCommentsSlice = createSlice({
	name: "logsAndComments",
	initialState: initialStateSelectedLockerLogsAndComments,
	reducers: {
		set: (draftState, { payload }: PayloadAction<ILockerTab>) => ({
			...draftState,
			tab: payload,
		}),
		toggle: (draftState) => ({ ...draftState, isOpen: !draftState.isOpen }),
	},
})
export const { set: setLogsAndCommentsActionCreator, toggle: toggleLogsAndCommentsActionCreator } =
	logsAndCommentsSlice.actions
