import { AiOutlineLoading3Quarters } from "react-icons/ai"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledPageContainer = styled.div`
	${tw`bg-no-repeat bg-cover bg-center relative`}
`
export const StyledBackground = styled.div``
export const StyledContentContainer = styled.div`
	${tw`min-h-screen flex mx-0 justify-center`}
`
export const StyledCardWrapper = styled.div`
	${tw`flex justify-center self-center z-10`}
`
export const StyledCard = styled.div`
	${tw`flex flex-col p-8 bg-white mx-auto rounded-2xl w-72 shadow-2xl space-y-4`}
`
export const StyledLogoWrapper = styled.div`
	${tw`flex flex-col self-start text-bb-petrol-500`}
`
export const BudbeeLogo = styled.img`
	${tw`w-48 mb-2`}
`
export const StyledBumbleBox = styled.h1`
	${tw`font-bold text-3xl`}
`

export const StyledContent = styled.div`
	${tw``}
`
export const StyledInfo = styled.p`
	${tw`text-center text-gray-400 text-sm mb-2`}
`

export const StyledButton = styled.button`
	${tw`border w-full bg-bb-green-500 rounded-full text-base text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-bb-green-300 hover:text-gray-500 mr-6`}
`

export const StyledReTriggerButton = styled.button`
	${tw`border w-full border-gray-400 rounded-full text-sm text-gray-400 px-4 py-3 transition duration-300 ease-in-out hover:bg-bb-gray-200 hover:text-gray-500 mr-6`}
`

export const StyledLoadingWrapper = styled.button`
	${tw`flex flex-col items-center text-gray-400 space-y-2`}
`

export const StyledLoadingIcon = styled(AiOutlineLoading3Quarters)`
	${tw`text-6xl animate-spin`}
`
