import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"


const baseQuery = fetchBaseQuery({
	baseUrl: ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

interface IExtendExpireDate{
    packageId: string,
    days: number
}
interface IExtendExpireDateLocker{
	identifier: string,
	days: number
}
// Define a service using a base URL and expected endpoints
export const extendTimeApi = createApi({
	reducerPath: "extendTimeApi",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		extendExpireDate: builder.mutation<void, IExtendExpireDate>({
			query:({ packageId,days }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/lockers/extend-expire-date/${packageId}/${days}`,
				method: 'POST',
				body: {},
			}),
		}),
		extendExpireDateForAllParcels: builder.mutation<void, IExtendExpireDateLocker>({
			query:({ identifier, days }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/extend-expire-date/${identifier}/${days}`,
				method: 'PUT',
				body: {}
			})
		})
	}),
})

export const { useExtendExpireDateMutation, useExtendExpireDateForAllParcelsMutation } = extendTimeApi
