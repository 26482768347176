import { animated } from "react-spring"
import styled from "styled-components"
import tw from "twin.macro"
import { IoIosAddCircle, IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io"
import { BoxSize, BoxSizeFriendly, BoxSizeV2 } from "../../types/global"

export const StyledContainer = styled.div`
	${tw`flex z-20 top-0 right-0 min-w-[calc(100vw - 360px)] 2xl:min-w-[calc(100vw - 440px)] absolute h-screen overflow-hidden justify-end`};
`
export const StyledBackdrop = styled.div`
	${tw`opacity-50 bg-black h-full top-0 w-[calc(100vw - 360px)] 2xl:w-[calc(100vw - 440px)] absolute cursor-pointer`};
`

export const StyledContent = styled(animated.div)`
	${tw`flex flex-row max-h-[90%] h-[740px] lg:h-[80%] justify-end mt-[20px] mr-[20px] max-w-[calc(100vw - 395px)] 2xl:max-w-[calc(100vw - 480px)]`};
`

export const StyledLockerContainer = styled.div`
	${tw`flex flex-col z-30 h-full bg-bb-petrol-800 mr-8 rounded-lg py-4 border-[1rem] border-bb-petrol-800 overflow-x-scroll`};
`

export const StyledLocker = styled.div`
	${tw`flex justify-self-stretch h-full `};
`
export const StyledStatusRow = styled.div`
	${tw`flex flex-row text-white p-4 pb-0 justify-center sticky left-0 right-0`};
`

export const StyledStatusContainer = styled.div`
	${tw`flex flex-row text-white items-center mx-2`};
`

export const handleStatusColor = (status: string) => {
	switch (status) {
		case "Available":
			return tw`bg-bb-white`
		case "Delivery":
			return tw`bg-bb-green-500`
		case "Reserved":
			return tw`bg-bb-gray-400`
		case "Return":
			return tw`bg-bb-warn`
		case "Error":
			return tw`bg-bb-red-500`
		default:
			return tw`bg-white`
	}
}

export const StyledStatusIndicator = styled.div<{ status: string }>`
	${tw`flex rounded-full w-3 h-3`};
	${(props) => {
		return handleStatusColor(props.status)
	}}
`
export const StyledStatusText = styled.div`
	${tw`flex ml-1 font-medium text-xs`};
`

export const StyledLocationPartnerContainer = styled.div`
	${tw`z-30 flex min-w-[320px] w-[320px] 2xl:w-[380px] bg-bb-petrol-800 justify-self-end rounded-lg`};
`

export const StyledRow = styled.div`
	${tw`flex flex-row`};
`
export const StyledRowGrow = styled.div`
	${tw`flex flex-row flex-grow`};
`
export const StyledCol = styled.div`
	${tw`flex flex-col`};
`

/*
WIDE_XL = "BOX_450_450_600", 10
WIDE_L = "BOX_450_360_600", 8 
WIDE_M = "BOX_450_265_600", 6
WIDE_S = "BOX_450_170_600", 4
WIDE_XS = "BOX_450_122_600", 3
REGULAR_L = "BOX_265_265_600", 6
REGULAR_M = "BOX_265_170_600", 4
REGULAR_S = "BOX_265_122_600", 3
REGULAR_XS = "BOX_265_75_600", 2
NARROW_L = "BOX_200_170_600", 4
NARROW_M = "BOX_200_122_600", 3
NARROW_S = "BOX_200_75_600", 2

75
*/

export const handleBoxSize = (size: string) => {
	switch (size) {
		case BoxSizeFriendly.XL:
		case BoxSize.XL:
			return tw`min-w-[120px] flex-grow-[5]`
		case BoxSizeFriendly.L:
		case BoxSize.L:
			return tw`min-w-[80px] flex-grow-[4]`
		case BoxSizeFriendly.M:
		case BoxSize.M:
			return tw`min-w-[80px] flex-grow-[3]`
		case BoxSizeFriendly.S:
		case BoxSize.S:
			return tw`min-w-[80px] flex-grow-[2]`

		case BoxSizeV2.WIDE_XL:
			return tw`min-w-[135px] flex-grow-[10]`
		case BoxSizeV2.WIDE_L:
			return tw`min-w-[135px] flex-grow-[8]`
		case BoxSizeV2.WIDE_M:
			return tw`min-w-[135px] flex-grow-[6]`
		case BoxSizeV2.WIDE_S:
			return tw`min-w-[135px] flex-grow-[4]`
		case BoxSizeV2.WIDE_XS:
			return tw`min-w-[135px] flex-grow-[3]`
		case BoxSizeV2.REGULAR_L:
			return tw`min-w-[100px] flex-grow-[6]`
		case BoxSizeV2.REGULAR_M:
			return tw`min-w-[100px] flex-grow-[4]`
		case BoxSizeV2.REGULAR_S:
			return tw`min-w-[100px] flex-grow-[3]`
		case BoxSizeV2.REGULAR_XS:
			return tw`min-w-[100px] flex-grow-[2]`
		case BoxSizeV2.NARROW_L:
			return tw`min-w-[80px] flex-grow-[4]`
		case BoxSizeV2.NARROW_M:
			return tw`min-w-[80px] flex-grow-[3]`
		case BoxSizeV2.NARROW_S:
			return tw`min-w-[80px] flex-grow-[2]`
		default:
			return tw`min-w-[80px] flex-grow-[1]`
	}
}
export const handleBoxSizeSmall = (size: string) => {
	switch (size) {
		case BoxSizeFriendly.XL:
		case BoxSize.XL:
			return tw`min-w-[60px] flex-grow-[5]`
		case BoxSizeFriendly.L:
		case BoxSize.L:
			return tw`min-w-[40px] flex-grow-[4]`
		case BoxSizeFriendly.M:
		case BoxSize.M:
			return tw`min-w-[40px] flex-grow-[3]`
		case BoxSizeFriendly.S:
		case BoxSize.S:
			return tw`min-w-[40px] flex-grow-[2]`
		case BoxSizeV2.WIDE_XL:
			return tw`min-w-[67px] flex-grow-[10]`
		case BoxSizeV2.WIDE_L:
			return tw`min-w-[67px] flex-grow-[8]`
		case BoxSizeV2.WIDE_M:
			return tw`min-w-[67px] flex-grow-[6]`
		case BoxSizeV2.WIDE_S:
			return tw`min-w-[67px] flex-grow-[4]`
		case BoxSizeV2.WIDE_XS:
			return tw`min-w-[67px] flex-grow-[3]`
		case BoxSizeV2.REGULAR_L:
			return tw`min-w-[50px] flex-grow-[6]`
		case BoxSizeV2.REGULAR_M:
			return tw`min-w-[50px] flex-grow-[4]`
		case BoxSizeV2.REGULAR_S:
			return tw`min-w-[50px] flex-grow-[3]`
		case BoxSizeV2.REGULAR_XS:
			return tw`min-w-[50px] flex-grow-[2]`
		case BoxSizeV2.NARROW_L:
			return tw`min-w-[40px] flex-grow-[4]`
		case BoxSizeV2.NARROW_M:
			return tw`min-w-[40px] flex-grow-[3]`
		case BoxSizeV2.NARROW_S:
			return tw`min-w-[40px] flex-grow-[2]`
		default:
			return tw`min-w-[40px] flex-grow-[1]`
	}
}

export const SizeLabel = styled.div<{ size: string }>`
	${tw`flex relative top-1 left-1 items-center justify-center text-xs text-white
		 rounded-full w-[18px] h-[18px] 2xl:w-[24px] 2xl:h-[24px] bg-bb-petrol-600`};
`

export const StyledEditLockerContainer = styled.div`
	${tw`flex flex-col z-30 h-full bg-bb-petrol-600 mr-8 rounded-lg p-0 min-w-[32rem]`};
`
export const StyledEditModuleHeader = styled.h2`
	${tw`text-white bg-bb-petrol-800 rounded-t-lg p-4`}
`
export const BoardsWrapper = styled.div`
	${tw`h-full w-full overflow-x-scroll`}
`
export const BoardsStyle = styled.div`
	${tw`flex gap-1 w-min min-w-full items-center justify-around h-full p-12 `}
`
export const BoardStyle = styled.div<{ colorId: number }>`
	${tw`flex flex-col text-white bg-bb-petrol-500 border-t-8 rounded-lg flex-grow items-center justify-between min-w-[8rem] min-h-[10rem] h-full`}
	${(props) => {
		switch (props.colorId % 5) {
			case 0:
				return tw`border-bb-cream-500`
			case 1:
				return tw`border-bb-green-500`
			case 2:
				return tw`border-bb-cream-700`
			case 3:
				return tw`border-bb-green-300`
			case 4:
				return tw`border-bb-red-500`
			case 5:
				return tw`border-bb-red-700`
			default:
				return tw`border-bb-red-100`
		}
	}}
`
export const BoardHeader = styled.span`
	${tw`text-white text-center pt-4`}
`
export const StyledAddButton = styled(IoIosAddCircle)`
	${tw`text-2xl text-white cursor-pointer min-w-[1rem]`}
`
export const StyledMoveLeftButton = styled(IoIosArrowDropleftCircle)<{ disabled: boolean }>`
	${tw`text-2xl text-white cursor-pointer`}
	${(props) => {
		if (props.disabled) {
			return tw`text-bb-gray-600 cursor-not-allowed`
		}
	}}
`
export const StyledMoveRightButton = styled(IoIosArrowDroprightCircle)<{ disabled: boolean }>`
	${tw`text-2xl text-white cursor-pointer`}
	${(props) => {
		if (props.disabled) {
			return tw`text-bb-gray-600 cursor-not-allowed`
		}
	}}
`
export const MoveButtons = styled.div`
	${tw`flex gap-1 items-stretch justify-around px-4 pt-4`}
`
export const RemoveButton = styled.button`
	${tw`bg-bb-red-600 text-white w-full rounded-b-lg mt-4 py-1`}
`
export const BoardButtons = styled.div`
	${tw`flex flex-col w-full`}
`
export const StyledEditVersion = styled.div`
	${tw`p-4`}
`
