import React, { ReactNode } from "react"
import { Footer } from "./GlobalModal.styles"

type Props = {
	children?: ReactNode
}

const GlobalModalFooter = ({ children }: Props) => {
	return <Footer>{children}</Footer>
}

GlobalModalFooter.defaultProps = {
	children: null,
}

export default GlobalModalFooter
