import React, { ReactElement } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import StatsLoader from "./StatsLoader"

const StyledStatsWrapper = styled.div`
	${tw`flex flex-col mr-4 p-2 rounded-lg w-36 h-32 bg-white justify-between shadow-lg`}
`

const StyledStatsTitle = styled.div`
	${tw`flex flex-row text-gray-400 text-sm font-semibold overflow-ellipsis`}
`

const StyledStatsContent = styled.div`
	${tw`flex flex-row h-16 tracking-tighter font-bold text-bb-petrol-500 text-5xl overflow-ellipsis items-center`}
`

const StyledStatsFooter = styled.div`
	${tw`flex flex-row min-h-[20px] text-gray-400 font-medium text-sm overflow-ellipsis`}
`

type OverlayStatsProps = {
	title: string
	content: string | number
	footer?: string
	isLoading: boolean
	isFetching: boolean
}

const defaultProps = {
	footer: null,
}

const Stats = ({
	title,
	content,
	footer,
	isLoading,
	isFetching,
}: OverlayStatsProps): ReactElement => {
	return (
		<StyledStatsWrapper>
			<StyledStatsTitle>{title}</StyledStatsTitle>
			{isLoading || isFetching ? (
				<StatsLoader />
			) : (
				<StyledStatsContent>{content}</StyledStatsContent>
			)}
			<StyledStatsFooter>{footer || ""}</StyledStatsFooter>
		</StyledStatsWrapper>
	)
}

Stats.defaultProps = defaultProps

export default Stats
