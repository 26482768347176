import { Virtuoso } from "react-virtuoso"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledSidebarBodyOuter = styled.div`
	${tw`flex w-[360px] 2xl:w-[440px] flex-col z-10 fixed top-20  left-0 h-screen bg-bb-petrol-600 overflow-hidden`};
`
export const StyledSidebarBodyInner = styled.div`
	${tw`flex flex-col flex-grow text-black mb-20 ml-4 w-[328px] 2xl:w-[408px]`};
`

export const StyledVirtuoso = styled<any>(Virtuoso)`
	${tw`text-black mb-4 w-[349px] 2xl:w-[424px]`};

	&::-webkit-scrollbar {
		${tw`w-[16px]`};
	}

	&::-webkit-scrollbar-thumb {
		background-clip: content-box;
		border: 5px solid transparent;
		box-shadow: inset 0 0 0 10px #ffffffd9;
		min-height: 50px;
		${tw`rounded-full`};
	}

	&::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
`

export const StyledVirtuosoComponent = styled.div`
	${tw`flex justify-center p-4 text-white`}
`

export const StyledLoadingComponent = styled.div`
	${tw`flex justify-center pt-2 pb-4 text-white`}
`
