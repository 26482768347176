import styled from "styled-components"
import tw from "twin.macro"

export const Wrapper = styled.div`
	${tw`px-4 py-5 h-[calc(100% - 63px)] overflow-y-scroll`}
`
export const Log = styled.div`
	${tw`flex justify-between items-end border-b-2 border-bb-petrol-500 py-4 px-2`}
	&:first-child {
		${tw`border-t-2 pt-3`}
	}
`
export const IsCurrent = styled.span`
	${tw`text-bb-green-500 text-xs font-normal`}
`
export const LogStatusText = styled.h3`
	${tw`text-bb-white text-base font-semibold`}
`
export const LogTimestamp = styled.span`
	${tw`text-bb-white text-sm font-normal flex-shrink-0`}
`
export const NoLogsText = styled(LogStatusText)`
	${tw`text-center`}
`
