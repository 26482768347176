import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import tw from "twin.macro"
import { useGetLockersWithinQuery } from "../services/lockers"
import { selectLockerStats } from "../store/selectors"
import { ILockerStats } from "../store/selectors/selector"
import { IGlobalState } from "../types/global"
import Stats from "./Stats/Stats"
import StatsDemand from "./Stats/StatsDemand"

const StyledContainer = styled.div`
	${tw`absolute flex flex-row m-4 w-full h-20`}
`

const MapViewOverlay = (): ReactElement | null => {
	const lockersWithin = useSelector((state: IGlobalState) => state.map.lockersWithin)
	const {
		data: lockersWithinQueryResult,
		isLoading,
		isFetching,
	} = useGetLockersWithinQuery(lockersWithin ?? skipToken)
	const useSelectLockerStats = useSelector((state: IGlobalState) => {
		if (!lockersWithinQueryResult?.records) {
			return {} as ILockerStats
		}
		return selectLockerStats(state, lockersWithinQueryResult?.records)
	})

	if (!lockersWithinQueryResult) {
		return null
	}

	const {
		countLockersSelected,
		countTotalDisabledLockers,
		countDisabledLockersSelected,
		demand,
		countLockersAvailable,
	} = useSelectLockerStats

	return (
		<StyledContainer>
			<Stats
				isLoading={isLoading}
				isFetching={isFetching}
				title="Lockers selected"
				content={countLockersSelected}
			/>
			<Stats
				isLoading={isLoading}
				isFetching={isFetching}
				title="Disabled"
				content={countDisabledLockersSelected}
				footer={`Total: ${countTotalDisabledLockers}`}
			/>

			<StatsDemand
				isLoading={isLoading}
				isFetching={isFetching}
				high={demand?.MAXIMUM_BOUND}
				medium={demand?.HIGH_DEMAND}
				low={demand?.LOW_BOUND}
				none={demand?.none}
				available={countLockersAvailable}
			/>
		</StyledContainer>
	)
}

export default MapViewOverlay
