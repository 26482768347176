


import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { IGetLockers, ILocker, IOpeningHours } from "../types/lockers"


const baseQuery = fetchBaseQuery({
	baseUrl: "",
	prepareHeaders: (headers, { getState }) => {
		return headers
	},
})

interface ILockersWithinOpen{
    coordinates:{
        lat: number
        lng: number
    }
    country: string
}
interface ILockerSearchResponse{
    lockers: {
        address:{
            city: string
            coordinate: {
                latitude: number
                longitude: number
            }
            country: string
            postalCode: string
            street: string
        }
        available: boolean
        contactPerson: string
        contactPhoneNumber: string
        directions: string
        enabled: boolean
        id: string
        name: string
        openingHours: IOpeningHours
    }[]
}
// Define a service using a base URL and expected endpoints
export const openEndpointsApi = createApi({
	reducerPath: "openEndpointsApi",
	baseQuery: baseQuery,
    tagTypes: ["Open_Lockers"],
	endpoints: (builder) => ({
		getLockersFromOpen: builder.query<ILockerSearchResponse, ILockersWithinOpen>({
			query: ({ coordinates, country }) => {
                return `${window.config.REACT_APP_API_BASE_URL}lockers/search/${country}/${coordinates.lat}/${coordinates.lng}`
				//return `${window.config.REACT_APP_API_BASE_URL}bumblebox/lockers-within/3000/center/${coordinates.lng}/${coordinates.lat}`
			},
            providesTags: ["Open_Lockers"],
		})
	}),
})


export const { useGetLockersFromOpenQuery } = openEndpointsApi

