import React, { ReactElement } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	toggleIsExpandFiltersActionCreator,
	updateSelectFiltersActionCreator,
} from "../../../store/slices"
import { IGlobalState, ISelectOption, Toggle } from "../../../types/global"

import SearchFilter from "./SearchFilter"
import SearchInput from "./SearchInput"

interface SideBarSearchProps {
	selectTopResult: () => void
}

const SidebarSearch = ({ selectTopResult }: SideBarSearchProps): ReactElement => {
	const dispatch = useDispatch()

	const isExpandedFilters = useSelector(
		(state: IGlobalState) => state.sidebarFilters.isExpandedFilters
	)

	const handleToggleIsExpandFilters = () => {
		dispatch(toggleIsExpandFiltersActionCreator())
	}

	const handleSelectFilter = (selected: ISelectOption[], type: string) => {
		if (!selected) {
			return
		}

		dispatch(updateSelectFiltersActionCreator({ type, active: selected }))
	}

	return (
		<div>
			<SearchInput
				isExpandedFilters={isExpandedFilters}
				toggleIsExpandFilters={handleToggleIsExpandFilters}
				selectTopResult={selectTopResult}
			/>
			{isExpandedFilters && <SearchFilter handleSelectFilter={handleSelectFilter} />}
		</div>
	)
}

export default SidebarSearch
