import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { v1 as uuid } from "uuid"
import { useGetLockersWithinQuery, useSearchLockersQuery } from "../../services/lockers"
import { selectFilteredLockers } from "../../store/selectors"
import { selectLockerActionCreator, setFlyMapActionCreator } from "../../store/slices"
import { useAppSelector } from "../../store/utils"
import { IGlobalState } from "../../types/global"
import { ILocker } from "../../types/lockers"
import {
	StyledLoadingComponent,
	StyledSidebarBodyInner,
	StyledSidebarBodyOuter,
	StyledVirtuoso,
	StyledVirtuosoComponent,
} from "./LockersSidebar.styles"

import LockersSidebarHeader from "./LockersSidebarHeader"
import SidebarLocation from "./SidebarLocation"
import SidebarSearch from "./SidebarSearch/SidebarSearch"

const LockersSidebar = (): ReactElement => {
	const dispatch = useDispatch()
	const searchTerm = useAppSelector((state) => state.sidebarFilters.searchTerm)
	const {
		data: searchedLockers,
		isLoading: searchedIsLoading,
		isFetching: searchedIsFetching,
	} = useSearchLockersQuery(searchTerm ?? skipToken)

	const lockersWithin = useAppSelector((state) => state.map.lockersWithin)
	const { data: lockersWithinQueryResult } = useGetLockersWithinQuery(lockersWithin ?? skipToken)
	const useSelectFilteredLockers = useSelector((state: IGlobalState): ILocker[] => {
		if (!lockersWithinQueryResult?.records) {
			return [] as ILocker[]
		}
		return selectFilteredLockers(state, lockersWithinQueryResult?.records)
	})
	const [isEndReached, setIsEndReached] = useState(false)

	const handleSelect = (locker: ILocker) => {
		const url = new URL(window.location.toString())
		url.searchParams.set("locker", locker.identifier)
		window.history.pushState({}, "", url)
		dispatch(selectLockerActionCreator({ identifier: locker.identifier }))
		dispatch(setFlyMapActionCreator({ lat: locker.latitude, lng: locker.longitude }))
	}
	const selectTopResult = () => {
		if (searchedLockers && searchedLockers.records) {
			const locker: ILocker = searchedLockers.records[0]
			const url = new URL(window.location.toString())
			url.searchParams.set("locker", locker.identifier)
			window.history.pushState({}, "", url)
			dispatch(selectLockerActionCreator({ identifier: locker.identifier }))
			dispatch(setFlyMapActionCreator({ lat: locker.latitude, lng: locker.longitude }))
		}
	}

	const handleEndReached = () => {
		setIsEndReached(true)
	}

	const LoadingComponent = () => {
		if (!searchedIsLoading && !searchedIsFetching) {
			return null
		}

		return <StyledLoadingComponent>Loading...</StyledLoadingComponent>
	}

	const endComponent = () => {
		return isEndReached && <StyledVirtuosoComponent>All lockers loaded</StyledVirtuosoComponent>
	}
	return (
		<>
			<LockersSidebarHeader />
			<StyledSidebarBodyOuter>
				<StyledSidebarBodyInner>
					<SidebarSearch selectTopResult={selectTopResult} />
					<LoadingComponent />

					{searchTerm && searchedLockers && (
						<StyledVirtuoso
							data={searchedLockers.records}
							totalCount={20}
							endReached={handleEndReached}
							overscan={10}
							itemContent={(index: number, locker: ILocker) => {
								return (
									<SidebarLocation
										key={uuid()}
										locker={locker}
										handleSelect={handleSelect}
									/>
								)
							}}
							components={{
								Footer: () => endComponent(),
							}}
						/>
					)}

					{!searchTerm && useSelectFilteredLockers && (
						<StyledVirtuoso
							data={useSelectFilteredLockers}
							totalCount={20}
							endReached={handleEndReached}
							overscan={10}
							itemContent={(index: number, locker: ILocker) => {
								return (
									<SidebarLocation
										key={uuid()}
										locker={locker}
										handleSelect={handleSelect}
									/>
								)
							}}
							components={{
								Footer: () => endComponent(),
							}}
						/>
					)}
				</StyledSidebarBodyInner>
			</StyledSidebarBodyOuter>
		</>
	)
}

export default LockersSidebar
