import { Spinner } from "@budbee-monorepo/ui"
import React, { ReactNode } from "react"
import { useSelector } from "react-redux"
import { useGetLockerStatusLogsQuery } from "../../../services/lockerStatusLogs"
import { IGlobalState } from "../../../types/global"
import { timeAndDate } from "../../../utils/timeFormatter"
import {
	IsCurrent,
	Log,
	LogStatusText,
	LogTimestamp,
	NoLogsText,
	Wrapper,
} from "./LockerLogs.styles"

const LockerLogs = () => {
	let lockerLogs: ReactNode[] = []
	const selectedLockerIdentifer = useSelector(
		(state: IGlobalState) => state.lockers.selectedLocker
	)
	const { data: logs, isLoading } = useGetLockerStatusLogsQuery(selectedLockerIdentifer)

	if (isLoading) {
		return <Spinner />
	}
	if (logs) {
		lockerLogs = logs.map((log, idx) => {
			return (
				<Log>
					<div>
						{idx === 0 ? <IsCurrent>Current status</IsCurrent> : null}
						{log.enabled ? (
							<LogStatusText>Enabled</LogStatusText>
						) : (
							<LogStatusText>{log.reason}</LogStatusText>
						)}
					</div>
					<LogTimestamp>{timeAndDate(new Date(log.timestamp))}</LogTimestamp>
				</Log>
			)
		})
	}
	return (
		<Wrapper>
			{lockerLogs.length > 0 ? (
				lockerLogs
			) : (
				<NoLogsText>We have no history for this locker</NoLogsText>
			)}
		</Wrapper>
	)
}

export default LockerLogs
