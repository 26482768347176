import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"

const baseQuery = fetchBaseQuery({
	baseUrl: ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

interface IModuleTemplate{
    id: number,
    name: string,
    version: string,
    createdAt: number,
    updatedAt: number,
    boxes:{
        id: number,
        moduleId: number,
        size: string,
        width: number,
        height: number,
        depth: number,
        slotId: number
    }[]
}
interface IMoveModule{
    lockerIdentifier: string
    data:{
        direction: "LEFT" | "RIGHT",
        boardId: number
    }   
}
interface IAddModule{
    lockerIdentifier: string
    data:{
        moduleId: number,
        boardId: number
    }   
}
interface IDeleteModule{
    lockerIdentifier: string
    data:{
        boardId: number
    }  
}

export const lockerModulesApi = createApi({
	reducerPath: "lockerModules",
	baseQuery: baseQuery,
	tagTypes: [],
	endpoints: (builder) => ({
		getModuleTemplates: builder.query<IModuleTemplate[], void>({
            query: () => {
                return `${window.config.REACT_APP_API_BASE_URL}bumblebox/module-templates`
            },
        }),
        addModule: builder.mutation<void, IAddModule>({
            query:({ lockerIdentifier, data }) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/modules/${lockerIdentifier}`,
                method: 'POST',
                body: data,
            }),
        }),
        moveModule: builder.mutation<void, IMoveModule>({
            query:({ lockerIdentifier, data }) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/modules/move/${lockerIdentifier}`,
                method: 'PUT',
                body: data,
            }),
        }),
        deleteModule: builder.mutation<void, IDeleteModule>({
            query:({ lockerIdentifier, data }) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/modules/${lockerIdentifier}`,
                method: 'DELETE',
                body: data,
            }),
        }),
        unassignDeliveries: builder.mutation<void, string>({
            query:( lockerIdentifier ) => ({
                url: `${window.config.REACT_APP_API_BASE_URL}lockers/unassign-deliveries/${lockerIdentifier}`,
                method: 'PUT',
            }),
        }),
		
	}),
})

export const { useGetModuleTemplatesQuery, useAddModuleMutation, useMoveModuleMutation, useDeleteModuleMutation, useUnassignDeliveriesMutation } =
lockerModulesApi