import { useAuth0 } from "@auth0/auth0-react"
import { createBrowserHistory } from "history"
import React, { ReactElement, useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { MapPage } from "./router/pages"
import LoginPage from "./router/pages/LoginPage"
import OpenMap from "./router/pages/OpenMap"
import { ProtectedRoute } from "./router/ProtectedRoute"
import { setAuthTokenActionCreator } from "./store/slices"
import { useAppDispatch } from "./store/utils"

export const history = createBrowserHistory()

const App = (): ReactElement => {
	const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
	const [hasToken, setHasToken] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	useEffect(() => {
		const setAccessToken = async () => {
			const claims = await getIdTokenClaims()
			const { __raw: accessToken } = claims || { __raw: "" }

			dispatch(setAuthTokenActionCreator(accessToken))
		}

		if (isAuthenticated && !isLoading) {
			setAccessToken().then(() => {
				setHasToken(true)
			})
		}
	}, [dispatch, getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading])
	if (!window.location.pathname.includes("open") && (!isAuthenticated || !hasToken)) {
		return <LoginPage />
	}
	return (
		<Routes>
			<Route path="/" element={<ProtectedRoute component={MapPage} />} />
			<Route path="/open/map" element={<OpenMap />} />
			<Route path="*" element={<LoginPage />} />
		</Routes>
	)
}

export default App
