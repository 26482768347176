import styled from "styled-components"
import tw from "twin.macro"

export const StyledStatsWrapper = styled.div`
	${tw`flex flex-col mr-4 p-2 rounded-lg w-72 h-32 bg-white shadow-lg justify-between`}
`

export const StyledStatsTitle = styled.div`
	${tw`flex text-gray-400 text-sm font-semibold overflow-ellipsis`}
`

export const StyledStatsContent = styled.div`
	${tw`flex flex-row h-16`}
`
export const StyledStatsCol = styled.div`
	${tw`flex flex-col w-1/2`}
`

export const StyledStatsDemandRow = styled.div`
	${tw`flex flex-row items-center`}
`

export const StyledStatsDemandCount = styled.div`
	${tw`flex tracking-tighter font-bold text-bb-petrol-500 text-2xl overflow-ellipsis`}
`

export const StyledStatsDemandIndicator = styled.img`
	${tw`h-3 mr-2`}
`

export const StyledFooter = styled.div`
	${tw`flex flex-row min-h-[20px] text-gray-400 font-medium text-sm overflow-ellipsis`}
`
