import { useGetAuthoritiesQuery } from "../../services/deviceAccess"

export interface UseDeviceAccessAuthoritiesResult {
	canViewLockers: boolean
	canOpenLockers: boolean
	canAudit: boolean
	canUpdateDemandLevel: boolean
}

export const useDeviceAccessAuthorities = (): UseDeviceAccessAuthoritiesResult => {
	const { data } = useGetAuthoritiesQuery()

	return {
		canViewLockers: data ? data.includes("READ") : false,
		canOpenLockers: data ? data.includes("OPEN_LOCKER") : false,
		canAudit: data ? data.includes("READ_AUDIT") : false,
		canUpdateDemandLevel: data ? data.includes("UPDATE_DEMAND_LEVEL") : false
	}
}
