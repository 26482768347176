import styled, { keyframes } from "styled-components"

const animation = keyframes`
    from{
        opacity: 1.0;
        transform: translate(-50%, -50%) scale(0.0);
    }
    to{
        opacity: 0.0;
        transform: translate(-50%, -50%) scale(1.0);
    }
`

export const SpinnerDot = styled.div<{ size: string; color: string }>`
	width: ${({ size }) => size};
	height: ${({ size }) => size};
	position: absolute;
	background: ${({ color }) => color};
	top: 50%;
	left: 50%;
	border-radius: 100%;
	animation: ${animation} 1s ease-in-out infinite;
`
