import { Feature, FeatureCollection } from "geojson"
import { AnyLayer, AnyLayout, AnyPaint, SymbolLayer } from "mapbox-gl"
import { Ref } from "react"
import { LayerProps } from "react-map-gl"

export type ILayerProps = AnyLayer & {
	id: string
	type:
		| "fill"
		| "line"
		| "symbol"
		| "circle"
		| "fill-extrusion"
		| "raster"
		| "background"
		| "heatmap"
		| "hillshade"
		| "sky"
		| "custom"
	source?: string
	"source-layer"?: string
	beforeId?: string
	layout?: AnyLayout
	paint: AnyPaint
	filter?: any[]
	minzoom?: number
	maxzoom?: number
	ref?: Ref<ILayerProps>
}

export type TFeature = Feature & {
	geometry: Geometry
	type: "Feature"
	properties: Properties
	layer?: Layer
	source?: string
	state?: State
	id?: number
}
export type TOpenFeature = Feature & {
	geometry: Geometry
	type: "Feature"
	properties: OpenProperties
	layer?: Layer
	source?: string
	state?: State
	id?: number
}

export interface Geometry {
	type: string
	coordinates: number[]
}

export interface Layer {
	id: string
	type: string
	source: string
	filter: string[]
	paint: Paint
	layout: State
}

export interface State {
	[key: string]: string
}

export interface Paint {
	"circle-color": CircleEColor
	"circle-stroke-width": number
	"circle-stroke-color": CircleEColor
	"circle-radius": number
}

export interface CircleEColor {
	r: number
	g: number
	b: number
	a: number
}

export type Properties = LockerProperty & ClusterProperty

export interface LockerProperty {
	id?: number
	identifier?: string
	demand?: string
	enabled?: boolean
	name?: string
	color?: string
	lat: number
	lng: number
}

export interface ClusterProperty {
	cluster?: boolean
	cluster_id?: number
	point_count?: number
	point_count_abbreviated?: number
}

export type OpenProperties = OpenLockerProperty & ClusterProperty
export interface OpenLockerProperty{
	id?: number
	name: string
	lat: number
	lng: number
	address: string
	openHours: string[]
}

export const clusterLayer: ILayerProps = {
	id: "clusters",
	type: "circle",
	source: "lockers",
	filter: ["has", "point_count"],
	paint: {
		"circle-color": "#82d8ff",
		"circle-stroke-width": 2,
		"circle-stroke-color": "#afecf8",
		"circle-radius": [
			"step",
			["get", "point_count"],
			10,
			10,
			20,
			20,
			25,
			50,
			30,
			100,
			35,
			200,
			50,
		],
	},
}

export const clusterCountLayer: ILayerProps = {
	id: "cluster-count",
	type: "symbol",
	source: "lockers",
	filter: ["has", "point_count"],
	layout: {
		"text-field": "{point_count_abbreviated}",
		"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
		"text-size": 12,
	},
	paint: {},
}

export const unclusteredPointLayer: ILayerProps = {
	id: "unclustered-point",
	type: "circle",
	source: "lockers",
	filter: ["!", ["has", "point_count"]],
	paint: {
		"circle-color": ["get", "color"],
		"circle-radius": 6,
		"circle-stroke-width": 2,
		"circle-stroke-color": "#fff",
	},
}




export const clusterPinLayer: ILayerProps = {
	id: "clusters-pin",
	type: "symbol",
	source: "lockers",
	filter: ["has", "point_count"],
	layout:{
		'icon-image':'group-map-pin',
		'icon-size': 0.6,
		'icon-offset': [0,0],
		'icon-allow-overlap': true
	},
	paint:{
		
	}
}

export const pinLayer: ILayerProps = {
	id: "pin-point",
	type: "symbol",
	source: "lockers",
	filter: ["!", ["has", "point_count"]],
	layout:{
		'icon-image':'map-pin',
		'icon-size': 0.5,
		'icon-offset': [0,0],
		'icon-allow-overlap': true,
		'icon-anchor': 'bottom'
		
	},
	paint:{
		
	}
}