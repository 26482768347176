import React, { ReactNode } from "react"
import { Body } from "./GlobalModal.styles"

type Props = {
	children?: ReactNode
}

const GlobalModalBody = ({ children }: Props) => {
	return <Body>{children}</Body>
}
GlobalModalBody.defaultProps = {
	children: null,
}
export default GlobalModalBody
