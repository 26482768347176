import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import demandMax from "../assets/demandMax.png"
import demandMed from "../assets/demandMed.png"
import demandMin from "../assets/demandMin.png"
import { LockerDemand } from "../types/lockers"

const StyledStatsDemandIndicator = styled.img`
	${tw`h-3 mx-2`}
`

type Props = {
	demand: LockerDemand
}

const DemandIndicator = ({ demand }: Props) => {
	let demandIcon
	let demandString

	switch (demand) {
		case LockerDemand.LOW_BOUND:
			demandIcon = demandMin
			demandString = "Low bound"
			break
		case LockerDemand.HIGH_DEMAND:
			demandIcon = demandMed
			demandString = "High demand"
			break

		case LockerDemand.MAXIMUM_BOUND:
			demandIcon = demandMax
			demandString = "Max bound"
			break
		default:
			demandIcon = demandMin
			demandString = "Low bound"
	}
	return <StyledStatsDemandIndicator src={demandIcon} />
}

export default DemandIndicator
