import React, { ReactElement } from "react"
import { ToastContainer } from "react-toastify"
import ClusterMapView from "../../components/ClusterMapView"
import LockerView from "../../components/SelectedLocker/LockerView"
import LockersSidebar from "../../components/Sidebar/LockersSidebar"

const MapPage = (): ReactElement => {
	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={750}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
			/>
			<LockerView />
			<ClusterMapView />
			<LockersSidebar />
		</>
	)
}

export default MapPage
