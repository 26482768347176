import React from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import styled from "styled-components"
import tw from "twin.macro"

const StyledStatsLoader = styled(AiOutlineLoading3Quarters)`
	${tw`text-5xl text-gray-400 animate-spin self-center`}
`

const StatsLoader = () => {
	return <StyledStatsLoader />
}

export default StatsLoader
