import { Button, ButtonVariants } from "@budbee-monorepo/ui"
import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react"
import { InnerWrapper, ModalInput, ModalLabel, Wrapper } from "./GlobalModal.styles"
import GlobalModalBody from "./GlobalModalBody"
import GlobalModalFooter from "./GlobalModalFooter"
import GlobalModalHeader from "./GlobalModalHeader"

interface IGlobalModalStringInputProp {
	title: string
	text: string
	placeholder: string
	label: string
	onCancel: () => void
	isValid: (value: string) => boolean
	onSubmit: (value: string) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants
}
const GlobalModalStringInput = ({
	title,
	text,
	placeholder,
	label,
	onCancel,
	isValid,
	onSubmit,
	submitButtonText,
	submitButtonVariant,
}: IGlobalModalStringInputProp) => {
	const [value, setValue] = useState<string>("")
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value)
	}

	return (
		<Wrapper>
			<InnerWrapper>
				<GlobalModalHeader title={title} onCancel={onCancel} />
				<GlobalModalBody>
					<p>{text}</p>
					<ModalLabel htmlFor="input">{label}</ModalLabel>
					<ModalInput
						name="input"
						type="text"
						placeholder={placeholder}
						value={value}
						onChange={handleChange}
					/>
				</GlobalModalBody>
				<GlobalModalFooter>
					<Button variant={ButtonVariants.SECONDARY} onClick={onCancel}>
						Cancel
					</Button>
					<Button
						variant={submitButtonVariant}
						onClick={() => (value ? onSubmit(value) : null)}
						disabled={!isValid(value)}
					>
						{submitButtonText}
					</Button>
				</GlobalModalFooter>
			</InnerWrapper>
		</Wrapper>
	)
}

export default GlobalModalStringInput
