import { AppState, Auth0Provider } from "@auth0/auth0-react"
import React, { PropsWithChildren } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ExternalEnvironmentVariables } from "./utils/environment-variables"

interface Auth0Props {
	config: ExternalEnvironmentVariables
}
export const Auth0ProviderWithHistory = ({
	children,
	config,
}: PropsWithChildren<Auth0Props>): JSX.Element | null => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	const domain: string | undefined = config.REACT_APP_AUTH0_DOMAIN
	const clientId: string | undefined = config.REACT_APP_AUTH0_CLIENT_ID
	const audience: string | undefined = config.REACT_APP_AUTH0_AUDIENCE
	const onRedirectCallback = (appState: AppState) => {
		const locker = searchParams.get("locker")
		const url = locker
			? `${window.location.pathname}?locker=${locker}`
			: `${window.location.pathname}`
		navigate(appState?.returnTo || url)
	}

	if (!(domain && clientId && audience)) {
		return null
	}
	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			audience={audience}
			redirectUri={window.location.href}
			onRedirectCallback={onRedirectCallback}
			scope="openid"
			cacheLocation="memory"
			useRefreshTokens
		>
			{children}
		</Auth0Provider>
	)
}
