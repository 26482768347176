import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { v1 as uuid } from "uuid"
import { ILockersWithin } from "../../types/lockers"

const initialState: ILockersWithin = {
	id: uuid(),
	radius: 10000,
	coordinates: {
		lat: 59.336057,
		lng: 18.02866,
	},
	customPin: null,
	flyTo: null,
}

export const lockersWithinSlice = createSlice({
	name: "lockersWithin",
	initialState,
	reducers: {
		update: (draftState, { payload }: PayloadAction<ILockersWithin>) => ({
			...payload,
			customPin: draftState.customPin,
		}),
		setCustomPin: (
			draftState,
			{ payload }: PayloadAction<{ lat: number; lng: number; showOnMap: boolean } | null>
		) => ({
			...draftState,
			customPin: payload,
		}),
		removeCustomPin: (draftState) => ({
			...draftState,
			customPin: null,
		}),
		setFlyMap: (draftState, { payload }: PayloadAction<{ lat: number; lng: number }>) => ({
			...draftState,
			flyTo: payload,
		}),
		removeFlyTo: (draftState) => ({ ...draftState, flyTo: null }),
	},
})

export const {
	update: lockersWithinActionCreator,
	setCustomPin: setCustomPinActionCreator,
	removeCustomPin: removeCustomPinActionCreator,
	setFlyMap: setFlyMapActionCreator,
	removeFlyTo: removeFlyToActionCreator,
} = lockersWithinSlice.actions
