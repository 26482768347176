import React, { ReactElement, useEffect } from "react"
import { IoIosCheckmark } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { useGetDisabledReasonsQuery, useGetPostalCodeZonesQuery } from "../../../services/lockers"
import {
	setAllFiltersActiveActionCreator,
	setAllFiltersInactiveActionCreator,
	setDisabledReasonsActionCreator,
	toggleDisabledReasonActionCreator,
	toggleToggleFiltersActionCreator,
} from "../../../store/slices"
import {
	IGlobalState,
	ISelectOption,
	ITogglableFilters,
	TogglableFilterNames,
} from "../../../types/global"
import { IDisabledReason, IPostalCodeZone, LockerDemand } from "../../../types/lockers"
import { getCountryNameByCode } from "../../../utils/getCountryNameByCode"
import {
	StyledButton,
	StyledFilterHeader,
	StyledFilterSection,
	StyledFilterSectionHeader,
	StyledFilterSections,
	StyledSearchFilterContainer,
} from "./SearchFilter.styles"
import SelectInput from "./SelectInput"

type SearchFilterProps = {
	handleSelectFilter: (e: ISelectOption[], string: string) => void
}
type FilterButtons = {
	heading: string
	filter: FilterEntry[]
}
enum GeneralToggle {
	ALL = "ALL",
	ENABLED = "ENABLED",
	DISABLED = "DISABLED",
}

type FilterEntry = {
	id: LockerDemand | GeneralToggle | number
	name: string
	active: boolean
}
export const countryOptions: ISelectOption[] = [
	{ value: "BE", label: "Belgium" },
	{ value: "DE", label: "Germany" },
	{ value: "DK", label: "Denmark" },
	{ value: "FI", label: "Finland" },
	{ value: "LU", label: "Luxembourg" },
	{ value: "NL", label: "Netherlands" },
	{ value: "NO", label: "Norway" },
	{ value: "SE", label: "Sweden", isFixed: true },
]

const generateRegionOptions = (postalCodeZones: IPostalCodeZone[] | undefined): ISelectOption[] => {
	if (!postalCodeZones) return []
	return postalCodeZones.map((el) => {
		const { country, id, title } = el
		return {
			value: id,
			label: `${country},${id},${title},${getCountryNameByCode(country)}`,
			name: title,
			countryCode: country,
			countryName: getCountryNameByCode(country),
		}
	})
}
const lockerDemandToTogglableFilterNames = (
	lockerDemand: LockerDemand | GeneralToggle
): TogglableFilterNames | null => {
	switch (lockerDemand) {
		case LockerDemand.HIGH_DEMAND:
			return "showHighdemand"
		case LockerDemand.MAXIMUM_BOUND:
			return "showMaxBound"
		case LockerDemand.LOW_BOUND:
			return "showLowBound"
		default:
			return null
	}
}

const filterButtons = (
	filters: ITogglableFilters,
	disabledReasons: IDisabledReason[] | undefined
): FilterButtons[] => {
	if (!disabledReasons) return []
	return [
		{
			heading: "",
			filter: [
				{
					id: GeneralToggle.ALL,
					name: "All",
					active:
						filters.showMaxBound &&
						filters.showLowBound &&
						filters.showEnabled &&
						filters.showHighdemand &&
						(filters.showDisabledReasons
							? filters.showDisabledReasons.length === disabledReasons.length
							: true),
				},
				{
					id: GeneralToggle.ENABLED,
					name: "Enabled",
					active: filters.showEnabled,
				},
				{
					id: GeneralToggle.DISABLED,
					name: "Disabled",
					active: filters.showDisabledReasons
						? filters.showDisabledReasons.length === disabledReasons.length
						: true,
				},
			],
		},
		{
			heading: "Demand",
			filter: [
				{
					id: LockerDemand.MAXIMUM_BOUND,
					name: "Max-bound",
					active: filters.showMaxBound,
				},
				{
					id: LockerDemand.HIGH_DEMAND,
					name: "High demand",
					active: filters.showHighdemand,
				},
				{
					id: LockerDemand.LOW_BOUND,
					name: "Low-bound",
					active: filters.showLowBound,
				},
			],
		},
		{
			heading: "Disabled reasons",
			filter: disabledReasons
				? disabledReasons.map((reason) => {
						const inArray: boolean =
							filters.showDisabledReasons?.find((r) => r.id === reason.id) !==
							undefined

						return {
							id: reason.id,
							name: reason.reason,
							active: inArray,
						}
				  })
				: [],
		},
	]
}

const SearchFilter = ({ handleSelectFilter }: SearchFilterProps): ReactElement => {
	const filters = useSelector((state: IGlobalState) => state.sidebarFilters.toggle)
	const { data: disabledReasons } = useGetDisabledReasonsQuery()
	const { data: postalCodeZones } = useGetPostalCodeZonesQuery()
	const dispatch = useDispatch()
	const generatedOptions = generateRegionOptions(postalCodeZones)
	useEffect(() => {
		if (disabledReasons) {
			const mapped: { id: number }[] = disabledReasons.map<{ id: number }>((reason) => ({
				id: reason.id,
			}))
			dispatch(setDisabledReasonsActionCreator(mapped))
		}
	}, [disabledReasons, dispatch])

	const handleToggleFilter = (entry: FilterEntry) => {
		if (typeof entry.id === "number") {
			dispatch(
				toggleDisabledReasonActionCreator({
					id: entry.id,
				})
			)
		} else if (Object.values(GeneralToggle).findIndex((val) => val === entry.id) > -1) {
			switch (entry.id) {
				case GeneralToggle.ALL:
					if (entry.active) {
						dispatch(setAllFiltersInactiveActionCreator())
					} else if (disabledReasons) {
						const mapped: { id: number }[] = disabledReasons.map<{ id: number }>(
							(reason) => ({
								id: reason.id,
							})
						)
						dispatch(setAllFiltersActiveActionCreator(mapped))
					}

					break
				case GeneralToggle.ENABLED:
					dispatch(
						toggleToggleFiltersActionCreator({
							filter: "showEnabled",
						})
					)
					break
				case GeneralToggle.DISABLED:
					if (entry.active) {
						dispatch(setDisabledReasonsActionCreator([]))
					} else if (disabledReasons) {
						const mapped: { id: number }[] = disabledReasons.map<{ id: number }>(
							(reason) => ({
								id: reason.id,
							})
						)
						dispatch(setDisabledReasonsActionCreator(mapped))
					}
					break
				default:
					break
			}
		} else {
			const filterName = lockerDemandToTogglableFilterNames(entry.id)
			if (filterName) {
				dispatch(
					toggleToggleFiltersActionCreator({
						filter: filterName,
					})
				)
			}
		}
	}
	return (
		<StyledSearchFilterContainer>
			<SelectInput
				handleSelectFilter={handleSelectFilter}
				options={countryOptions}
				type="country"
			/>
			<SelectInput
				handleSelectFilter={handleSelectFilter}
				options={generatedOptions}
				type="region"
			/>
			<StyledFilterSections>
				<StyledFilterHeader>Filters</StyledFilterHeader>
				{filterButtons(filters, disabledReasons).map((filterSection) => {
					const filterEntries = filterSection.filter.map((filterEntry) => {
						return (
							<StyledButton
								key={filterEntry.id}
								disabled={false}
								enabled={filterEntry.active}
								onClick={() => handleToggleFilter(filterEntry)}
								type="button"
							>
								{filterEntry.active ? <IoIosCheckmark size={20} /> : null}
								{filterEntry.name}
							</StyledButton>
						)
					})
					return (
						<>
							<StyledFilterSectionHeader>
								{filterSection.heading}
							</StyledFilterSectionHeader>
							<StyledFilterSection>{filterEntries}</StyledFilterSection>
						</>
					)
				})}
			</StyledFilterSections>
		</StyledSearchFilterContainer>
	)
}

export default SearchFilter
