import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	ISelectFilter,
	ISelectOption,
	ITogglableFilters,
	TogglableFilterNames,
} from "../../types/global"

const toggleFiltersInitialState: ITogglableFilters = {
	showMaxBound: true,
	showLowBound: true,
	showHighdemand: true,
	showEnabled: true,
	showDisabledReasons: undefined,
}

const selectFiltersInitialState: ISelectFilter[] = [
	{ type: "country", active: [] },
	{ type: "region", active: [] },
]

export const searchTermSlice = createSlice({
	name: "searchTerm",
	initialState: "",
	reducers: {
		update: (draftState, { payload }: PayloadAction<string>) => {
			return payload
		},
	},
})

export const selectFiltersSlice = createSlice({
	name: "selectFilters",
	initialState: selectFiltersInitialState,
	reducers: {
		update: (
			draftState,
			{ payload }: PayloadAction<{ type: string; active: ISelectOption[] }>
		) => {
			const index = draftState.findIndex((filter) => filter.type === payload.type)
			draftState[index].active = payload.active
		},
	},
})

export const toggleFiltersSlice = createSlice({
	name: "toggleFilters",
	initialState: toggleFiltersInitialState,
	reducers: {
		setFilter: (
			draftState,
			{ payload }: PayloadAction<{ filter: TogglableFilterNames; active: boolean }>
		) => {
			draftState[payload.filter] = payload.active
		},
		toggle: (draftState, { payload }: PayloadAction<{ filter: TogglableFilterNames }>) => {
			draftState[payload.filter] = !draftState[payload.filter]
		},
		toggleDisabledReason: (draftState, { payload }: PayloadAction<{ id: number }>) => {
			if (draftState.showDisabledReasons) {
				const existingIndex = draftState.showDisabledReasons.findIndex(
					(reason) => reason.id === payload.id
				)
				if (existingIndex > -1) {
					draftState.showDisabledReasons.splice(existingIndex, 1)
				} else {
					draftState.showDisabledReasons.push({ id: payload.id })
				}
			} else {
				draftState.showDisabledReasons = [{ id: payload.id }]
			}
		},
		setAllFiltersActive: (draftState, { payload }: PayloadAction<{ id: number }[]>) => {
			return {
				...toggleFiltersInitialState,
				showDisabledReasons: payload,
			}
		},
		setAllFiltersInactive: (draftState) => {
			return {
				showMaxBound: false,
				showLowBound: false,
				showHighdemand: false,
				showEnabled: false,
				showDisabledReasons: [],
			}
		},
		setDisabledReasons: (draftState, { payload }: PayloadAction<{ id: number }[]>) => {
			return {
				...draftState,
				showDisabledReasons: payload,
			}
		},
	},
})

export const isExpandFiltersSlice = createSlice({
	name: "isExpandFilters",
	initialState: false,
	reducers: {
		toggle: (draftState) => {
			return !draftState
		},
	},
})

export const { update: updateSearchTermActionCreator } = searchTermSlice.actions
export const {
	setFilter: setFilterToggleFiltersActionCreator,
	toggle: toggleToggleFiltersActionCreator,
	toggleDisabledReason: toggleDisabledReasonActionCreator,
	setAllFiltersActive: setAllFiltersActiveActionCreator,
	setAllFiltersInactive: setAllFiltersInactiveActionCreator,
	setDisabledReasons: setDisabledReasonsActionCreator,
} = toggleFiltersSlice.actions
export const { update: updateSelectFiltersActionCreator } = selectFiltersSlice.actions
export const { toggle: toggleIsExpandFiltersActionCreator } = isExpandFiltersSlice.actions
