import Select from "react-select"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledSelect = styled(Select)`
	${tw`mb-2 w-full flex-grow text-black focus:ring-2 focus:ring-black`}
	.react-select__control {
		${tw`rounded-lg border-none`}
	}

	.react-select__value-container {
		min-height: 2.5rem;
	}
`

export const StyledSelectElement = styled.div`
	${tw`flex flex-row`}
`

export const StyledBadge = styled.div`
	${tw`text-xs text-gray-100 mr-1 px-[4px] bg-bb-petrol-500 rounded`}
`
export const StyledText = styled.div`
	${tw`text-xs text-gray-800 mr-1`}
`
