export const getCountryNameByCode = (countryCode: string) => {
	switch (countryCode) {
		case "SE":
			return "sweden"
		case "NL":
			return "netherlands"
		case "BE":
			return "belgium"
		case "LU":
			return "luxembourg"
		case "DK":
			return "denmark"
		case "FI":
			return "finland"
		case "DE":
			return "germany"
		case "NO":
			return "norway"
		default:
			return "sweden"
	}
}
