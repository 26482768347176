import React from "react"
import { Header, HeaderCloseButton, HeaderTitle } from "./GlobalModal.styles"

interface IGlobalModalHeader {
	title: string
	onCancel: () => void
}
const GlobalModalHeader = ({ title, onCancel }: IGlobalModalHeader) => {
	return (
		<Header>
			<HeaderTitle>{title}</HeaderTitle>
			<HeaderCloseButton onClick={onCancel}>X</HeaderCloseButton>
		</Header>
	)
}

export default GlobalModalHeader
