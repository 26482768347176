import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"

const baseQuery = fetchBaseQuery({
	baseUrl: "",
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

interface IUnassignParcelData {
	identifier: string
	parcelId: number
}
// Define a service using a base URL and expected endpoints
export const parcelsApi = createApi({
	reducerPath: "parcelsApi",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		unassignParcel: builder.mutation<void, IUnassignParcelData>({
			query: ({ parcelId }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}parcels/manual-cancel-parcel-delivery`,
				method: "POST",
				body: {
					parcelId,
				},
			}),
		}),
		setParcelToPresent: builder.mutation<void, IUnassignParcelData>({
			query: ({ parcelId }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}parcels/manual-confirm-parcel-delivery`,
				method: "POST",
				body: {
					parcelId,
				},
			}),
		}),
	}),
})

export const { useUnassignParcelMutation, useSetParcelToPresentMutation } = parcelsApi
