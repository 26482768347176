import "mapbox-gl/dist/mapbox-gl.css"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { PersistGate } from "redux-persist/integration/react"
import { getConfig } from "./utils/environment-variables"
import App from "./App"
import { Auth0ProviderWithHistory } from "./Auth0ProviderWithHistory"
import GlobalModalWrapper from "./components/GlobalModal/GlobalModalWrapper"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { persistor, store } from "./store/configureStore"

getConfig().then((config) => {
	window.config = config.data
	ReactDOM.render(
		<React.StrictMode>
			<BrowserRouter>
				<Auth0ProviderWithHistory config={config.data}>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<App />
							<GlobalModalWrapper />
						</PersistGate>
					</Provider>
				</Auth0ProviderWithHistory>
			</BrowserRouter>
		</React.StrictMode>,
		document.getElementById("root")
	)
})

// To to start measuring performance, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Docs: https://bit.ly/CRA-vitals
reportWebVitals()
