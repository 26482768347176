import React, { ReactElement } from "react"
import budbeeLogo from "../../assets/budbeegreen.png"
import { StyledLockersSidebarHeader, StyledLogo, StyledText } from "./LockersSidebarHeader.styles"

const LockersSidebarHeader = (): ReactElement => {
	return (
		<StyledLockersSidebarHeader>
			<StyledLogo src={budbeeLogo} alt="Budbee logo" />
			<StyledText>Bumblebox</StyledText>
		</StyledLockersSidebarHeader>
	)
}

export default LockersSidebarHeader
