import React, { useState } from "react"
import { ActiveTab, Tab, Wrapper } from "./Tabs.styles"

export interface ITab {
	title: string
	identifier: string
}
type Props = {
	data: ITab[]
	identifier: string
	handleChange: (Identifier: string) => void
}
const Tabs = ({ data, identifier, handleChange }: Props) => {
	const handleClick = (newIdentifier: string) => {
		handleChange(newIdentifier)
	}
	const tabButtons = data.map((val) => {
		if (val.identifier === identifier) {
			return (
				<ActiveTab
					onClick={() => handleClick(val.identifier)}
					key={val.identifier}
					aria-hidden="true"
				>
					{val.title}
				</ActiveTab>
			)
		}
		return (
			<Tab
				onClick={() => handleClick(val.identifier)}
				key={val.identifier}
				aria-hidden="true"
			>
				{val.title}
			</Tab>
		)
	})
	return <Wrapper>{tabButtons}</Wrapper>
}

export default Tabs
