import { toast } from "react-toastify"

export const wipToast = () => {
	toast.warn("Not implemented yet, sorry for the inconvenience!", {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}

export const errorToast = (message: string) => {
	toast.error(message, {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}
export const notAnAdminToast = () => {
	toast.warn("You don't have access to this function", {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}
export const ExtendedTimeSuccessfullyToast = (days: number) => {
	toast.success(`Successfully extended expiry time with ${days} days`, {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}
export const ResetErrorStateSuccessfullyToast = (id: number | null) => {
	toast.success(
		`Successfully changed the state of  ${id ? `box with id ${id}` : "multiple boxes"}`,
		{
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		}
	)
}
export const ResetErrorStateForAllBoxesSuccessfullyToast = (identifier: string) => {
	toast.success(`Successfully reset the state of all boxes for locker ${identifier}`, {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}
export const OpenCompartmentWorkingToast = () => {
	return toast.loading(`Opening locker`, {
		position: "top-right",
		autoClose: 3500,
		hideProgressBar: false,
		closeOnClick: true,
		draggable: true,
		progress: undefined,
	})
}
export const OpenCompartmentSuccessToast = (loadingId: string | number) => {
	toast.update(loadingId, {
		render: `Successfully opened the compartment`,
		type: "success",
		isLoading: false,
		autoClose: 3500,
		closeButton: true,
	})
}

export const OpenCompartmentFailedToast = (loadingId: string | number, message: string) => {
	toast.update(loadingId, {
		render: message,
		type: "error",
		isLoading: false,
		autoClose: 10000,
		closeButton: true,
	})
}
