import { IoMdClose } from "react-icons/io"
import styled from "styled-components"
import tw from "twin.macro"
import Select, { Theme } from "react-select"

export const Wrapper = styled.div`
	${tw`absolute top-0 right-0 bottom-0 left-0 bg-bb-black/60 z-50 `}
`
export const InnerWrapper = styled.div`
	transform: translate(-50%, -50%);
	${tw`absolute left-1/2 top-1/2 bg-bb-gray-300 w-[32rem] rounded-lg border border-bb-gray-400`};
`
export const Header = styled.div`
	${tw`bg-bb-white py-5 px-6 flex justify-between border-b border-bb-gray-400`}
`
export const HeaderTitle = styled.h2`
	${tw`font-semibold text-base`}
`
export const HeaderCloseButton = styled(IoMdClose)`
	${tw`text-2xl cursor-pointer`}
`
export const Body = styled.div`
	${tw`p-6`}
`
export const Footer = styled.div`
	${tw`pb-6 px-6 flex flex-row-reverse gap-4`}
`

export const ModalInput = styled.input`
	${tw`w-full text-bb-black bg-bb-white border border-bb-black rounded-lg pl-6 pr-2 py-2 outline-none ring-inset ring-white focus:ring-2`}

	-webkit-appearance: none;
	-moz-appearance: textfield;
	appearance: textfield;
	margin: 0;
`
export const ModalLabel = styled.label`
	${tw`block text-base font-semibold my-2`}
`
export const StyledSelect = styled(Select)`
	${tw`flex-grow text-bb-black max-w-full`}
	.react-select__control {
		${tw`rounded-lg`}
	}
	.react-select__value-container {
		${tw`min-h-[2.5rem]`}
	}
`
export const SelectTheme = (theme: Theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary: "#114e4c",
		primary75: "#BAD4D3",
		primary50: "#D1E2E2",
		primary25: "#E8F1F0",
	},
})
