import { skipToken } from "@reduxjs/toolkit/query"
import React, { ReactElement, useEffect, useState } from "react"
import { BiSlider } from "react-icons/bi"
import { MdSearch } from "react-icons/md"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import tw from "twin.macro"
import { useSearchLockersQuery } from "../../../services/lockers"
import { updateSearchTermActionCreator } from "../../../store/slices"
import { useDebounce } from "../../../utils/hooks/useDebounce"

const IconContainer = styled.span`
	${tw`z-10 py-4 rounded-full leading-snug font-normal absolute text-center absolute bg-transparent text-base items-center justify-center`},
`

const SearchIconContainer = styled(IconContainer)`
	${tw`pl-4 h-full pointer-events-none`}
`

const FilterIconContainer = styled(IconContainer)<{ isExpandedFilters: boolean }>`
	${tw`right-0 px-2 py-2 mt-2 mr-3 cursor-pointer`}

	:hover {
		${tw`bg-white bg-opacity-30`}
	}
	${(props) => {
		return props.isExpandedFilters ? tw`bg-white bg-opacity-20` : ""
	}}
`

const StyledContainer = styled.div`
	${tw`relative flex flex-row w-full h-14 items-stretch mb-4 mt-4`};
`

const StyledMdSearch = styled(MdSearch)`
	${tw`text-bb-white text-2xl`};
`
const StyledBiSlider = styled(BiSlider)`
	${tw`text-bb-white text-2xl`};
`

const StyledInput = styled.input`
	${tw`pl-4 pr-14 py-3 placeholder-bb-gray-400  text-white relative bg-bb-petrol-500 rounded-md w-full pl-12 font-semibold outline-none border-2`};
`

interface SearchInputProps {
	toggleIsExpandFilters: () => void
	isExpandedFilters: boolean
	selectTopResult: () => void
}

const SearchInput = ({
	toggleIsExpandFilters,
	isExpandedFilters,
	selectTopResult,
}: SearchInputProps): ReactElement => {
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState<string | null>(null)
	const debouncedSearchTerm = useDebounce(searchTerm, 500)
	const { data, error, isLoading, isSuccess, isError } = useSearchLockersQuery(
		debouncedSearchTerm ?? skipToken
	)

	useEffect(() => {
		const searchString = debouncedSearchTerm || ""

		dispatch(updateSearchTermActionCreator(searchString))
	}, [data, error, isLoading, isSuccess, isError, debouncedSearchTerm, dispatch])

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			selectTopResult()
		}
	}
	return (
		<StyledContainer>
			<SearchIconContainer>
				<StyledMdSearch />
			</SearchIconContainer>
			<StyledInput
				onChange={(e) => setSearchTerm(e.target.value)}
				type="text"
				placeholder="Search lockers"
				onKeyDown={onKeyDown}
			/>
			<FilterIconContainer
				isExpandedFilters={isExpandedFilters}
				onClick={toggleIsExpandFilters}
			>
				<StyledBiSlider />
			</FilterIconContainer>
		</StyledContainer>
	)
}

export default SearchInput
