import { useAuth0 } from "@auth0/auth0-react"
import { Spinner } from "@budbee-monorepo/ui"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	useAddLockerCommentMutation,
	useDeleteLockerCommentMutation,
	useEditLockerCommentMutation,
	useGetLockerCommentsQuery,
} from "../../../services/lockerComments"
import { IGlobalState } from "../../../types/global"
import { timeAndDate } from "../../../utils/timeFormatter"
import {
	Wrapper,
	Comment,
	CommentTitle,
	CommentText,
	CommentTimestamp,
	ButtonWrapper,
	CommentButtonWrapper,
	CommentBottomContent,
	CommentEditButton,
	CommentDeleteButton,
	AddCommentWrapper,
	AddComment,
	AddCommentTitle,
	AddCommentTextArea,
	AddCommentButtons,
	AddCommentCancelButton,
	OpenAddCommentButton,
	AddCommentAddButton,
	CommentInner,
	OuterWrapper,
} from "./LockerComments.styles"

const LockerComments = () => {
	const [addCommentIsOpen, setAddCommentIsOpen] = useState<boolean>(false)
	const [newComment, setNewComment] = useState<string>("")
	const [editId, setEditId] = useState<number | null>(null)
	const selectedLockerIdentifer = useSelector(
		(state: IGlobalState) => state.lockers.selectedLocker
	)
	const { user } = useAuth0()
	const { data: comments, isLoading } = useGetLockerCommentsQuery(selectedLockerIdentifer)
	const [addLockerComment, addLockerCommentResult] = useAddLockerCommentMutation()
	const [deleteLockerComment, deleteLockerCommentResult] = useDeleteLockerCommentMutation()
	const [editLockerComment, editLockerCommentResult] = useEditLockerCommentMutation()
	const handleEditComment = (id: number, comment: string) => {
		setEditId(id)
		setNewComment(comment)
		setAddCommentIsOpen(true)
	}
	const handleDeleteComment = (id: number) => {
		deleteLockerComment({
			id,
			lockerIdentifier: selectedLockerIdentifer,
		})
	}
	const submitComment = () => {
		if (editId != null) {
			editLockerComment({
				id: editId,
				lockerIdentifier: selectedLockerIdentifer,
				data: {
					comment: newComment,
				},
			})
		} else {
			addLockerComment({
				lockerIdentifier: selectedLockerIdentifer,
				comment: newComment,
			})
		}

		setAddCommentIsOpen(false)
		setNewComment("")
	}
	const handleOpenNewComment = () => {
		setAddCommentIsOpen(true)
		setNewComment("")
		setEditId(null)
	}
	const handleCloseAddComment = () => {
		setAddCommentIsOpen(false)
		setTimeout(() => {
			setNewComment("")
			setEditId(null)
		}, 300)
	}
	const lockerComments = () => {
		if (!isLoading && comments) {
			return comments.map((comment) => {
				return (
					<Comment key={comment.createdAt}>
						<CommentInner>
							<CommentTitle>{`${comment.firstName} ${comment.lastName}`}</CommentTitle>
							<CommentText>{comment.comment}</CommentText>
							<CommentBottomContent>
								<CommentTimestamp>
									{timeAndDate(new Date(comment.createdAt))}
								</CommentTimestamp>
								{comment.createdByThisUser ? (
									<CommentButtonWrapper>
										<CommentEditButton
											type="button"
											onClick={() =>
												handleEditComment(comment.id, comment.comment)
											}
										>
											Edit
										</CommentEditButton>
										<CommentDeleteButton
											type="button"
											onClick={() => handleDeleteComment(comment.id)}
										>
											Delete
										</CommentDeleteButton>
									</CommentButtonWrapper>
								) : null}
							</CommentBottomContent>
						</CommentInner>
					</Comment>
				)
			})
		}
		return <Spinner />
	}
	return (
		<OuterWrapper>
			<Wrapper isOpen={addCommentIsOpen}>
				<ButtonWrapper>
					<OpenAddCommentButton type="button" onClick={handleOpenNewComment}>
						+ Add comment
					</OpenAddCommentButton>
				</ButtonWrapper>
				{lockerComments()}
			</Wrapper>
			<AddCommentWrapper isOpen={addCommentIsOpen}>
				<AddComment>
					<AddCommentTitle>{editId ? "Edit comment" : "Add comment"}</AddCommentTitle>
					<AddCommentTextArea
						placeholder="Your comment"
						value={newComment}
						onChange={(val) => setNewComment(val.target.value)}
					/>
					<AddCommentButtons>
						<AddCommentCancelButton onClick={handleCloseAddComment}>
							Cancel
						</AddCommentCancelButton>
						<AddCommentAddButton onClick={submitComment}>
							{editId ? "Edit comment" : "Add comment"}
						</AddCommentAddButton>
					</AddCommentButtons>
				</AddComment>
			</AddCommentWrapper>
		</OuterWrapper>
	)
}

export default LockerComments
