import styled from "styled-components"
import Select, { Theme } from "react-select"
import tw from "twin.macro"
import { MdError } from "react-icons/md"

export const StyledSelect = styled(Select)`
	${tw`flex-grow text-bb-black max-w-full`}
	.react-select__control {
		${tw`rounded-lg`}
	}
	.react-select__value-container {
		${tw`min-h-[2.5rem]`}
	}
`
export const Wrapper = styled.div`
	${tw`relative text-white px-4 h-full rounded-b-lg overflow-scroll bg-bb-petrol-600`};
	&::-webkit-scrollbar {
		${tw`w-4`};
	}
	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 0 10px rgba(7, 34, 33, 0.25);
		${tw`rounded-full bg-clip-content border-4 border-transparent min-h-[50px]`};
	}

	&::-webkit-scrollbar-button {
		${tw`w-0 h-0 hidden`}
	}

	&::-webkit-scrollbar-corner {
		${tw`bg-transparent `}
	}
`
export const StyledText = styled.div`
	${tw`text-xs text-gray-800 mr-1`}
`

export const ErrorContainer = styled.div`
	${tw`bg-bb-red-100 border-2 border-bb-red-500 rounded-lg flex items-center gap-4 p-2`}
`
export const StyledErrorText = styled.p`
	${tw`text-base text-black`}
`
export const StyledErrorIcon = styled(MdError)`
	${tw`text-bb-red-500 min-w-[2rem] min-h-[2rem]`}
`

export const StyledInput = styled.input`
	${tw`bg-bb-white text-bb-black border border-bb-gray-400 rounded-lg outline-none mb-3 py-3 px-4`}
	&:focus {
		${tw`border-bb-black`}
	}
`
export const StyledTextarea = styled.textarea`
	${tw`resize-none text-bb-black border border-bb-gray-400 rounded-lg outline-none mb-3 py-3 px-4 font-light text-sm h-20`}
`
export const StyledInputLabel = styled.span`
	${tw`text-base py-1.5 font-semibold`}
`
export const StyledButtonContainer = styled.div`
	${tw`flex justify-around py-4`}
`
export const StyledButtonColumnContainer = styled.div`
	${tw`flex flex-col py-4 px-2`}
`
export const StyledButton = styled.button`
	${tw`py-4 px-8 rounded-lg border border-bb-white bg-bb-white text-bb-black text-base font-semibold`}
`
export const StyledSecondaryButton = styled(StyledButton)`
	${tw`bg-transparent text-bb-white border border-bb-white`}
`
export const StyledSmallButton = styled.button`
	${tw`py-2 px-4 my-2 min-w-[107px] rounded-lg border border-bb-white bg-bb-white text-bb-black text-sm font-semibold`}
`
export const StyledTransparentSmallButton = styled(StyledSmallButton)`
	${tw`bg-transparent border-none border-none text-bb-red-500 m-0`}
`

export const OpeningHourWrapper = styled.div`
	${tw`lowercase`}
	&::first-letter {
		${tw`uppercase`}
	}
`
export const OpeningHourTitle = styled.span`
	${tw`text-base font-semibold`}
`

export const OpeningHour = styled.div`
	${tw`flex flex-row gap-8`}
`
export const OpeningHourPeriod = styled.div`
	${tw`flex flex-col pb-2`}
`
export const OpeningHourPeriodTitle = styled.span`
	${tw`capitalize text-sm font-light`}
`
export const OpeningHourInput = styled.input`
	${tw`text-bb-black w-full py-1 px-4 rounded-lg text-center outline-none border border-bb-gray-400`}
	&:focus {
		${tw`border-bb-black`}
	}
`
export const OpeningHourInputs = styled.div`
	${tw`flex items-center`}
`
export const OpeningHourInputDivider = styled.span`
	${tw`text-lg p-2`}
`

export const SelectTheme = (theme: Theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary: "#114e4c",
		primary75: "#BAD4D3",
		primary50: "#D1E2E2",
		primary25: "#E8F1F0",
	},
})
