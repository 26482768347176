import { skipToken } from "@reduxjs/toolkit/query"
import turfDistance from "@turf/distance"
import { point as turfPoint } from "@turf/helpers"
import { FeatureCollection, Point } from "geojson"
import _ from "lodash"
import mapboxgl, {
	GeoJSONSource,
	LngLatLike,
	MapboxGeoJSONFeature,
	MapLayerMouseEvent,
} from "mapbox-gl"
import * as React from "react"
import { ReactElement, useCallback, useEffect, useRef, useState } from "react"

import ReactMapGL, { Layer, MapRef, Marker, Source } from "react-map-gl"

import { useSearchParams } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { v1 as uuid } from "uuid"
import { useGetLockersWithinQuery } from "../services/lockers"
import { selectFilteredLockers } from "../store/selectors"
import {
	lockersWithinActionCreator,
	removeFlyToActionCreator,
	removeShowLockerOnMapActionCreator,
	selectLockerActionCreator,
	setCustomPinActionCreator,
} from "../store/slices"
import { useAppSelector } from "../store/utils"
import { IGlobalState } from "../types/global"
import { useMediaQuery } from "../utils/hooks/useMediaQuery"
import { StyledPopup } from "./ClusterMapView.styles"
import { clusterCountLayer, clusterLayer, TFeature, unclusteredPointLayer } from "./Layers"
import MapViewOverlay from "./MapViewOverlay"
import PopupContent from "./PopupContent"
import mapPin from "../assets/mapPin.png"
// The following is required to prevent mapbox from being transpiled during build.
// notice the exclamation point in the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const ClusterMapView = (): ReactElement => {
	const mapRef = useRef<MapRef>(null)
	const dispatch = useDispatch()
	const isLargeScreen = useMediaQuery("(min-width: 1536px)")
	const lockersWithin = useAppSelector((state) => state.map.lockersWithin)
	const showLockerOnMap = useAppSelector((state) => state.lockers.showLockerOnMap)

	const { data: lockersWithinQueryResult } = useGetLockersWithinQuery(lockersWithin ?? skipToken)

	const useSelectFilteredLockers = useSelector((state: IGlobalState) => {
		if (!lockersWithinQueryResult?.records) {
			return
		}
		return selectFilteredLockers(state, lockersWithinQueryResult?.records)
	}, shallowEqual)

	const [cursor, setCursor] = useState<string>("auto")
	const [lockersGeoJson, setLockersGeoJson] = useState<FeatureCollection | string>("")
	const [hoverInfo, setHoverInfo] = useState<any>(null)

	useEffect(() => {
		if (showLockerOnMap) {
			const { longitude, latitude } = showLockerOnMap
			const center = { lng: longitude, lat: latitude }
			mapRef.current?.flyTo({
				center: center,
				zoom: 15,
				duration: 2500,
			})
			dispatch(removeShowLockerOnMapActionCreator())
		} else if (lockersWithin.customPin && lockersWithin.customPin.showOnMap) {
			mapRef.current?.flyTo({
				center: lockersWithin.customPin,
				zoom: 15,
				duration: 2500,
			})
			dispatch(setCustomPinActionCreator({ ...lockersWithin.customPin, showOnMap: false }))
		} else if (lockersWithin.flyTo) {
			mapRef.current?.flyTo({
				center: lockersWithin.flyTo,
				duration: 2500,
			})
			dispatch(removeFlyToActionCreator())
		}
	}, [showLockerOnMap, lockersWithin, dispatch])

	useEffect(() => {
		const geoJson: FeatureCollection = {
			type: "FeatureCollection",
			features: [],
		}
		if (!useSelectFilteredLockers) {
			return
		}

		for (let i = 0; i < useSelectFilteredLockers.length; i += 1) {
			const locker = useSelectFilteredLockers[i]

			const color = locker.enabled ? "#41CDA5" : "#BD666A"

			const feature: TFeature = {
				type: "Feature",
				properties: {
					id: locker.id,
					identifier: locker.identifier,
					name: locker.name,
					demand: locker.demand,
					enabled: locker.enabled,
					color,
					lat: locker.latitude,
					lng: locker.longitude,
				},
				geometry: { type: "Point", coordinates: [locker.longitude, locker.latitude] },
			}
			geoJson.features.push(feature)
		}

		setLockersGeoJson(geoJson)
	}, [useSelectFilteredLockers])

	const handleMapViewChange = useCallback(() => {
		if (!mapRef.current) {
			return
		}
		const map = mapRef.current.getMap()

		const center = map.getCenter()
		const east = map.getBounds().getEast()

		const centerLng = parseFloat(center.lng.toFixed(6))
		const centerLat = parseFloat(center.lat.toFixed(6))

		const boundLng = parseFloat(east.toFixed(6))
		const boundLat = centerLat

		const lockersWithinSafe = lockersWithin || {
			radius: 10000,
			coordinates: { lat: 59.336057, lng: 18.02866 },
		}

		const { radius: prevBoundToCenterDistance, coordinates: prevCoordinates } =
			lockersWithinSafe
		const { lat: prevLag, lng: prevLng } = prevCoordinates

		const fromBound = turfPoint([boundLat, boundLng])
		const fromPrevCenter = turfPoint([prevLag, prevLng])
		const toCenter = turfPoint([centerLat, centerLng])

		const prevCenterToNewCenterDistance = Math.round(
			turfDistance(fromPrevCenter, toCenter, {
				units: "meters",
			})
		)
		const boundToCenterDistance = Math.round(
			turfDistance(fromBound, toCenter, { units: "meters" })
		)

		// If we zoom out more than 499m we will fetch for more lockers.
		const hasZoomedOut = boundToCenterDistance - prevBoundToCenterDistance >= 500
		// If we move the map more than 499m we will fetch for more lockers.
		const hasMovedMap = prevCenterToNewCenterDistance >= 500

		if (hasZoomedOut || hasMovedMap) {
			dispatch(
				lockersWithinActionCreator({
					id: uuid(),
					radius: boundToCenterDistance < 1500 ? 1500 : boundToCenterDistance,
					coordinates: { lat: centerLat, lng: centerLng },
					customPin: null,
					flyTo: null,
				})
			)
		}
	}, [dispatch, lockersWithin])

	const debounceMapViewChange = _.debounce(
		() => {
			handleMapViewChange()
			setCursor("auto")
		},
		350,
		{ maxWait: 2500 }
	)

	const handleClusterZoom = (feature: MapboxGeoJSONFeature) => {
		const clusterId = feature.properties?.cluster_id

		const mapboxSource = mapRef.current?.getMap().getSource("lockers") as GeoJSONSource

		if (!mapboxSource) {
			return
		}

		mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
			if (err) {
				return
			}

			const geo = feature.geometry as Point

			mapRef.current?.easeTo({
				center: geo.coordinates as LngLatLike,
				zoom,
				duration: 1500,
			})
		})
	}

	const handleClickedLocker = (feature: MapboxGeoJSONFeature) => {
		const clickedLocker = useSelectFilteredLockers?.find(
			(locker) => locker.identifier === feature.properties?.identifier
		)

		if (!clickedLocker) {
			return
		}
		const url = new URL(window.location.toString())
		url.searchParams.set("locker", clickedLocker.identifier)
		window.history.pushState({}, "", url)
		dispatch(selectLockerActionCreator({ identifier: clickedLocker.identifier }))
	}

	const handleOnClick = (event: MapLayerMouseEvent) => {
		const feature = event.features && event.features[0]

		if (!feature) {
			return
		}

		if (feature.layer.id === unclusteredPointLayer.id) {
			handleClickedLocker(feature)
		}
		if (feature.layer.id === clusterLayer.id) {
			handleClusterZoom(feature)
		}
	}

	const handleOnMouseEnter = useCallback((event: MapLayerMouseEvent) => {
		setCursor("pointer")
		const locker = event.features && event.features[0]

		if (locker?.layer?.id === unclusteredPointLayer.id) {
			return setHoverInfo({
				longitude: locker.properties?.lng,
				latitude: locker.properties?.lat,
				identifier: locker && locker.properties?.identifier,
				enabled: locker && locker.properties?.enabled,
				name: locker && locker.properties?.name,
				demand: locker && locker.properties?.demand,
			})
		}
	}, [])
	const handleOnMouseLeave = useCallback(() => {
		setCursor("auto")
		return setHoverInfo(null)
	}, [])
	const handleOnMove = useCallback(() => setCursor("grab"), [])

	const selectedHoverLocker = () => {
		const { identifier, name, demand, enabled } = hoverInfo || {}
		if (!(hoverInfo && identifier && name)) {
			return null
		}
		return (
			<PopupContent identifier={identifier} demand={demand} name={name} enabled={enabled} />
		)
	}

	return (
		<ReactMapGL
			reuseMaps
			initialViewState={{
				latitude: 59.336057,
				longitude: 18.02866,
				zoom: 12,
				bearing: 0,
				pitch: 0,
			}}
			style={{
				position: "absolute",
				left: isLargeScreen ? "440px" : "360px",
				width: isLargeScreen ? "calc(100% - 440px)" : "calc(100% - 360px)",
			}}
			mapboxAccessToken={window.config.REACT_APP_MAPBOX_ACCESS_TOKEN || ""}
			mapStyle="mapbox://styles/budbee/ckw4sk9iv65s714o5ye4vgwt8?optimize=true"
			onClick={handleOnClick}
			onMoveEnd={debounceMapViewChange}
			onZoomEnd={debounceMapViewChange}
			onMove={handleOnMove}
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			cursor={cursor}
			ref={mapRef}
			interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
		>
			{lockersWithin.customPin ? (
				<Marker
					longitude={lockersWithin.customPin.lng}
					latitude={lockersWithin.customPin.lat}
					anchor="bottom"
				>
					<img src={mapPin} alt="map pin" width="40px" />
				</Marker>
			) : null}
			<Source
				id="lockers"
				type="geojson"
				data={lockersGeoJson}
				cluster
				clusterMaxZoom={4}
				clusterRadius={25}
			>
				<Layer {...clusterLayer} />
				<Layer {...clusterCountLayer} />
				<Layer {...unclusteredPointLayer} />
			</Source>
			{selectedHoverLocker() && (
				<StyledPopup
					longitude={hoverInfo.longitude}
					latitude={hoverInfo.latitude}
					offset={[0, -10]}
					closeButton={false}
					style={{ maxWidth: "500px" }}
				>
					{selectedHoverLocker()}
				</StyledPopup>
			)}
			<MapViewOverlay />
		</ReactMapGL>
	)
}

export default ClusterMapView
