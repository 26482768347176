import React, { ReactElement, useMemo } from "react"
import { useSelector } from "react-redux"

import { components, MultiValueGenericProps, OptionProps } from "react-select"
import { selectActiveSelectionFiltersByType } from "../../../store/selectors"
import { IGlobalState, ISelectOption } from "../../../types/global"
import { StyledBadge, StyledSelect, StyledSelectElement, StyledText } from "./SelectInput.styles"

type Props = {
	type: string
	options: ISelectOption[]
	handleSelectFilter: (e: ISelectOption[], string: string) => void
}

const SelectInput = ({ options, type, handleSelectFilter }: Props): ReactElement => {
	const selectActiveSelectFilters = useMemo(selectActiveSelectionFiltersByType, [])

	const activeSelectFilters = useSelector((state: IGlobalState) =>
		selectActiveSelectFilters(state, type)
	)

	const Option = ({ children, ...props }: OptionProps) => {
		const { selectProps, data } = props
		if (selectProps.name === "region") {
			const { countryCode, value, name } = data as ISelectOption
			return (
				<components.Option {...props}>
					<StyledSelectElement>
						<StyledBadge>{countryCode}</StyledBadge>
						<StyledBadge>{value}</StyledBadge>
						<StyledText>{name}</StyledText>
					</StyledSelectElement>
				</components.Option>
			)
		}
		return (
			<components.Option {...props}>
				<StyledText>{children}</StyledText>
			</components.Option>
		)
	}

	const MultiValueLabel = ({ children, ...props }: MultiValueGenericProps) => {
		const { data, selectProps } = props

		if (selectProps.name === "region") {
			const { countryCode, name, value } = data
			return (
				<components.MultiValueLabel {...props}>
					<StyledSelectElement>
						<StyledBadge>{countryCode}</StyledBadge>
						<StyledBadge>{value}</StyledBadge>
						<StyledText>{name}</StyledText>
					</StyledSelectElement>
				</components.MultiValueLabel>
			)
		}

		return (
			<components.MultiValueLabel {...props}>
				<StyledText>{children}</StyledText>
			</components.MultiValueLabel>
		)
	}

	return (
		<StyledSelect
			onChange={(e) => handleSelectFilter(e as ISelectOption[], type)}
			isMulti
			placeholder={type}
			value={activeSelectFilters}
			name={type}
			options={options}
			classNamePrefix="react-select"
			closeMenuOnSelect={false}
			components={{ MultiValueLabel, Option }}
		/>
	)
}

export default SelectInput
