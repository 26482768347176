import { ButtonVariants } from "@budbee-monorepo/ui"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	hideGlobalModalActionCreator,
	showConfirmationGlobalModalActionCreator,
	showNumberInputGlobalModalActionCreator,
	showStringInputGlobalModalActionCreator,
	showDropdownInputGlobalModalActionCreator,
} from "../../store/slices"
import { IGlobalModalActions, IGlobalState, IOption } from "../../types/global"

interface IShowConfirmationParams {
	title: string
	text: string
	actions: IGlobalModalActions[]
}
interface IShowNumberInputParams {
	title: string
	text: string | null
	label: string
	placeholder: string
	isValid: (value: number) => boolean
	onSubmit: (value: number) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants
}
interface IShowStringInputParams {
	title: string
	text: string | null
	label: string
	placeholder: string
	isValid: (value: string) => boolean
	onSubmit: (value: string) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants
}
interface IShowDropdownInputParams {
	title: string
	text: string | null
	label: string
	placeholder: string
	options: IOption<string | null>[]
	selectedOption: IOption<string | null>
	isValid: (value: IOption<string | null>) => boolean
	onSubmit: (value: IOption<string | null>) => void
	submitButtonText: string
	submitButtonVariant: ButtonVariants
}
export const useGlobalModal = () => {
	const dispatch = useDispatch()
	return {
		showConfirmation: useCallback(
			(params: IShowConfirmationParams) => {
				dispatch(showConfirmationGlobalModalActionCreator(params))
			},
			[dispatch]
		),
		showNumberInput: useCallback(
			(params: IShowNumberInputParams) => {
				dispatch(showNumberInputGlobalModalActionCreator(params))
			},
			[dispatch]
		),
		showStringInput: useCallback(
			(params: IShowStringInputParams) => {
				dispatch(showStringInputGlobalModalActionCreator(params))
			},
			[dispatch]
		),
		showDropdownInput: useCallback(
			(params: IShowDropdownInputParams) => {
				dispatch(showDropdownInputGlobalModalActionCreator(params))
			},
			[dispatch]
		),
		hide: useCallback(() => {
			dispatch(hideGlobalModalActionCreator())
		}, [dispatch]),
		state: useSelector((state: IGlobalState) => state.globalModal),
	}
}
