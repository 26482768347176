import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ILocker } from "../../types/lockers"

export const selectedLockerSlice = createSlice({
	name: "selectedLocker",
	initialState: null as string | null,
	reducers: {
		select: (draftState, { payload }: PayloadAction<{ identifier: string }>) =>
			payload.identifier,
		remove: () => null,
	},
})

export const showLockerOnMapSlice = createSlice({
	name: "showLockerOnMap",
	initialState: null as ILocker | null,
	reducers: {
		set: (draftState, { payload }: PayloadAction<{ locker: ILocker }>) => payload.locker,
		remove: () => null,
	},
})

export const { select: selectLockerActionCreator, remove: removeLockerActionCreator } =
	selectedLockerSlice.actions

export const { set: setShowLockerOnMapActionCreator, remove: removeShowLockerOnMapActionCreator } =
	showLockerOnMapSlice.actions
