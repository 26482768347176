
function hasOwnProperty<X extends {[k: string | number | symbol]: any}, Y extends PropertyKey>
    (obj: X, prop: Y): obj is X & Record<Y, unknown> {

    return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function sortArrayByParameter<T>(objectArray:T[], property: string): {[key: string]: T[]}{
    const returnObject:{ [key: string]: T[]; } = {}
    objectArray.forEach((val) => {
        if(hasOwnProperty(val, property)){
            if(returnObject[val[property] as string] === undefined){
                returnObject[val[property] as string] = [val]
            } else{
                returnObject[val[property] as string].push(val)
            }
        }
        
    })
    return returnObject;
}

export function splitArray<T>(array: T[], condition: (val: T) => boolean):[T[],T[]]{
    return array.reduce<[T[],T[]]>((previous, current) => {
        if(condition(current)){
          return [[...previous[0], current], previous[1]]  
        } 
        return [previous[0], [ ...previous[1],current]]  
        
    },[[],[]])
}

    