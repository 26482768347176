import React from "react"
import { IoMdKeypad } from "react-icons/io"
import styled from "styled-components"
import tw from "twin.macro"
import { IBox } from "../../types/lockers"
import { BoxDimOverlay } from "./LockerBox.styles"

export const StyledScreenContainer = styled.div<{ v2: boolean; editMode?: boolean }>`
	${tw`flex flex-1 justify-center items-center border-b border-r box-border border-bb-petrol-600 text-black bg-bb-petrol-600 flex-grow relative`};
	${(props) => {
		if (props.v2 && props.editMode) {
			return tw`min-w-[40px] flex-grow-[9]`
		}
		if (props.v2) {
			return tw`min-w-[80px] flex-grow-[9]`
		}
		return tw`min-w-[120px] flex-grow-[5]`
	}}
	${(props) => {
		if (props.editMode) {
			return tw`bg-bb-petrol-800`
		}
	}}
`

export const StyledScreen = styled.div`
	${tw`flex items-center justify-center bg-bb-green-500 w-2/4 h-2/3`};
`

export const StyledKeypad = styled(IoMdKeypad)`
	${tw`text-gray-200 h-1/3 w-auto`};
`

type Props = {
	hasSelectedBox: boolean
	v2: boolean
}

const LockerScreen = ({ hasSelectedBox, v2 }: Props) => {
	return (
		<StyledScreenContainer v2>
			{hasSelectedBox && <BoxDimOverlay />}
			<StyledScreen>
				<StyledKeypad />
			</StyledScreen>
		</StyledScreenContainer>
	)
}

export default LockerScreen
