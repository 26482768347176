import styled from "styled-components"
import tw from "twin.macro"

export const Wrapper = styled.div`
	${tw`flex items-center bg-bb-white/5 border border-bb-white/20 rounded-lg m-3 overflow-hidden box-border`}
`
export const Tab = styled.div`
	${tw`text-bb-white text-center px-0 py-2 cursor-pointer text-sm flex-grow`}
`
export const ActiveTab = styled(Tab)`
	${tw`bg-bb-green-500/10 border-b-2 border-bb-green-500 pt-2 pb-1.5`}
`
