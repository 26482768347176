import React from "react"
import { v1 as uuid } from "uuid"
import { ICompartmentBySize } from "../../types/lockers"
import boxSizeFormatter from "../../utils/boxSizeFormatter"

type Props = {
	parcelSizes: ICompartmentBySize
}

const InboundParcels = ({ parcelSizes }: Props) => {
	return (
		<div className="flex flex-row justify-start">
			{Object.keys(parcelSizes).map((keyName) => (
				<div
					key={uuid()}
					className="flex flex-row rounded-full w-14 h-6 border border-bb-petrol-600 content-center items-center justify-between mr-1"
				>
					<div className="flex items-center justify-center text-[10px] text-white rounded-full w-6 h-6 bg-bb-petrol-600">
						{boxSizeFormatter(keyName)}
					</div>
					<div className="mr-[4px] text-[10px] text-black">
						{parcelSizes[keyName as keyof ICompartmentBySize]}
					</div>
				</div>
			))}
		</div>
	)
}

export default InboundParcels
