import { ButtonVariants } from "@budbee-monorepo/ui"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	IGlobalModalActions,
	IGlobalModalConfirmation,
	IGlobalModal,
	IGlobalModalNumberInput,
	IGlobalModalStringInput,
	IGlobalModalDropdownInput,
	IOption,
} from "../../types/global"

const initialState:
	| IGlobalModal
	| IGlobalModalConfirmation
	| IGlobalModalNumberInput
	| IGlobalModalStringInput
	| IGlobalModalDropdownInput = {
	type: null,
	show: false,
	title: null,
	text: null,
}

export const globalModalSlice = createSlice({
	name: "globalModal",
	initialState,
	reducers: {
		showConfirmation: (
			draftState,
			{
				payload,
			}: PayloadAction<{ title: string; text: string; actions: IGlobalModalActions[] }>
		) => ({
			type: "CONFIRMATION",
			show: true,
			title: payload.title,
			text: payload.text,
			actions: payload.actions,
		}),
		showNumberInput: (
			draftState,
			{
				payload,
			}: PayloadAction<{
				title: string
				text: string | null
				label: string
				placeholder: string
				isValid: (value: number) => boolean
				onSubmit: (value: number) => void
				submitButtonText: string
				submitButtonVariant: ButtonVariants
			}>
		) => ({
			type: "NUMBER_INPUT",
			show: true,
			title: payload.title,
			text: payload.text,
			label: payload.label,
			placeholder: payload.placeholder,
			isValid: payload.isValid,
			onSubmit: payload.onSubmit,
			submitButtonText: payload.submitButtonText,
			submitButtonVariant: payload.submitButtonVariant,
		}),
		showStringInput: (
			draftState,
			{
				payload,
			}: PayloadAction<{
				title: string
				text: string | null
				label: string
				placeholder: string
				isValid: (value: string) => boolean
				onSubmit: (value: string) => void
				submitButtonText: string
				submitButtonVariant: ButtonVariants
			}>
		) => ({
			type: "STRING_INPUT",
			show: true,
			title: payload.title,
			text: payload.text,
			label: payload.label,
			placeholder: payload.placeholder,
			isValid: payload.isValid,
			onSubmit: payload.onSubmit,
			submitButtonText: payload.submitButtonText,
			submitButtonVariant: payload.submitButtonVariant,
		}),
		showDropdownInput: (
			draftState,
			{
				payload,
			}: PayloadAction<{
				title: string
				text: string | null
				label: string
				placeholder: string
				options: IOption<string | null>[]
				selectedOption: IOption<string | null>
				isValid: (value: IOption<string | null>) => boolean
				onSubmit: (value: IOption<string | null>) => void
				submitButtonText: string
				submitButtonVariant: ButtonVariants
			}>
		) => ({
			type: "DROPDOWN_INPUT",
			show: true,
			title: payload.title,
			text: payload.text,
			label: payload.label,
			placeholder: payload.placeholder,
			options: payload.options,
			selectedOption: payload.selectedOption,
			isValid: payload.isValid,
			onSubmit: payload.onSubmit,
			submitButtonText: payload.submitButtonText,
			submitButtonVariant: payload.submitButtonVariant,
		}),
		hide: (draftState) => ({
			type: null,
			show: false,
			title: null,
			text: null,
		}),
	},
})

export const {
	showConfirmation: showConfirmationGlobalModalActionCreator,
	hide: hideGlobalModalActionCreator,
	showNumberInput: showNumberInputGlobalModalActionCreator,
	showStringInput: showStringInputGlobalModalActionCreator,
	showDropdownInput: showDropdownInputGlobalModalActionCreator,
} = globalModalSlice.actions
