import React from "react"
import { SpinnerDot } from "./Spinner.styles"

type Props = {
	size: string
	color: string
}
export const Spinner = ({ size, color }: Props) => {
	return <SpinnerDot size={size} color={color} />
}

Spinner.defaultProps = {
	size: "30px",
	color: "#41CDA5",
}
