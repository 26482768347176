import styled from "styled-components"
import tw from "twin.macro"
import { IoMdClose } from "react-icons/io"

export const BodyWrapper = styled.div`
	${tw`px-4 pt-6`}
`
export const ButtonWrapper = styled.div`
	${tw`grid grid-cols-2 gap-2 my-2`}
`
export const StyledButton = styled.button<{ displayDisabled?: boolean }>`
	${tw`h-10 px-4 py-2 bg-bb-white text-black text-base rounded-lg font-medium `}
	${(props) => {
		if (props.displayDisabled || props.disabled) {
			return tw`bg-bb-gray-600 cursor-not-allowed`
		}
	}}
`
export const TopHandle = styled.div`
	${tw``}
`
export const StyledCloseButton = styled(IoMdClose)`
	${tw`text-2xl text-white absolute top-1 right-2 cursor-pointer`}
`

export const StyledBadge = styled.div`
	${tw`flex flex-row text-xs text-gray-100 py-1 px-2 bg-bb-petrol-500 rounded`}
`

export const StyledStatus = styled.div<{ status: string; type: string }>`
	${tw`flex text-xs text-gray-100 py-1 px-2 bg-bb-petrol-500 rounded flex-shrink`};
	${({ status, type }) => {
		switch (status) {
			case "AVAILABLE":
				return tw`bg-white text-gray-800`
			case "PRESENT":
				if (type === "RETURN") {
					return tw`bg-bb-warn text-gray-800`
				}
				return tw`bg-bb-green-500 text-gray-800`
			case "RESERVED":
				return tw`bg-gray-300 text-gray-800`
			case "ERROR":
				return tw`bg-bb-red-500`
			default:
				return tw`bg-blue-200 text-gray-800`
		}
	}};
`
