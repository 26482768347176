import { IoMdClose } from "react-icons/io"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledBody = styled.div`
	${tw`text-black px-4 bg-white h-full rounded-b-lg overflow-y-scroll`};

	&::-webkit-scrollbar {
		${tw`w-[16px]`};
	}

	&::-webkit-scrollbar-thumb {
		background-clip: content-box;
		border: 5px solid transparent;
		box-shadow: inset 0 0 0 10px rgba(7, 34, 33, 0.25);
		min-height: 50px;
		${tw`rounded-full`};
	}

	&::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
`

export const StyledDemandIndicator = styled.img`
	${tw`h-3 ml-4 w-6`}
`

export const StyledDetailContainer = styled.div`
	${tw`p-4 border-b`}
`

export const StyledDetailContent = styled.div`
	${tw`flex flex-row items-center justify-between items-end max-w-full`}
`

export const StyledDetailContentCol = styled.div`
	${tw`flex flex-col`}
`
export const StyledDetailContentColRightAlignedItems = styled(StyledDetailContentCol)`
	${tw`items-end`}
`

export const StyledDetailLabel = styled.div`
	${tw`font-semibold mb-2`}
`
export const StyledDetailLabelInline = styled.div`
	${tw`font-semibold`}
`
export const StyledDetailButton = styled.button`
	${tw`flex font-medium flex-shrink-0 text-bb-petrol-500 cursor-pointer mt-2 justify-end`}
`
export const StyledDetailMinorLabel = styled.div`
	${tw`font-medium`}
`
export const StyledDetailTextBlock = styled.p`
	${tw`mb-2`}
`
export const StyledDetailLink = styled.a`
	${tw`text-bb-petrol-500`}
`
export const OpeningHoursList = styled.ul`
	${tw`list-none `}
`
export const OpeningHoursListItem = styled.li`
	${tw`mb-1`}
`
export const OpeningHoursDay = styled.span`
	${tw`inline-block w-16 capitalize font-medium`}
`
export const OpeningHoursTime = styled.span`
	${tw`font-normal`}
`
export const StyledDetailClosableSelectWrapper = styled.div`
	${tw`flex items-center w-full py-4`}
`
export const StyledInlineCloseButton = styled(IoMdClose)`
	${tw`text-xl text-gray-400 ml-2`}
`
export const StyledCommentsNotification = styled.span`
	${tw`rounded-full bg-bb-red-500 text-bb-white mx-2 w-6 h-6`}
`
