import { Popup } from "react-map-gl"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledPopup = styled(Popup)`
	& > .mapboxgl-popup-content {
		${tw`p-2 h-12`}
	}
`

export const StyledBadge = styled.div`
	${tw`text-xs text-gray-100 py-1 px-2 bg-bb-petrol-500 rounded`}
`
export const StyledLockerName = styled.div`
	${tw`text-xs ml-2 text-gray-600 truncate`}
`
