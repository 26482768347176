import styled from "styled-components"
import tw from "twin.macro"

export const StyledLockersSidebarHeader = styled.div`
	${tw`flex flex-row w-[360px] 2xl:w-[440px] justify-between items-end p-4 bg-bb-petrol-700 fixed top-0 left-0 h-20`};
`

export const StyledLogo = styled.img`
	${tw`max-h-12 w-auto `};
`

export const StyledText = styled.div`
	${tw`text-bb-gray-400 text-4xl 2xl:text-3xl 2xl:leading-none leading-none align-bottom`};
`
