import React, { ReactChild } from "react"
import {
	StyledDangerButton,
	StyledPrimaryButton,
	StyledSecondaryButton,
	StyledTertiaryButton,
	StyledTransparentButton,
	StyledTransparentDangerButton,
} from "./Button.styles"

export enum ButtonVariants {
	PRIMARY = "primary",
	SECONDARY = "secondary",
	TERTIARY = "tertiary",
	TRANSPARENT = "transparent",
	DANGER = "danger",
	TRANSPARENT_DANGER = "transparentDanger",
}
export enum ButtonSizes {
	SMALL = "small",
	NORMAL = "normal",
	LARGE = "large",
}

export type ButtonProps = {
	variant: ButtonVariants
	size: ButtonSizes
	darkBackground: boolean
	disabled: boolean
	onClick: () => void
	children: ReactChild
}
export const Button = ({
	variant,
	size,
	darkBackground,
	disabled,
	onClick,
	children,
}: ButtonProps) => {
	const Primary = () => (
		<StyledPrimaryButton
			darkBackground={darkBackground}
			size={size}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</StyledPrimaryButton>
	)
	const Secondary = () => (
		<StyledSecondaryButton
			darkBackground={darkBackground}
			size={size}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</StyledSecondaryButton>
	)
	const Tertiary = () => (
		<StyledTertiaryButton
			darkBackground={darkBackground}
			size={size}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</StyledTertiaryButton>
	)
	const Transparent = () => (
		<StyledTransparentButton
			darkBackground={darkBackground}
			size={size}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</StyledTransparentButton>
	)
	const Danger = () => (
		<StyledDangerButton
			darkBackground={darkBackground}
			size={size}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</StyledDangerButton>
	)
	const TransparentDanger = () => (
		<StyledTransparentDangerButton
			darkBackground={darkBackground}
			size={size}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</StyledTransparentDangerButton>
	)
	switch (variant) {
		case ButtonVariants.PRIMARY:
			return Primary()
		case ButtonVariants.SECONDARY:
			return Secondary()
		case ButtonVariants.TERTIARY:
			return Tertiary()
		case ButtonVariants.TRANSPARENT:
			return Transparent()
		case ButtonVariants.DANGER:
			return Danger()
		case ButtonVariants.TRANSPARENT_DANGER:
			return TransparentDanger()
		default:
			return Primary()
	}
}
Button.defaultProps = {
	variant: "primary",
	size: "normal",
	darkBackground: false,
	disabled: false,
	children: null,
}
