import React from "react"
import { IoIosArrowForward } from "react-icons/io"
import styled from "styled-components"
import tw from "twin.macro"

export const StyledSidebarLocation = styled.div<{ isSelected: boolean; hasSelected: boolean }>`
	${tw`flex z-40 flex-row items-center w-[328px] 2xl:w-[408px] flex-grow bg-bb-white h-12 rounded-md mb-2 p-2 cursor-pointer`};
	${(props) => {
		if (props.isSelected) {
			return ""
		}

		return props.hasSelected ? tw`bg-bb-petrol-300` : ""
	}}
`

export const StyledEnabledStatus = styled.div(({ enabled = false }: { enabled?: boolean }) => {
	return [
		tw`w-1.5 h-full flex-shrink-0 rounded-md`,
		enabled ? tw`bg-bb-green-500` : tw`bg-bb-red-500`,
	]
})

export const StyledDemandIndicator = styled.img`
	${tw`h-3 ml-4`}
`

export const StyledLabel = styled.div`
	${tw`ml-4 leading-relaxed text-sm font-medium text-black`};
`
export const StyledLockerName = styled.div`
	${tw`mx-2 flex-grow font-semibold truncate text-sm text-gray-500`};
`

export const StyledArrowWrapper = styled.div`
	${tw`flex w-6 h-8 flex-shrink-0 justify-center items-center`};
`

export const StyledArrow = styled(IoIosArrowForward)`
	${tw`text-black text-3xl text-center min-h-full`};
`
