import { BoxSize, BoxSizeFriendly, BoxSizeV2 } from "../types/global"

export default (size: string) => {
	switch (size) {
		case BoxSizeFriendly.XL:
		case BoxSize.XL:
			return "XL"
		case BoxSizeFriendly.L:
		case BoxSize.L:
			return "L"
		case BoxSizeFriendly.M:
		case BoxSize.M:
			return "M"
		case BoxSizeFriendly.S:
		case BoxSize.S:
			return "S"
		case BoxSizeFriendly.XS:
		case BoxSize.XS:
			return "XS"
		case BoxSizeV2.WIDE_XL:
			return "XL"
		case BoxSizeV2.WIDE_L:
			return "XL"
		case BoxSizeV2.WIDE_M:
			return "L"
		case BoxSizeV2.WIDE_S:
			return "L"
		case BoxSizeV2.WIDE_XS:
			return "M"
		case BoxSizeV2.REGULAR_L:
			return "L"
		case BoxSizeV2.REGULAR_M:
			return "S"
		case BoxSizeV2.REGULAR_S:
			return "S"
		case BoxSizeV2.REGULAR_XS:
			return "XS"
		case BoxSizeV2.NARROW_L:
			return "S"
		case BoxSizeV2.NARROW_M:
			return "XS"
		case BoxSizeV2.NARROW_S:
			return "XXS"
		default:
			return "N/A"
	}
}
