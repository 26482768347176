const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const timeAndDate = (date: Date) => {
    const year = date.getFullYear();
    const month = monthsShort[date.getMonth()];
    const day = date.getDate() < 10? `0${date.getDate()}`: date.getDate();
    const hours = date.getHours()< 10? `0${date.getHours()}`: date.getHours();
    const minutes = date.getMinutes()< 10? `0${date.getMinutes()}`: date.getMinutes();
    return`${hours}:${minutes}, ${day} ${month} ${year}`
}
export const stringToNum = (num: string): number => {
	return Number(num)
}
export const clockNumberToDisplayableString = (num: number): string => {
	if(num < 10){
        return `0${num}`
    }
    return `${num}`
}