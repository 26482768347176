import { skipToken } from "@reduxjs/toolkit/dist/query"
import React, { useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useGetSpecificLockerQuery } from "../../services/lockers"
import { removeLockerActionCreator } from "../../store/slices"
import { useAppSelector } from "../../store/utils"
import { IBox, LockerDemand } from "../../types/lockers"

import {
	StyledButton,
	StyledCloseButton,
	StyledEnabledStatus,
	StyledOnlineStatus,
	StyledHeader,
	StyledHeaderIdRow,
	StyledHeaderSearchRow,
	StyledIdContainer,
	StyledMdSearch,
	StyledNameContainer,
	StyledRoot,
	StyledSearchContainer,
	StyledSearchInput,
	StyledUnknowOnlineStatus,
	StyledPopup,
} from "./LockerViewDetails.styles"
import LockerViewDetailsBody from "./LockerViewDetailsBody"
import LockerViewDetailsBodyEditable from "./LockerViewDetailsBodyEditable"
import MultipleSelectedBoxView from "./MultipleSelectedBoxView"
import SelectedBoxView from "./SelectedBoxView"
import { useGetDeviceInfoQuery } from "../../services/deviceAccess"
import { useDeviceAccessAuthorities } from "../../utils/hooks/useAuthorities"

type Props = {
	selectedBoxes: IBox[]
	clearSelectedBox: () => void
	editEnabled: boolean
	setEditEnabled: (value: boolean) => void
}

const LockerViewDetails = ({
	selectedBoxes,
	clearSelectedBox,
	editEnabled,
	setEditEnabled,
}: Props) => {
	const dispatch = useDispatch()
	const selectedLocker = useAppSelector((state) => state.lockers.selectedLocker)
	const { data: specificLocker } = useGetSpecificLockerQuery(selectedLocker || skipToken)
	const { canViewLockers } = useDeviceAccessAuthorities()
	const { injectedParcelsInDistributionTerminal: ipidt, parcelsOnTheWay } = specificLocker || {}
	const { data: deviceInfoData, isLoading: deviceInfoLoading } = useGetDeviceInfoQuery(
		canViewLockers ? selectedLocker || skipToken : skipToken,
		{
			pollingInterval: 20000,
		}
	)
	const handleOnClick = () => {
		dispatch(removeLockerActionCreator())
		const url = new URL(window.location.toString())
		url.searchParams.delete("locker")
		window.history.pushState({}, "", url)
		clearSelectedBox()
	}
	if (!specificLocker || !specificLocker.locker) {
		return (
			<StyledRoot>
				<AiOutlineLoading3Quarters className="text-6xl text-gray-400 animate-spin self-center my-6" />
			</StyledRoot>
		)
	}
	return (
		<StyledRoot>
			<StyledHeader>
				<StyledHeaderIdRow>
					<StyledEnabledStatus enabled={specificLocker.locker.enabled} />
					<StyledIdContainer>{specificLocker.locker.identifier}</StyledIdContainer>
					<div hidden={!canViewLockers}>
						{deviceInfoData && deviceInfoData.online !== null ? (
							<StyledPopup text={deviceInfoData.online ? "Online" : "Offline"}>
								<StyledOnlineStatus
									loading={deviceInfoLoading}
									online={deviceInfoData.online}
								/>
							</StyledPopup>
						) : (
							<StyledPopup text="Unknown status">
								<StyledUnknowOnlineStatus />
							</StyledPopup>
						)}
					</div>
					<StyledNameContainer>{specificLocker.locker.name}</StyledNameContainer>
					<StyledCloseButton onClick={handleOnClick} />
				</StyledHeaderIdRow>
				<StyledHeaderSearchRow>
					<StyledSearchContainer>
						<StyledMdSearch />
						<StyledSearchInput type="text" />
					</StyledSearchContainer>

					<StyledButton onClick={() => setEditEnabled(!editEnabled)}>
						Edit locker
					</StyledButton>
				</StyledHeaderSearchRow>
			</StyledHeader>
			{editEnabled ? (
				<LockerViewDetailsBodyEditable
					selectedLocker={specificLocker.locker}
					cancelEditing={() => setEditEnabled(false)}
				/>
			) : (
				<LockerViewDetailsBody
					selectedLocker={specificLocker.locker}
					ipidt={ipidt}
					parcelsOnTheWay={parcelsOnTheWay}
					editEnabled={editEnabled}
				/>
			)}
			{selectedBoxes.length === 1 && (
				<SelectedBoxView
					lockerIdentifier={specificLocker.locker.identifier}
					selectedBox={selectedBoxes[0]}
					clearSelectedBox={clearSelectedBox}
				/>
			)}
			{selectedBoxes.length > 1 && (
				<MultipleSelectedBoxView
					selectedBoxes={selectedBoxes}
					clearSelectedBoxes={clearSelectedBox}
				/>
			)}
		</StyledRoot>
	)
}

export default LockerViewDetails
