import { useAuth0 } from "@auth0/auth0-react"
import React from "react"
import budbeeLogo from "../../assets/budbeegreen.png"
import {
	BudbeeLogo,
	StyledBumbleBox,
	StyledButton,
	StyledCard,
	StyledCardWrapper,
	StyledContent,
	StyledContentContainer,
	StyledInfo,
	StyledLoadingIcon,
	StyledLoadingWrapper,
	StyledLogoWrapper,
	StyledPageContainer,
	StyledReTriggerButton,
} from "./LoginPage.styles"

const LoginPage = () => {
	const { loginWithRedirect, isLoading } = useAuth0()

	return (
		<StyledPageContainer>
			<div className="absolute bg-gradient-to-br from-cyan-400 to-bb-green-500 inset-0 z-0" />
			<StyledContentContainer>
				<StyledCardWrapper>
					<StyledCard>
						<StyledLogoWrapper>
							<BudbeeLogo src={budbeeLogo} alt="Budbee logo" />
							<StyledBumbleBox>Bumblebox</StyledBumbleBox>
						</StyledLogoWrapper>
						{isLoading ? (
							<>
								<StyledLoadingWrapper>
									<p>Authenticating...</p>
									<StyledLoadingIcon />
								</StyledLoadingWrapper>
								<StyledContent>
									<StyledInfo>
										If it doesn&apos;t redirect you please click the button
										below.
									</StyledInfo>

									<StyledReTriggerButton
										type="submit"
										onClick={() =>
											loginWithRedirect({
												hello: "hej",
											})
										}
									>
										Re-trigger log in
									</StyledReTriggerButton>
								</StyledContent>
							</>
						) : (
							<StyledContent>
								<StyledInfo>Please click the button below to sign in.</StyledInfo>

								<StyledButton
									type="submit"
									onClick={() =>
										loginWithRedirect({
											hello: "hej",
										})
									}
								>
									Log in
								</StyledButton>
							</StyledContent>
						)}
					</StyledCard>
				</StyledCardWrapper>
			</StyledContentContainer>
		</StyledPageContainer>
	)
}

export default LoginPage
