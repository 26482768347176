import styled from "styled-components"
import tw from "twin.macro"

interface AddCommentProps {
	readonly isOpen: boolean
}

export const OuterWrapper = styled.div`
	${tw`h-[calc(100% - 62px)]`}
`
export const Wrapper = styled.div<AddCommentProps>`
	${tw`relative px-4 py-5 overflow-y-scroll`}
	${({ isOpen }) => {
		if (isOpen) {
			return tw`h-[calc(100% - 288px)]`
		}
		return tw`h-full`
	}}
`
export const ButtonWrapper = styled.div`
	${tw`flex items-center justify-end w-full border-b-2 border-bb-petrol-500 py-2 px-0`}
`
export const OpenAddCommentButton = styled.button`
	${tw`text-sm font-semibold text-bb-green-500`}
`
export const Comment = styled.div`
	${tw`flex px-3 py-2 justify-between items-start border-b-2 border-bb-petrol-500 gap-4`}
`
export const CommentInner = styled.div`
	${tw`flex-grow`}
`
export const CommentTitle = styled.h3`
	${tw`text-base text-bb-white font-semibold`}
`
export const CommentText = styled.p`
	${tw`text-sm text-bb-white font-light`}
`
export const CommentBottomContent = styled.div`
	${tw`flex justify-between px-0 py-3`}
`
export const CommentTimestamp = styled.span`
	${tw`text-xs text-bb-white font-light`}
`
export const CommentButtonWrapper = styled.div`
	${tw`flex gap-4`}
`
export const CommentEditButton = styled.button`
	${tw`text-xs text-bb-green-500`}
`
export const CommentDeleteButton = styled.button`
	${tw`text-xs text-bb-cream-500`}
`

export const AddCommentWrapper = styled.div<AddCommentProps>`
	${tw`absolute bottom-0 left-0 bg-bb-petrol-700 transition-transform duration-300 ease-in-out min-w-full`}
	transform: translateY(${({ isOpen }) => (isOpen ? "0%" : "100%")});
`
export const AddComment = styled.div`
	${tw`relative flex flex-col p-4 `}
`
export const AddCommentTitle = styled.h3`
	${tw`text-bb-white font-semibold text-base mb-1.5`}
`
export const AddCommentTextArea = styled.textarea`
	${tw`text-bb-white font-light text-base mb-4 p-2 bg-bb-white/10 border border-bb-white rounded-lg outline-none resize-none min-h-[152px] `}
`
export const AddCommentButtons = styled.div`
	${tw`flex justify-between`}
`
export const AddCommentButton = styled.button`
	${tw`flex items-center justify-center text-base font-semibold px-8 py-4 min-w-[144px] border border-bb-white rounded-lg`}
`
export const AddCommentCancelButton = styled(AddCommentButton)`
	${tw`text-bb-white bg-none`}
`
export const AddCommentAddButton = styled(AddCommentButton)`
	${tw`text-bb-black bg-bb-white`}
`
