import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { lockersApi } from "../services/lockers" // defaults to localStorage for we
import { ordersApi } from "../services/orders"
import { extendTimeApi } from "../services/extendTime"
import { lockerModulesApi } from "../services/lockerModules"
import { parcelsApi } from "../services/parcels"

import {
	toggleFiltersSlice,
	selectFiltersSlice,
	lockersWithinSlice,
	isExpandFiltersSlice,
	selectedLockerSlice,
	showLockerOnMapSlice,
	searchTermSlice,
	authTokenSlice,
	globalModalSlice,
} from "./slices"
import { lockerStatusLogsApi } from "../services/lockerStatusLogs"
import { logsAndCommentsSlice } from "./slices/logs_comments_slice"
import { lockerCommentsApi } from "../services/lockerComments"
import { clearBoxErrorApi } from "../services/clearBoxError"
import { openEndpointsApi } from "../services/openedApi"
import { deviceAccessApi } from "../services/deviceAccess"

const persistConfig = {
	key: "root",
	version: 2,
	storage,
	whitelist: ["counter"],
}

const mapReducer = combineReducers({
	lockersWithin: lockersWithinSlice.reducer,
})

const filtersReducer = combineReducers({
	toggle: toggleFiltersSlice.reducer,
	select: selectFiltersSlice.reducer,
	isExpandedFilters: isExpandFiltersSlice.reducer,
	searchTerm: searchTermSlice.reducer,
})

const lockerReducer = combineReducers({
	selectedLocker: selectedLockerSlice.reducer,
	showLockerOnMap: showLockerOnMapSlice.reducer,
})

const rootReducer = combineReducers({
	[lockersApi.reducerPath]: lockersApi.reducer,
	[lockerModulesApi.reducerPath]: lockerModulesApi.reducer,
	[ordersApi.reducerPath]: ordersApi.reducer,
	[parcelsApi.reducerPath]: parcelsApi.reducer,
	[extendTimeApi.reducerPath]: extendTimeApi.reducer,
	[lockerCommentsApi.reducerPath]: lockerCommentsApi.reducer,
	[lockerStatusLogsApi.reducerPath]: lockerStatusLogsApi.reducer,
	[clearBoxErrorApi.reducerPath]: clearBoxErrorApi.reducer,
	[openEndpointsApi.reducerPath]: openEndpointsApi.reducer,
	[deviceAccessApi.reducerPath]: deviceAccessApi.reducer,
	auth: authTokenSlice.reducer,
	lockers: lockerReducer,
	sidebarFilters: filtersReducer,
	map: mapReducer,
	globalModal: globalModalSlice.reducer,
	logsAndComments: logsAndCommentsSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
const disableSerializeCheck = {
	serializableCheck: false,
}

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => {
		const middlewares = [
			...getDefaultMiddleware(disableSerializeCheck),
			lockersApi.middleware,
			parcelsApi.middleware,
			lockerModulesApi.middleware,
			ordersApi.middleware,
			extendTimeApi.middleware,
			lockerCommentsApi.middleware,
			lockerStatusLogsApi.middleware,
			clearBoxErrorApi.middleware,
			openEndpointsApi.middleware,
			deviceAccessApi.middleware,
		]

		return middlewares
	},
})

setupListeners(store.dispatch)

const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { store, persistor }
