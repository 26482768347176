import React from "react"
import { Title, Wrapper } from "./LockerLogsAndCommentHeader.styles"

type Props = {
	lockerName: string
}

const LockerLogsAndCommentsHeader = ({ lockerName }: Props) => {
	return (
		<Wrapper>
			<Title>{lockerName}</Title>
		</Wrapper>
	)
}

export default LockerLogsAndCommentsHeader
