import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { Button, ButtonVariants, Spinner } from "@budbee-monorepo/ui"
import { useSearchParams } from "react-router-dom"
import {
	lockersApi,
	useDeleteLockerMutation,
	useGetDisabledReasonsQuery,
	useGetPostalCodeZonesQuery,
	useUpdateLockerDetailsMutation,
} from "../../services/lockers"
import { ILocker, IOpeningHours, IPostalCodeZone, IUpdateLockerDetails } from "../../types/lockers"
import {
	StyledDetailContainer,
	StyledDetailContent,
	StyledDetailContentCol,
	StyledDetailLabel,
} from "./LockerViewDetailsBody.styles"
import {
	ErrorContainer,
	OpeningHour,
	OpeningHourInput,
	OpeningHourInputDivider,
	OpeningHourInputs,
	OpeningHourPeriod,
	OpeningHourPeriodTitle,
	OpeningHourTitle,
	OpeningHourWrapper,
	SelectTheme,
	StyledButton,
	StyledButtonColumnContainer,
	StyledButtonContainer,
	StyledErrorIcon,
	StyledErrorText,
	StyledInput,
	StyledInputLabel,
	StyledSecondaryButton,
	StyledSelect,
	StyledSmallButton,
	StyledTextarea,
	StyledTransparentSmallButton,
	Wrapper,
} from "./LockerViewDetailsBodyEditable.styles"
import {
	removeCustomPinActionCreator,
	removeLockerActionCreator,
	setCustomPinActionCreator,
} from "../../store/slices"
import { useGlobalModal } from "../../utils/hooks/useGlobalModal"
import { useRoles } from "../../utils/hooks/useRoles"

type Props = {
	selectedLocker: ILocker
	cancelEditing: () => void
}

export interface StatusOption {
	value: number | null
	label: string
}
export interface PostalCodeZones {
	value: IPostalCodeZone
	label: string
}
export interface IFlattenedOpeningHours {
	day: string
	open: {
		hour: string | null
		minute: string | null
	}
	close: {
		hour: string | null
		minute: string | null
	}
}
interface GroupedOptions<OptionType> {
	readonly label: string
	readonly options: readonly OptionType[]
}
const LockerViewDetailsBodyEditable = ({ selectedLocker, cancelEditing }: Props) => {
	const dispatch = useDispatch()
	const [name, setName] = useState<string>(selectedLocker.name)
	const [status, setStatus] = useState<StatusOption>(
		selectedLocker.disabledReason
			? {
					value: selectedLocker.disabledReason.id,
					label: selectedLocker.disabledReason.reason,
			  }
			: { value: null, label: "Enabled" }
	)

	const [postalCodeZone, setPostalCodeZone] = useState<PostalCodeZones>({
		label: `${selectedLocker.postalCodeZone.id} - ${selectedLocker.postalCodeZone.title}`,
		value: selectedLocker.postalCodeZone,
	})
	const [street, setStreet] = useState<string>(selectedLocker.street)
	const [postalCode, setPostalCode] = useState<string>(selectedLocker.postalCode)
	const [city, setCity] = useState<string>(selectedLocker.city)
	const [directions, setDirections] = useState<string>(selectedLocker.directions)
	const [directionsEnglish, setDirectionsEnglish] = useState<string>(
		selectedLocker.directionsEnglish
	)
	const [latitude, setLatitude] = useState<string>(selectedLocker.latitude.toString())
	const [longitude, setLongitude] = useState<string>(selectedLocker.longitude.toString())
	const [openingHours, setOpeningHours] = useState<IFlattenedOpeningHours[]>(
		selectedLocker.openingHours.periods.map((period) => {
			const dayOpenReformatted = period.open.time
				? {
						hour: period.open.time.split(":")[0],
						minute: period.open.time.split(":")[1],
				  }
				: {
						hour: null,
						minute: null,
				  }
			const dayCloseReformatted = period.close.time
				? {
						hour: period.close.time.split(":")[0],
						minute: period.close.time.split(":")[1],
				  }
				: {
						hour: null,
						minute: null,
				  }
			return {
				day: period.open.day,
				open: dayOpenReformatted,
				close: dayCloseReformatted,
			}
		})
	)
	const [error, setError] = useState<string | null>(null)
	const [coordinateChange, setCoordinateChange] = useState<boolean>(false)

	const disabledReasons = useGetDisabledReasonsQuery()
	const [updateLockerDetails, updateLockerDetailsResult] = useUpdateLockerDetailsMutation()
	const [deleteLocker, deleteLockerResult] = useDeleteLockerMutation()
	const postalCodeZones = useGetPostalCodeZonesQuery()
	const scrollableBodyRef = useRef<HTMLDivElement>(null)
	const { showConfirmation, hide } = useGlobalModal()
	const { isAdmin } = useRoles()

	useEffect(() => {
		if (updateLockerDetailsResult.isSuccess) {
			setTimeout(() => {
				if (
					coordinateChange &&
					updateLockerDetailsResult.originalArgs &&
					updateLockerDetailsResult.originalArgs.data
				) {
					setCoordinateChange(false)
					const { latitude: lat, longitude: lng } =
						updateLockerDetailsResult.originalArgs.data
					dispatch(
						setCustomPinActionCreator({
							lat: lat,
							lng: lng,
							showOnMap: true,
						})
					)
					dispatch(removeLockerActionCreator())
					const url = new URL(window.location.toString())
					url.searchParams.delete("locker")
					window.history.pushState({}, "", url)
					setTimeout(() => {
						dispatch(removeCustomPinActionCreator())
					}, 10000)
				}
				cancelEditing()
			}, 1000)
		}
	}, [updateLockerDetailsResult, cancelEditing, dispatch, coordinateChange])

	let statusOptions: GroupedOptions<StatusOption>[] = []
	if (disabledReasons.data) {
		const disabledOptions: StatusOption[] = disabledReasons.data.map(({ id, reason }) => ({
			value: id,
			label: reason,
		}))
		statusOptions = [
			{
				label: "Enabled options",
				options: [{ value: null, label: "Enabled" }],
			},
			{
				label: "Disabled options",
				options: disabledOptions,
			},
		]
	}
	let postalCodeZoneOptions: PostalCodeZones[] = []
	if (postalCodeZones.data) {
		postalCodeZoneOptions = postalCodeZones.data.map((zone) => ({
			value: zone,
			label: `${zone.id} - ${zone.title}`,
		}))
	}

	const onStatusChange = (option: StatusOption | null) => {
		if (option) {
			setStatus(option)
		}
	}
	const onPostalCodeZoneChange = (option: PostalCodeZones | null) => {
		if (option) {
			setPostalCodeZone(option)
		}
	}
	const saveEdit = () => {
		if (city.length === 0 || street.length === 0 || postalCode.length === 0) {
			return
		}

		const unFlattenedPeriods = openingHours.map((period) => ({
			open: {
				day: period.day,
				time:
					period.open.hour && period.open.minute
						? `${period.open.hour}:${period.open.minute}:00`
						: null,
			},
			close: {
				day: period.day,
				time:
					period.close.hour && period.close.minute
						? `${period.close.hour}:${period.close.minute}:00`
						: null,
			},
		}))
		const unFlattenedOpeningHours: IOpeningHours = {
			weekdayText: selectedLocker.openingHours.weekdayText,
			periods: unFlattenedPeriods,
		}
		if (Number.isNaN(parseFloat(latitude)) || Number.isNaN(parseFloat(longitude))) {
			setError("The coordinates are incorrect")
			scrollableBodyRef.current?.scrollTo(0, 0)
			return
		}
		if (
			selectedLocker.latitude !== parseFloat(latitude) ||
			selectedLocker.longitude !== parseFloat(longitude)
		) {
			setCoordinateChange(true)
		}
		const data: IUpdateLockerDetails = {
			identifier: selectedLocker.identifier,
			data: {
				name: name,
				street,
				city,
				country: selectedLocker.country,
				directions,
				directionsEnglish,
				postalCodeZone: postalCodeZone.value,
				postalCode,
				openingHours: unFlattenedOpeningHours,
				latitude: parseFloat(latitude),
				longitude: parseFloat(longitude),
				enabled: status.value === null,
				disabledReason:
					status.value !== null
						? {
								id: status.value,
								reason: status.label,
						  }
						: null,
				version: selectedLocker.version,
			},
		}
		updateLockerDetails(data)
	}
	const changeOpeningHours = (index: number) => {
		const tempOpeningHours = [...openingHours]
		return {
			open: {
				hour: (value: string | null) => {
					tempOpeningHours[index].open.hour = value
					setOpeningHours(tempOpeningHours)
				},
				minute: (value: string | null) => {
					tempOpeningHours[index].open.minute = value
					setOpeningHours(tempOpeningHours)
				},
			},
			close: {
				hour: (value: string | null) => {
					tempOpeningHours[index].close.hour = value
					setOpeningHours(tempOpeningHours)
				},
				minute: (value: string | null) => {
					tempOpeningHours[index].close.minute = value
					setOpeningHours(tempOpeningHours)
				},
			},
		}
	}
	const setOpeningHoursForIndex = (
		idx: number,
		data: {
			open: {
				hour: string | null
				minute: string | null
			}
			close: {
				hour: string | null
				minute: string | null
			}
		}
	) => {
		const tempOpeningHours = [...openingHours]
		tempOpeningHours[idx].open = data.open
		tempOpeningHours[idx].close = data.close
		setOpeningHours(tempOpeningHours)
	}
	const blurOpeningHours = (value: string | null, index: number) => {
		const formattedValue = {
			hour: () => {
				if (value === "" || value === null) {
					return "00"
				}
				if (Number(value) >= 24) {
					return "00"
				}
				if (value.length === 1) {
					return `0${value}`
				}
				return value
			},
			minute: () => {
				if (value === "" || value === null) {
					return "00"
				}
				if (Number(value) >= 60) {
					return "59"
				}
				if (value.length === 1) {
					return `0${value}`
				}
				return value
			},
		}

		return {
			open: {
				hour: () => {
					changeOpeningHours(index).open.hour(formattedValue.hour())
				},
				minute: () => {
					changeOpeningHours(index).open.minute(formattedValue.minute())
				},
			},
			close: {
				hour: () => {
					changeOpeningHours(index).close.hour(formattedValue.hour())
				},
				minute: () => {
					changeOpeningHours(index).close.minute(formattedValue.minute())
				},
			},
		}
	}
	const openingHoursMap = openingHours.map((period, idx) => {
		if (
			period.open.hour === null ||
			period.open.minute === null ||
			period.close.hour === null ||
			period.close.minute === null
		) {
			return (
				<OpeningHourWrapper>
					<OpeningHourTitle>{period.day}</OpeningHourTitle>
					<OpeningHour>
						<StyledSmallButton
							onClick={() => {
								setOpeningHoursForIndex(idx, {
									open: {
										hour: "00",
										minute: "00",
									},
									close: {
										hour: "00",
										minute: "00",
									},
								})
							}}
						>
							Set opening hours
						</StyledSmallButton>
					</OpeningHour>
				</OpeningHourWrapper>
			)
		}
		return (
			<OpeningHourWrapper>
				<OpeningHourTitle>{period.day}</OpeningHourTitle>
				<StyledTransparentSmallButton
					onClick={() => {
						setOpeningHoursForIndex(idx, {
							open: {
								hour: null,
								minute: null,
							},
							close: {
								hour: null,
								minute: null,
							},
						})
					}}
				>
					Set to closed
				</StyledTransparentSmallButton>
				<OpeningHour>
					<OpeningHourPeriod>
						<OpeningHourPeriodTitle>Open</OpeningHourPeriodTitle>
						<OpeningHourInputs>
							<OpeningHourInput
								value={period.open.hour}
								onChange={(e) => changeOpeningHours(idx).open.hour(e.target.value)}
								onBlur={(e) => blurOpeningHours(e.target.value, idx).open.hour()}
								onFocus={(e) => e.target.select()}
								maxLength={2}
							/>
							<OpeningHourInputDivider>:</OpeningHourInputDivider>
							<OpeningHourInput
								value={period.open.minute}
								onChange={(e) =>
									changeOpeningHours(idx).open.minute(e.target.value)
								}
								onBlur={(e) => blurOpeningHours(e.target.value, idx).open.minute()}
								onFocus={(e) => e.target.select()}
								maxLength={2}
							/>
						</OpeningHourInputs>
					</OpeningHourPeriod>
					<OpeningHourPeriod>
						<OpeningHourPeriodTitle>Close</OpeningHourPeriodTitle>
						<OpeningHourInputs>
							<OpeningHourInput
								value={period.close.hour}
								onChange={(e) => changeOpeningHours(idx).close.hour(e.target.value)}
								onBlur={(e) => blurOpeningHours(e.target.value, idx).close.hour()}
								onFocus={(e) => e.target.select()}
								maxLength={2}
							/>
							<OpeningHourInputDivider>:</OpeningHourInputDivider>
							<OpeningHourInput
								value={period.close.minute}
								onChange={(e) =>
									changeOpeningHours(idx).close.minute(e.target.value)
								}
								onBlur={(e) => blurOpeningHours(e.target.value, idx).close.minute()}
								onFocus={(e) => e.target.select()}
								maxLength={2}
							/>
						</OpeningHourInputs>
					</OpeningHourPeriod>
				</OpeningHour>
			</OpeningHourWrapper>
		)
	})

	const removeLocker = () => {
		showConfirmation({
			title: "Remove locker",
			text: "Make sure the locker is empty and that all parcels have been taken care for",
			actions: [
				{
					variant: ButtonVariants.DANGER,
					text: "Remove locker",
					action: () => {
						deleteLocker(selectedLocker.identifier)
						hide()
					},
				},
				{
					variant: ButtonVariants.SECONDARY,
					text: "Cancel",
					action: () => {
						hide()
					},
				},
			],
		})
	}
	if (deleteLockerResult.isSuccess) {
		dispatch(removeLockerActionCreator())
	}
	return (
		<Wrapper ref={scrollableBodyRef}>
			{!updateLockerDetailsResult.isUninitialized ? (
				<Spinner size="60px" />
			) : (
				<>
					{error ? (
						<StyledDetailContainer>
							<ErrorContainer>
								<StyledErrorIcon />
								<StyledErrorText>{error}</StyledErrorText>
							</ErrorContainer>
						</StyledDetailContainer>
					) : null}
					<StyledDetailContainer>
						<StyledDetailLabel>Name</StyledDetailLabel>
						<StyledDetailContentCol>
							<StyledInput value={name} onChange={(e) => setName(e.target.value)} />
						</StyledDetailContentCol>
					</StyledDetailContainer>
					<StyledDetailContainer>
						<StyledDetailLabel>Status</StyledDetailLabel>
						<StyledDetailContent>
							<StyledSelect
								options={statusOptions}
								defaultValue={status}
								onChange={(e) => onStatusChange(e as StatusOption)}
								classNamePrefix="react-select"
								theme={SelectTheme}
							/>
						</StyledDetailContent>
					</StyledDetailContainer>
					<StyledDetailContainer>
						<StyledDetailLabel>Address</StyledDetailLabel>
						<StyledDetailContentCol>
							<StyledInputLabel>Street</StyledInputLabel>
							<StyledInput
								value={street}
								onChange={(e) => setStreet(e.target.value)}
							/>
							<StyledInputLabel>Postal code</StyledInputLabel>
							<StyledInput
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
							/>
							<StyledInputLabel>City</StyledInputLabel>
							<StyledInput value={city} onChange={(e) => setCity(e.target.value)} />
						</StyledDetailContentCol>
					</StyledDetailContainer>
					<StyledDetailContainer>
						<StyledDetailLabel>Postal Code Zone</StyledDetailLabel>
						<StyledDetailContent>
							<StyledSelect
								options={postalCodeZoneOptions}
								defaultValue={postalCodeZone}
								onChange={(e) => onPostalCodeZoneChange(e as PostalCodeZones)}
								classNamePrefix="react-select"
								theme={SelectTheme}
							/>
						</StyledDetailContent>
					</StyledDetailContainer>
					<StyledDetailContainer>
						<StyledDetailLabel>Directions</StyledDetailLabel>
						<StyledDetailContentCol>
							<StyledInputLabel>Local language</StyledInputLabel>
							<StyledTextarea
								value={directions}
								onChange={(e) => setDirections(e.target.value)}
							/>
							<StyledInputLabel>English</StyledInputLabel>
							<StyledTextarea
								value={directionsEnglish}
								onChange={(e) => setDirectionsEnglish(e.target.value)}
							/>
						</StyledDetailContentCol>
					</StyledDetailContainer>
					<StyledDetailContainer>
						<StyledDetailLabel>Coordinates</StyledDetailLabel>
						<StyledDetailContentCol>
							<StyledInputLabel>Latitude</StyledInputLabel>
							<StyledInput
								value={latitude}
								onChange={(e) => {
									setLatitude(e.target.value)
									setError(null)
								}}
							/>
							<StyledInputLabel>Longitude</StyledInputLabel>
							<StyledInput
								value={longitude}
								onChange={(e) => {
									setLongitude(e.target.value)
									setError(null)
								}}
							/>
						</StyledDetailContentCol>
					</StyledDetailContainer>
					<StyledDetailContainer>
						<StyledDetailLabel>Opening hours</StyledDetailLabel>
						<StyledDetailContentCol>{openingHoursMap}</StyledDetailContentCol>
					</StyledDetailContainer>
					<StyledButtonContainer>
						<StyledButton onClick={saveEdit}>Save</StyledButton>
						<StyledSecondaryButton onClick={cancelEditing}>
							Cancel
						</StyledSecondaryButton>
					</StyledButtonContainer>
					{isAdmin ? (
						<StyledButtonColumnContainer>
							<Button variant={ButtonVariants.DANGER} onClick={removeLocker}>
								Remove locker
							</Button>
						</StyledButtonColumnContainer>
					) : null}
				</>
			)}
		</Wrapper>
	)
}

export default LockerViewDetailsBodyEditable
