import React, { ReactNode } from "react"
import { IBox } from "../../types/lockers"
import { BoxDimOverlay, StyledLockerBox } from "./LockerBox.styles"

type Props = {
	children: ReactNode
	box: IBox
	selectedBox: boolean
	hasAnySelectedBoxes: boolean
	handleOnClick: (event: React.MouseEvent<HTMLElement>, box: IBox) => void
}

const LockerBox = ({ box, children, handleOnClick, selectedBox, hasAnySelectedBoxes }: Props) => {
	return (
		<StyledLockerBox
			onClick={(e) => handleOnClick(e, box)}
			size={box.size}
			status={box.status}
			type={box.type}
		>
			{!selectedBox && hasAnySelectedBoxes && <BoxDimOverlay />}

			{children}
		</StyledLockerBox>
	)
}

export default LockerBox
