import styled from "styled-components"

export type ButtonProps = {
	darkBackground: boolean
	size: "small" | "normal" | "large"
	disabled: boolean
}
const minWidth = { small: "6.5rem", normal: "8.25rem", large: "9.25rem" }
const padding = { small: "0.5rem 0.5rem", normal: "1rem 0.5rem", large: "1.5rem 0.5rem" }
const fontSize = { small: "0.75rem", normal: "1rem", large: "1.25rem" }
const lineHeight = { small: "1rem", normal: "1.5rem", large: "1.75rem" }
export const StyledButton = styled.button<ButtonProps>`
	outline: none;
	border: none;
	border-radius: 0.5rem;
	font-weight: 600;
	min-width: ${(props) => minWidth[props.size]};
	padding: ${(props) => padding[props.size]};
	font-size: ${(props) => fontSize[props.size]};
	line-height: ${(props) => lineHeight[props.size]};
	cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
`

export const StyledPrimaryButton = styled(StyledButton)<ButtonProps>`
	background-color: ${(props) =>
		props.darkBackground
			? props.disabled
				? "#b3b3b3"
				: "#fff"
			: props.disabled
			? "#b3b3b3"
			: "#000"};
	color: ${(props) => (props.darkBackground ? (props.disabled ? "#fff" : "#000") : "#fff")};
`
export const StyledSecondaryButton = styled(StyledButton)<ButtonProps>`
	background-color: ${(props) => (props.disabled ? "#b3b3b3" : "transparent")};
	color: ${(props) => (props.darkBackground ? (props.disabled ? "#fff" : "#fff") : "#000")};
	border: 1px solid ${(props) => (props.darkBackground ? "#fff" : "#000")};
`
export const StyledTertiaryButton = styled(StyledButton)<ButtonProps>`
	background-color: ${(props) => (props.disabled ? "#b3b3b3" : "#D9F5ED")};
	color: ${(props) => (props.disabled ? "#fff" : "#000")};
`
export const StyledTransparentButton = styled(StyledButton)<ButtonProps>`
	background-color: transparent;
	color: #18706d;
`
export const StyledDangerButton = styled(StyledButton)<ButtonProps>`
	background-color: ${(props) => (props.disabled ? "#ebd1d2" : "#bd666a")};
	color: #ffffff;
`
export const StyledTransparentDangerButton = styled(StyledButton)<ButtonProps>`
	background-color: transparent;
	color: #bd666a;
`
