import styled from "styled-components"
import tw from "twin.macro"

interface DrawerProps {
	readonly isOpen: boolean
}
export const Wrapper = styled.div<DrawerProps>`
	${tw`fixed flex top-0 bottom-0 left-0 right-0 z-10`}
	${({ isOpen }) => {
		if (isOpen) {
			return tw`pointer-events-auto`
		}
		return tw`pointer-events-none`
	}}
`
export const DrawerFade = styled.div<DrawerProps>`
	${tw`bg-bb-black flex-grow transition-all duration-150 ease-in-out `}
	${({ isOpen }) => {
		if (isOpen) {
			return tw`delay-300 opacity-40`
		}
		return tw`opacity-0`
	}}
`
export const DrawerContentWrapper = styled.div<DrawerProps>`
	${tw`bg-bb-petrol-600 w-[440px] transition-all duration-300 ease-in-out relative`}
	${({ isOpen }) => {
		if (isOpen) {
			return tw`delay-150 translate-x-0`
		}
		return tw`opacity-0 translate-x-full`
	}}
`
export const DrawerCloseButton = styled.div<DrawerProps>`
	${tw`absolute top-8 flex items-center justify-center bg-bb-white w-10 h-10 rounded-lg shadow cursor-pointer transition-all duration-300 ease-in-out`}
	${({ isOpen }) => {
		if (isOpen) {
			return tw`-left-20`
		}
		return tw`left-0`
	}}
`
