import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export const authTokenSlice = createSlice({
	name: "authToken",
	initialState: "",
	reducers: {
		set: (draftState, { payload }: PayloadAction<string>) => payload,
		remove: () => "",
	},
})

export const { set: setAuthTokenActionCreator, remove: removeAuthTokenActionCreator } =
	authTokenSlice.actions
