import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setLogsAndCommentsActionCreator } from "../../store/slices"
import { IGlobalState } from "../../types/global"
import { ILockerTab } from "../../types/logsAndComments"
import Tabs, { ITab } from "../Tabs/Tabs"
import LockerComments from "./LockerComments/LockerComments"
import LockerLogs from "./LockerLogs/LockerLogs"
import { Wrapper } from "./LockerLogsAndCommentsBody.styles"

const LockerLogsAndCommentsBody = () => {
	const choosenTab = useSelector((state: IGlobalState) => state.logsAndComments.tab)
	const dispatch = useDispatch()
	const data: ITab[] = [
		{
			title: "Logs",
			identifier: "LOGS",
		},
		{
			title: "Comments",
			identifier: "COMMENTS",
		},
	]
	const handleChange = (tab: string) => {
		const newTab = Object.values(ILockerTab).find((val) => {
			return val === tab
		})
		if (newTab) {
			dispatch(setLogsAndCommentsActionCreator(newTab))
		}
	}
	const switchBetweenPages = () => {
		switch (choosenTab) {
			case "COMMENTS":
				return <LockerComments />
			case "PARCELS":
				return <div />
			default:
				return <LockerLogs />
		}
	}
	return (
		<Wrapper>
			<Tabs data={data} identifier={choosenTab} handleChange={handleChange} />
			{switchBetweenPages()}
		</Wrapper>
	)
}

export default LockerLogsAndCommentsBody
