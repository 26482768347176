import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { toast } from "react-toastify"
import type { RootState } from "../store/configureStore"
import {
	IDisabledReason,
	IGetLockers,
	ILockersWithin,
	IPostalCodeZone,
	ISpecificLocker,
	IUpdateLockerDemand,
	IUpdateLockerDetails,
} from "../types/lockers"

const baseQuery = fetchBaseQuery({
	baseUrl: "", //process.env.REACT_APP_API_BASE_URL? `${process.env.REACT_APP_API_BASE_URL}bumblebox/` : ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

// Define a service using a base URL and expected endpoints
export const lockersApi = createApi({
	reducerPath: "lockersApi",
	baseQuery: baseQuery,
	tagTypes: ["Lockers", "SpecificLocker"],
	endpoints: (builder) => ({
		getLockersWithin: builder.query<IGetLockers, ILockersWithin>({
			query: ({ radius, coordinates }) => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/lockers-within/${radius}/center/${coordinates.lng}/${coordinates.lat}`
			},
			async onQueryStarted(lockersWithinArg, { queryFulfilled }) {
				if (lockersWithinArg?.id) {
					await toast.promise(queryFulfilled, {
						error: "Error fetching lockers",
					})
				}
			},
			providesTags: ["Lockers"],
		}),
		searchLockers: builder.query<IGetLockers, string>({
			query: (searchTerm) => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/search?filter=${searchTerm}`
			},
		}),
		getSpecificLocker: builder.query<ISpecificLocker, string>({
			query: (lockerIdentifier) => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/locker-specifics/${lockerIdentifier}`
			},
			async onQueryStarted(lockerIdentifier, { queryFulfilled }) {
				if (lockerIdentifier) {
					await toast.promise(queryFulfilled, {
						error: `Error fetching locker ${lockerIdentifier}`,
					})
				}
			},
			providesTags: (result, error, arg) => [{ type: "SpecificLocker", id: arg }],
		}),
		getDisabledReasons: builder.query<IDisabledReason[], void>({
			query: () => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/disabled-reasons`
			},
		}),
		getPostalCodeZones: builder.query<IPostalCodeZone[], void>({
			query: () => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/postal-code-zones`
			},
		}),
		updateLockerDetails: builder.mutation<void, IUpdateLockerDetails>({
			query: ({ identifier, data }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/locker-specifics/${identifier}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Lockers", { type: "SpecificLocker", id: arg.identifier }]
			},
		}),
		updateLockerDemand: builder.mutation<void, IUpdateLockerDemand>({
			query: ({ identifier, demand }) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/demand/${identifier}`,
				method: "PUT",
				body: {
					demand,
				},
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Lockers", { type: "SpecificLocker", id: arg.identifier }]
			},
		}),
		deleteLocker: builder.mutation<void, string>({
			query: (identifier) => ({
				url: `${window.config.REACT_APP_API_BASE_URL}bumblebox/locker/${identifier}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Lockers", { type: "SpecificLocker", id: arg }]
			},
		})
	}),
})

export const {
	useGetLockersWithinQuery,
	useSearchLockersQuery,
	useGetSpecificLockerQuery,
	useGetDisabledReasonsQuery,
	useGetPostalCodeZonesQuery,
	useUpdateLockerDetailsMutation,
	useUpdateLockerDemandMutation,
	useDeleteLockerMutation,
} = lockersApi
