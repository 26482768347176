import React from "react"
import { Button } from "@budbee-monorepo/ui"
import { InnerWrapper, Wrapper } from "./GlobalModal.styles"
import GlobalModalFooter from "./GlobalModalFooter"
import GlobalModalHeader from "./GlobalModalHeader"
import GlobalModalBody from "./GlobalModalBody"
import { IGlobalModalActions } from "../../types/global"

interface IGlobalModalConfirmationProp {
	title: string
	text: string
	onCancel: () => void
	actions: IGlobalModalActions[]
}
const GlobalModalConfirmation = ({
	title,
	text,
	onCancel,
	actions,
}: IGlobalModalConfirmationProp) => {
	return (
		<Wrapper>
			<InnerWrapper>
				<GlobalModalHeader title={title} onCancel={onCancel} />
				<GlobalModalBody>{text}</GlobalModalBody>
				<GlobalModalFooter>
					{actions.map((action) => (
						<Button
							key={action.variant}
							variant={action.variant}
							onClick={action.action}
						>
							{action.text}
						</Button>
					))}
				</GlobalModalFooter>
			</InnerWrapper>
		</Wrapper>
	)
}

export default GlobalModalConfirmation
