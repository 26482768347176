import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import React, { useCallback, useEffect } from "react"
import { Button, ButtonSizes, ButtonVariants } from "@budbee-monorepo/ui"
import { useDispatch } from "react-redux"
import {
	useAddModuleMutation,
	useDeleteModuleMutation,
	useGetModuleTemplatesQuery,
	useMoveModuleMutation,
	useUnassignDeliveriesMutation,
} from "../../services/lockerModules"
import { lockersApi, useUpdateLockerDetailsMutation } from "../../services/lockers"
import { IBoard, IOption } from "../../types/global"
import { IBox, ILocker, ISpecificLocker } from "../../types/lockers"
import { splitArray } from "../../utils/arrayHelpers"
import { useGlobalModal } from "../../utils/hooks/useGlobalModal"
import { errorToast } from "../../utils/toasts"
import { StyledLockerBox } from "./LockerBox.styles"
import { StyledKeypad, StyledScreen, StyledScreenContainer } from "./LockerScreen"
import {
	BoardButtons,
	BoardHeader,
	BoardsStyle,
	BoardStyle,
	BoardsWrapper,
	MoveButtons,
	RemoveButton,
	StyledAddButton,
	StyledCol,
	StyledEditLockerContainer,
	StyledEditModuleHeader,
	StyledMoveLeftButton,
	StyledMoveRightButton,
	StyledRowGrow,
} from "./LockerView.styles"

type Props = {
	boards: IBoard[]
	locker: ILocker
}
const LockerModuleEdit = ({ boards, locker }: Props) => {
	const { showConfirmation, showDropdownInput, hide } = useGlobalModal()
	const { data: moduleTemplates, isLoading, isFetching } = useGetModuleTemplatesQuery()
	const [addModuleMutation, addModuleResult] = useAddModuleMutation()
	const [moveModuleMutation, moveModuleResult] = useMoveModuleMutation()
	const [deleteModuleMutation, deleteModuleResult] = useDeleteModuleMutation()
	const [updateLockerDetails, updateLockerDetailsResult] = useUpdateLockerDetailsMutation()
	const [unassignDeliveries, unassignDeliveriesResult] = useUnassignDeliveriesMutation()
	const dispatch = useDispatch()
	useEffect(() => {
		if (!addModuleResult.isUninitialized && addModuleResult.isSuccess) {
			dispatch(
				lockersApi.util.invalidateTags([
					{
						type: "SpecificLocker",
					},
				])
			)
			addModuleResult.reset()
		}
		if (addModuleResult.isError) {
			const { data } = addModuleResult.error as FetchBaseQueryError
			errorToast(data as string)
		}
	}, [addModuleResult, dispatch])
	useEffect(() => {
		if (!moveModuleResult.isUninitialized && moveModuleResult.isSuccess) {
			dispatch(
				lockersApi.util.invalidateTags([
					{
						type: "SpecificLocker",
					},
				])
			)
			moveModuleResult.reset()
		}
		if (moveModuleResult.isError) {
			const { data } = moveModuleResult.error as FetchBaseQueryError
			errorToast(data as string)
		}
	}, [moveModuleResult, dispatch])
	useEffect(() => {
		if (!deleteModuleResult.isUninitialized && deleteModuleResult.isSuccess) {
			dispatch(
				lockersApi.util.invalidateTags([
					{
						type: "SpecificLocker",
					},
				])
			)
			deleteModuleResult.reset()
		}
		if (deleteModuleResult.isError) {
			const { data } = deleteModuleResult.error as FetchBaseQueryError
			errorToast(data as string)
		}
	}, [deleteModuleResult, dispatch])
	useEffect(() => {
		if (!unassignDeliveriesResult.isUninitialized && unassignDeliveriesResult.isSuccess) {
			dispatch(
				lockersApi.util.invalidateTags([
					{
						type: "SpecificLocker",
					},
				])
			)
			unassignDeliveriesResult.reset()
		}
		if (unassignDeliveriesResult.isError) {
			const { data } = unassignDeliveriesResult.error as FetchBaseQueryError
			errorToast(data as string)
		}
	}, [unassignDeliveriesResult, dispatch])

	const removeModule = (boardId: number) => {
		showConfirmation({
			title: "Are you sure?",
			text: "You are about to delete this module. This is an irreversible action and cannot be undone",
			actions: [
				{
					variant: ButtonVariants.DANGER,
					text: "Delete the module",
					action: () => {
						deleteModuleMutation({
							lockerIdentifier: locker.identifier,
							data: {
								boardId,
							},
						})
						hide()
					},
				},
				{
					variant: ButtonVariants.SECONDARY,
					text: "Cancel",
					action: () => {
						hide()
					},
				},
			],
		})
	}
	const moveModuleLeft = (boardId: number) => {
		moveModuleMutation({
			lockerIdentifier: locker.identifier,
			data: {
				direction: "LEFT",
				boardId: boardId,
			},
		})
	}
	const moveModuleRight = (boardId: number) => {
		moveModuleMutation({
			lockerIdentifier: locker.identifier,
			data: {
				direction: "RIGHT",
				boardId: boardId,
			},
		})
	}
	const addModule = (boardId: number) => {
		if (!moduleTemplates) return
		const options = moduleTemplates
			.filter(
				(template) => template.version === locker.version && template.name !== "Monitor"
			)
			.map((template) => {
				return {
					value: template.id.toString(),
					label: template.name,
				}
			})
		showDropdownInput({
			title: "Choose module",
			text: `Please choose a module to add width boardId: ${boardId}`,
			label: "Module",
			placeholder: "module",
			options,
			selectedOption: {
				value: null,
				label: "",
			},
			isValid: function (value: IOption<string | null>): boolean {
				return value.value !== null
			},
			onSubmit: function (value: IOption<string | null>): void {
				if (Number.isNaN(Number(value.value))) return
				addModuleMutation({
					lockerIdentifier: locker.identifier,
					data: {
						moduleId: Number(value.value),
						boardId: boardId,
					},
				})
				hide()
			},
			submitButtonText: "Add",
			submitButtonVariant: ButtonVariants.PRIMARY,
		})
	}
	const changeVersion = () => {
		const options = [
			{
				value: "indoor_1",
				label: "indoor_1",
			},
			{
				value: "indoor_2",
				label: "indoor_2",
			},
		]
		showDropdownInput({
			title: "Change version",
			text: "Please select what version you'd like this locker to be",
			label: "Version",
			placeholder: "version",
			options,
			selectedOption: {
				value: locker.version,
				label: locker.version || "null",
			},
			isValid: function (value: IOption<string | null>): boolean {
				return value.value !== locker.version
			},
			onSubmit: function (value: IOption<string | null>): void {
				if (!value.value) return
				updateLockerDetails({
					identifier: locker.identifier,
					data: {
						name: locker.name,
						street: locker.street,
						city: locker.city,
						country: locker.country,
						directions: locker.directions,
						directionsEnglish: locker.directionsEnglish,
						postalCodeZone: locker.postalCodeZone,
						postalCode: locker.postalCode,
						openingHours: locker.openingHours,
						latitude: locker.latitude,
						longitude: locker.longitude,
						enabled: locker.enabled,
						disabledReason: locker.disabledReason ? locker.disabledReason : null,
						version: value.value,
					},
				})
				hide()
			},
			submitButtonText: "Change",
			submitButtonVariant: ButtonVariants.PRIMARY,
		})
	}
	const unassignAllDeliveries = () => {
		showConfirmation({
			title: "Are you sure?",
			text: "You are about to remove all parcels from this locker, This means that the customer will not be able to pick them up.\n\n To deliver the parcels to the locker again, they need to be scanned at the locker. Do you want to proceed?",
			actions: [
				{
					variant: ButtonVariants.DANGER,
					text: "Unassign",
					action: () => {
						unassignDeliveries(locker.identifier)
						hide()
					},
				},
				{
					variant: ButtonVariants.SECONDARY,
					text: "Cancel",
					action: () => {
						hide()
					},
				},
			],
		})
	}
	const renderBoard = (boardBoxes: IBox[], isMonitor: boolean) => {
		const splitBoard = splitArray(boardBoxes, (box: IBox) => box.slotId < 25)
		const renderScreen = (slotId: number) => {
			if (!isMonitor) return null
			if (
				(locker.version === "indoor_1" && slotId === 3) ||
				(locker.version === "indoor_2" && slotId === 5)
			) {
				return (
					<StyledScreenContainer v2={locker.version === "indoor_2"} editMode>
						<StyledScreen>
							<StyledKeypad />
						</StyledScreen>
					</StyledScreenContainer>
				)
			}
			return null
		}
		return (
			<StyledRowGrow>
				{splitBoard.map((column) => (
					<StyledCol>
						{column.map((box) => (
							<>
								{renderScreen(box.slotId)}
								<StyledLockerBox
									size={box.size}
									status={box.status}
									type={box.type}
									small
								>
									{box.slotId}
								</StyledLockerBox>
							</>
						))}
					</StyledCol>
				))}
			</StyledRowGrow>
		)
	}
	const renderModules = () => {
		const sortedById = [...boards].sort((a, b) => a.id - b.id)
		return boards.map((board, index, arr) => {
			const colorId = sortedById.findIndex((b) => b.id === board.id)

			if (board.boardId === 0) {
				const canChangeVersion = boards.length === 0
				return (
					<>
						<StyledAddButton onClick={() => addModule(2)} />
						<BoardStyle colorId={colorId}>
							<BoardHeader>{board.boardId}</BoardHeader>
							{renderBoard(board.boxes, true)}
							<BoardButtons>
								<RemoveButton
									onClick={() => changeVersion()}
									disabled={canChangeVersion}
									style={{ marginTop: "55px" }}
								>
									Change version
								</RemoveButton>
							</BoardButtons>
						</BoardStyle>
					</>
				)
			}
			const addingBoardId = board.boardId % 2 === 0 ? board.boardId + 2 : board.boardId
			const leftMostModule = index === 0
			const rightMostModule = index === arr.length - 1
			return (
				<>
					<StyledAddButton onClick={() => addModule(addingBoardId)} />
					<BoardStyle colorId={colorId}>
						<BoardHeader>{board.boardId}</BoardHeader>
						{renderBoard(board.boxes, false)}
						<BoardButtons>
							<MoveButtons>
								<StyledMoveLeftButton
									onClick={() =>
										leftMostModule ? null : moveModuleLeft(board.boardId)
									}
									disabled={leftMostModule}
								/>
								<StyledMoveRightButton
									onClick={() =>
										rightMostModule ? null : moveModuleRight(board.boardId)
									}
									disabled={rightMostModule}
								/>
							</MoveButtons>
							<RemoveButton onClick={() => removeModule(board.boardId)}>
								Remove
							</RemoveButton>
						</BoardButtons>
					</BoardStyle>
				</>
			)
		})
	}

	return (
		<StyledEditLockerContainer>
			<StyledEditModuleHeader>Edit locker modules</StyledEditModuleHeader>

			<BoardsWrapper>
				<BoardsStyle>
					{renderModules()}
					<StyledAddButton
						onClick={() =>
							addModule(
								boards[boards.length - 1].boardId === 0
									? 1
									: boards[boards.length - 1].boardId + 2
							)
						}
					/>
				</BoardsStyle>
			</BoardsWrapper>
			<Button variant={ButtonVariants.DANGER} onClick={unassignAllDeliveries}>
				Unassign deliveries
			</Button>
		</StyledEditLockerContainer>
	)
}

export default LockerModuleEdit
