import styled from "styled-components"
import tw from "twin.macro"
import { Popup } from "react-map-gl"
import { MdSearch, MdChevronRight, MdClose } from "react-icons/md"

export const StyledInput = styled.input`
	${tw`bg-bb-white text-bb-black border border-bb-gray-400 rounded-lg outline-none mb-3 py-3 px-4 max-w-full shadow-lg w-full`}
	&:focus {
		${tw`border-bb-gray-600`}
	}
`
export const StyledSearchContainer = styled.div`
	${tw`absolute top-4 left-4 max-w-full w-[40rem] pr-8`}
`
export const StyledInnerSearchContainer = styled.div`
	${tw`relative w-full`}
`
export const StyledResultList = styled.div`
	${tw`bg-bb-white rounded-lg shadow-lg`}
`
export const StyledResultItem = styled.div`
	${tw`flex flex-row border border-b-bb-gray-300 px-2 py-2 items-center justify-between`}
`
export const StyledResultItemText = styled.div`
	${tw`flex flex-col`}
`
export const StyledSearchResultTitle = styled.span`
	${tw`text-bb-gray-900`}
`
export const StyledSearchResultSubTitle = styled.span`
	${tw`text-xs text-bb-gray-400`}
`
export const StyledRightArrowIcon = styled(MdChevronRight)`
	${tw`text-xl text-bb-gray-900 min-w-[2rem]`}
`
export const StyledSearchIcon = styled(MdSearch)`
	${tw`absolute top-4 right-4 text-xl text-bb-gray-900`}
`
export const StyledCloseIcon = styled(MdClose)`
	${tw`absolute top-4 right-4 text-xl text-bb-gray-900`}
`
export const StyledPopup = styled(Popup)`
	& > .mapboxgl-popup-content {
		${tw`p-4 pointer-events-none`}
	}
`
