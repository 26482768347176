import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { RootState } from "../store/configureStore"
import { ILockerStatusLog } from "../types/lockerStatusLogs"

const baseQuery = fetchBaseQuery({
	baseUrl: ``,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}

		return headers
	},
})

export const lockerStatusLogsApi = createApi({
	reducerPath: "lockerStatusLogs",
	baseQuery: baseQuery,
	tagTypes: ["LockerStatusLogs"],
	endpoints: (builder) => ({
		getLockerStatusLogs: builder.query<ILockerStatusLog[], string>({
            query: (lockerIdentifier) => {
				return `${window.config.REACT_APP_API_BASE_URL}bumblebox/disabled-reasons-history/${lockerIdentifier}`
			},
            providesTags: (result, error, arg) => [{ type: "LockerStatusLogs", id: arg }]
        }),
		
	}),
})

export const { useGetLockerStatusLogsQuery } =
lockerStatusLogsApi